/* React Components */
import React, { memo, useContext } from "react";

/* MUI Components */
import { Badge, Box, Tooltip, Typography } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";

/* Gray Components */
import { Application } from "../../../../context/Application";
import { isAdmin } from "../../../../utilities/isAdmin";
import BoxStatus from "./BoxStatus";
import GpiState from "./GpiState";

const RowItem = ({
  item, //val
  streamMeta, //streamData
  selectStream, //onSelect
  showGPIs,
}) => {
  const global = useContext(Application);

  /* Check is stream is Primary */
  const FindMatch = ({ data }) => {
    const obj = streamMeta.find((stream) => {
      return +stream.ssid === +data.ssid && stream.primary === true;
    });

    return (
      <Box>
        {obj && obj.hasOwnProperty("id") > 0 ? (
          <Typography className="streams___primary_label">Primary Stream</Typography>
        ) : streamMeta && streamMeta.length > 0 ? (
          <React.Fragment></React.Fragment>
        ) : (
          data.workflow &&
          data.isPlayable &&
          data.affiliate.toUpperCase() !== "NONE" &&
          (data.mvpd.toUpperCase() === "GRAY" || data.mvpd === "") && <Typography className="streams___primary_label">Potential Primary</Typography>
        )}
      </Box>
    );
  };

  return (
    <Box className="streams___list">
      {item.boxes.map((v, k) => {
        return (
          <Box
            key={"streams___list_" + k}
            data-sid={v.sid}
            data-ssid={v.ssid}
            data-adenabled={v.adEnabled}
            data-workflow={v.workflow}
            data-isplayable={v.isPlayable}
            data-mvpd={v.mvpd}
            className={
              "streams___list_row" +
              (global.activeStream && global.activeStream.ssid === v.ssid ? " active" : " inactive") +
              (v.hide ? " hidden" : " visible")
            }
          >
            <Box
              className={
                "streams___list_meta " +
                (v.workflow && v.isPlayable && v.affiliate.toUpperCase() !== "NONE" && (v.mvpd.toUpperCase() === "GRAY" || v.mvpd === "")
                  ? " primary"
                  : "")
              }
              onClick={(e) => selectStream(v, item.timezone)}
            >
              <Box className="meta___icon">
                <BoxStatus status={v.state} />
              </Box>
              <Box className="meta___title">
                {streamMeta && streamMeta.length > 0 && <FindMatch data={v} />}
                {isAdmin(global.appUser.account, global.appUI.hideAdminTools, false) && v.notes && v.notes > 0 ? (
                  <Tooltip title={`${v.notes} open note(s) on this stream`} placement="right" arrow>
                    <Badge className="notes___count" badgeContent={v.notes} color="warning">
                      <span>{v.friendly ? v.friendly : v.ssname + "(raw stream name)"}</span>
                    </Badge>
                  </Tooltip>
                ) : (
                  <span>{v.friendly ? v.friendly : v.ssname + "(raw stream name)"}</span>
                )}
              </Box>
              <ChevronRight className="meta___arrow" sx={{}} />
            </Box>
            {showGPIs && <GpiState key={k} data={v} />}
          </Box>
        );
      })}
    </Box>
  );
};

export default memo(RowItem);
