import React, { useContext, useState } from "react";

// MUI Components
import { ExpandLess, ExpandMore, Videocam } from "@mui/icons-material";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";

// AWS Components
import { DataStore } from "aws-amplify";
import { Streams } from "../../models";

// Gray Componets
import { getSources } from "../../utilities/utility";
import { Application } from "../../context/Application";

import setActivityLog from "../../utilities/setActivityLog";

const AdminVideoSource = ({ streamData, forceUpdate }) => {
  const global = useContext(Application);

  /* Drop Down Elements */
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  /* Admin Tool - Set Stream as Primary for ActiveSB */
  const setSource = async (data, source) => {
    const original = await DataStore.query(Streams, (c) =>
      c.and((c) => [c.ssid.eq(+data.ssid), c.station.eq(data.sname.toLowerCase().replace(/-\D\D/, ""))])
    );

    try {
      if (original.length > 0 && original[0].hasOwnProperty("ssid")) {
        /* Update Record */
        await DataStore.save(
          Streams.copyOf(original[0], (updated) => {
            updated.source = source;
            updated.author = global.appUser.email;
          })
        );
      } else {
        /* Create Record */
        await DataStore.save(
          new Streams({
            station: data.sname.toLowerCase().replace(/-\D\D/, ""),
            activesb: data.activesb,
            source: source,
            author: global.appUser.email,
          })
        );
      }

      await setActivityLog({
        type: "SOURCE",
        note: `Stream source set to ${source} for #${+data.ssid}`,
        author: global.appUser.email,
        station: global.activeStation.station,
      });

      // Refresh Navigation
      forceUpdate();
    } catch (e) {
      console.log("   - ERROR", e);
    }
  };

  return (
    <Box className="admin___panel_button">
      <Box className="title">
        <Videocam />
        <Typography>Video Source</Typography>
      </Box>
      <Box className="description">
        <Typography>
          Set the video source for {streamData.friendly} on SyncBox {streamData.activesb}.
        </Typography>
        {streamData.source && (
          <Typography>
            Video source currently set to <b>{streamData.source}</b>
          </Typography>
        )}
        <Typography>
          <b>NOTE:</b> This helps to direct individuals to a potential source of issues.
        </Typography>
      </Box>
      <Box
        sx={{
          cursor: "pointer",
          display: "flex",
          flexDirection: "column",
          gap: "0.25rem",
        }}
      >
        <Box className="status">
          <Button
            id="video-source-button"
            variant="outlined"
            aria-controls={open ? "video-source-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            endIcon={open ? <ExpandLess /> : <ExpandMore />}
            size="small"
          >
            {open ? "Select Source..." : streamData.source ? getSources(streamData.source) : "Select Source"}
          </Button>
          <Menu
            id="video-source-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {[
              { value: "MCR", name: "Master Control Room" },
              { value: "PCR", name: "Production Control Room" },
              { value: "ELEMENTAL", name: "AWS Elemental" },
              { value: "BRIGHTSIGN", name: "BrightSign" },
              { value: "DBI", name: "DBi" },
              { value: "JVC", name: "JVC" },
              { value: "OBS", name: "OBS" },
              { value: "SOFTRON", name: "Softron" },
              { value: "VMIX", name: "vMix" },
              { value: "WEATHERNATION", name: "WeatherNation" },
              { value: "WEATHERMETRICS", name: "Weather Metrics" },
              { value: "WEATHERMAX", name: "Weather MAX" },
              { value: "WIRECAST", name: "WireCast" },
              { value: "CUSTOM", name: "Custom Build" },
            ].map((item, key) => {
              return (
                <MenuItem
                  key={item.value}
                  onClick={() => {
                    handleClose();
                    setSource(streamData, item.value);
                  }}
                  sx={{
                    minWidth: "16.25rem",
                    fontSize: "0.875rem",
                    fontWeight: streamData?.source === item.value ? 700 : "inherit",
                    color: streamData?.source === item.value ? "rgba(15, 72, 255, 0.75)" : "inherit",
                  }}
                >
                  {item.name}
                </MenuItem>
              );
            })}
          </Menu>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(AdminVideoSource);
