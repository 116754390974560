import React, { memo, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactGa4 from "react-ga4";

// AWS Amplify GraphQL
import { API, graphqlOperation } from "aws-amplify";
import { updateUsers } from "../graphql/mutations";
import { listUsers } from "../graphql/queries";

// Material UI Components
import { StarSharp } from "@mui/icons-material";
import { Box, CssBaseline, Typography } from "@mui/material";

// Gray TV Components
import { setFavorites } from "../components/templates/includes/mainSidebarFunctions";
import { Application } from "../context/Application";
import { getRoutePath } from "../utilities/utility";

import MainSidebar from "../components/navigation/MainSidebar";
import Header from "../components/templates/Header";

// Gray TV Images
import TimelineImage from "../_images/screenshots/view-timeline.png";
import EventMonitorImage from "../_images/screenshots/event-monitor.png";
import AdAnalysisImage from "../_images/screenshots/analyze-breaks.png";
import VideoMonitorImage from "../_images/screenshots/video-monitor.png";

const WelcomeLayout = ({ route }) => {
  const global = useContext(Application);
  const goto = useNavigate();

  const handleSelect = (station) => {
    global.setActiveStream(null);
    global.setActiveStation(station);

    // Trigger a UI update by forcing a refresh
    global.setAppUI((prev) => ({ ...prev, error: null, forceUpdate: new Date().getTime() }));

    // Navigate to the new station's page
    goto(`/${getRoutePath(global.appUI.section)}/${station.station.toLowerCase()}`);
  };

  // Set Station as Favorite for Easier Visibility
  const handleRemove = async (data) => {
    try {
      const favArray = setFavorites({ favorites: global.favoriteList, data: data });

      if (!favArray) return;

      global.setFavoriteList(favArray);

      const response = await API.graphql(graphqlOperation(listUsers, { filter: { email: { eq: global.appUser.email } } }));
      const user = response?.data?.listUsers?.items[0];

      if (!user) return;

      const updateResponse = await API.graphql(
        graphqlOperation(updateUsers, { input: { id: user.id, favorites: JSON.stringify(favArray), _version: user._version } })
      );

      console.log("   - Update Response: ", updateResponse?.data?.updateUsers.favorites);

      // Trigger a UI update by forcing a refresh
      global.setAppUI((prev) => ({ ...prev, forceUpdate: new Date().getTime() }));
    } catch (e) {
      console.log("   - ERROR: Failure updating favorites list. ", e);
    }
  };

  // Set route in global state
  useEffect(() => {
    global.setActiveStation(null);
    global.setActiveStream(null);
    global.setAppUI((prev) => ({ ...prev, section: route }));

    document.title = "SyncMon Welcome | Gray Digital";
    ReactGa4.send({ hitType: "pageview" });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route, global.setAppUI]);

  return (
    <React.Fragment>
      <Header />
      <Box className="syncmonitor" sx={{}}>
        <CssBaseline />
        <MainSidebar items={global.stationList} />
        <Box className={"sidebar " + (global.appUI.showMobile ? "open" : "closed")} sx={{ height: "100vh" }}>
          <Box className="sidebar___wrapper">
            <Box className="sidebar___main" sx={{ maxWidth: "unset", padding: "0 0 0 2rem" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flexStart",
                  alignContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  height: "100%",
                  gap: "0.5rem",
                  width: "calc(100% - 2rem)",
                  paddingTop: "2rem",
                }}
              >
                {global.favoriteList && global.stationList && global.favoriteList.length > 0 && (
                  <Typography variant="h3" component="h3" sx={{ color: "#111", fontSize: "1.4rem", fontWeight: "normal", paddingBottom: "0.25rem" }}>
                    Favorited Stations
                  </Typography>
                )}
                {global.favoriteList && global.stationList && (
                  <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", justifyContent: "flex-start", width: "100%", flexWrap: "wrap" }}>
                    <>
                      {global.favoriteList.map((station, idx) => (
                        <Box key={idx} sx={{ display: "flex", flexDirection: "row", gap: "1rem", justifyContent: "flex-start" }}>
                          {global.stationList
                            .filter((e) => e.station === station)
                            .map((e, i) => (
                              <Box
                                key={i}
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "stretch",
                                  gap: "1rem",
                                  padding: "0.5rem 1rem",
                                  background: "#ffffff",
                                  borderRadius: "0.5rem",
                                  minWidth: "15rem",
                                  maxWidth: "15rem",
                                  width: "15rem",
                                  overflow: "hidden",
                                  cursor: "pointer",
                                  boxShadow: "rgba(6, 26, 54, 0.16) 0 0.25rem 1rem",

                                  "&:hover": {
                                    background: "#3481d4",
                                    color: "#fff",
                                  },
                                }}
                              >
                                <Box sx={{ flexGrow: 2, overflow: "hidden" }} onClick={() => handleSelect(e)}>
                                  <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }}>{e.station.toUpperCase()}</Typography>
                                  <Typography sx={{ fontSize: "0.75rem", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                    {e.dmaName}
                                  </Typography>
                                </Box>
                                <StarSharp sx={{ color: "gold", flexShrink: 0 }} onClick={() => handleRemove(station.toLowerCase())} />
                              </Box>
                            ))}
                        </Box>
                      ))}
                    </>
                  </Box>
                )}

                <Typography
                  variant="h3"
                  component="h3"
                  sx={{
                    color: "#111",
                    marginTop: global.favoriteList && global.stationList && global.favoriteList.length > 0 ? "3rem" : 0,
                    fontSize: "1.4rem",
                    fontWeight: "normal",
                    paddingBottom: "0.25rem",
                  }}
                >
                  SyncMon 101
                </Typography>
                <Box
                  sx={{
                    textAlign: "left",
                    width: "100%",
                    padding: "1rem",
                    background: "#ffffff",
                    borderRadius: "0.5rem",
                    flexGrow: 1,
                    boxShadow: "rgba(6, 26, 54, 0.16) 0 0.25rem 1rem",
                  }}
                >
                  <Typography variant="h6" component="h6" sx={{ fontSize: "1rem", padding: "1rem" }}>
                    Gray's SyncMon is a tool designed to help you monitor, assess, and troubleshoot both your streams as well as streaming events. The
                    tools within allow you to visually view events plotted against a timeline, or analyze ad breaks to help identify issues.
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignContent: "flex-start",
                      alignItems: "flex-start",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      background: "#ffffff",
                      borderRadius: "0.5rem",
                      padding: "3rem 1rem 0",
                      flexGrow: 1,
                      gap: "3rem 0.5rem",
                    }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "0.5rem", maxWidth: "23%", minWidth: 200 }}>
                      <Typography variant="h6" component="h6" sx={{ textTransform: "uppercase", fontSize: "1rem", fontWeight: "bold" }}>
                        Event Monitor
                        <img src={EventMonitorImage} alt="Event Monitor" style={{ marginTop: "0.5rem", width: "100%", border: "1px solid #ccc" }} />
                      </Typography>
                      <Typography variant="body2" component="p" sx={{ textAlign: "justify" }}>
                        The <b>Event Monitor</b> provides both a high-level summary as well as a deeply granular view of the events that define how a
                        stream is constructed: local streaming, blackout/LNL, or ad breaks.
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "0.5rem", maxWidth: "23%", minWidth: 200 }}>
                      <Typography variant="h6" component="h6" sx={{ textTransform: "uppercase", fontSize: "1rem", fontWeight: "bold" }}>
                        Event Timeline
                        <img src={TimelineImage} alt="Timeline" style={{ marginTop: "0.5rem", width: "100%", border: "1px solid #ccc" }} />
                      </Typography>
                      <Typography variant="body2" component="p" sx={{ textAlign: "justify" }}>
                        The <b>Timeline View</b> provides a visual overview of the events that construct your streams: 1) timing of program
                        start/stops; 2) local vs blackout/LNL; and 3) ad break frequency within local programs.
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "0.5rem", maxWidth: "23%", minWidth: 200 }}>
                      <Typography variant="h6" component="h6" sx={{ textTransform: "uppercase", fontSize: "1rem", fontWeight: "bold" }}>
                        Ad Breaks Analysis
                        <img src={AdAnalysisImage} alt="Ad Analysis" style={{ marginTop: "0.5rem", width: "100%", border: "1px solid #ccc" }} />
                      </Typography>
                      <Typography variant="body2" component="p" sx={{ textAlign: "justify" }}>
                        For Full SCTE and MiddleMan workflow stations, the <b>Ad Breaks Analysis</b> provides a detailed view of the stream's local ad
                        breaks to can help identify timing, duration, and triggering issues.
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "0.5rem", maxWidth: "23%", minWidth: 200 }}>
                      <Typography variant="h6" component="h6" sx={{ textTransform: "uppercase", fontSize: "1rem", fontWeight: "bold" }}>
                        Video Monitor
                        <img src={VideoMonitorImage} alt="Ad Analysis" style={{ marginTop: "0.5rem", width: "100%", border: "1px solid #ccc" }} />
                      </Typography>
                      <Typography variant="body2" component="p" sx={{ textAlign: "justify" }}>
                        <b>Video Monitor</b> highlights FAST streams, owned platform streams, and full program VOD for review, with focus on encoding,
                        captions, SCTE, META data, warnings, and errors.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default memo(WelcomeLayout);
