// React Components
import React, { memo, useContext } from "react";

// MUI Components
import { RestartAlt } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";

// DataStore Components
import { DataStore } from "aws-amplify";
import { Streams } from "../../models";

// Gray Components
import { isAdmin, isSuperAdmin } from "../../utilities/isAdmin";
import { Application } from "../../context/Application";
import setActivityLog from "../../utilities/setActivityLog";

import AdminStreamVisibility from "./AdminStreamVisibility";
import AdminWorkflowConfig from "./AdminWorkflowConfig";
import AdminStreamCategory from "./AdminStreamCategory";
import AdminPrimaryStream from "./AdminPrimaryStream";
import AdminTriggerSource from "./AdminTriggerSource";
import AdminVideoSource from "./AdminVideoSource";
import AdminInputSource from "./AdminInputSource";

// Gray Styles
import "../../_styles/AdminPanel.scss";

const AdminTools = ({ v, k, setAdminUpdate, selectStream }) => {
  const global = useContext(Application);

  const forceUpdate = () => {
    // Refresh Navigation
    global.setAppUI((prev) => ({ ...prev, forceUpdate: new Date().getTime() }));
  };

  /* Admin Tool - Set Stream as Primary for ActiveSB */
  const deleteDbEntries = async (data) => {
    const results = await DataStore.query(Streams, (c) => c.activesb.eq(data.activesb));

    try {
      if (results && results.length > 0) {
        results.forEach(async function (result) {
          await DataStore.delete(result);
        });
      }
      await setActivityLog({
        type: "RESET STREAM DB",
        note: `Encoder reset to default values for ${data.activesb}`,
        author: global.appUser.email,
        station: global.activeStation.station,
      });

      // Refresh Navigation
      forceUpdate();
    } catch (e) {
      console.log("   - ERROR", e);
    }
  };

  return (
    <Box className="eventmonitor___admin">
      <Box className="eventmonitor___admin_tools">
        {isAdmin(global.appUser.account, global.appUI.hideAdminTools, false) && (
          <Box className="eventmonitor___admin_tools___middle">
            <Typography
              sx={{
                fontSize: "0.75rem",
                fontWeight: "bold",
                padding: "1rem",
                backgroundColor: "#fff",
                borderRadius: "0.5rem",
                margin: "0  0.5rem 0 0 ",
              }}
            >
              ADMIN TOOLS FOR {v.friendly}
            </Typography>
            <Box className="eventmonitor___admin_tools___middle___buttons">
              <AdminPrimaryStream streamData={v} forceUpdate={forceUpdate} />
              <AdminStreamCategory streamData={v} forceUpdate={forceUpdate} />
              <AdminStreamVisibility streamData={v} forceUpdate={forceUpdate} />
              <AdminTriggerSource streamData={v} forceUpdate={forceUpdate} />
              <AdminVideoSource streamData={v} forceUpdate={forceUpdate} />
              <AdminWorkflowConfig streamData={v} forceUpdate={forceUpdate} />
              <AdminInputSource streamData={v} forceUpdate={forceUpdate} />

              {isSuperAdmin(global.appUser.account, global.appUI.hideAdminTools, false) && (
                <Box className="admin___panel_button">
                  <Box className="title superadmin">
                    <RestartAlt fontSize="1rem" />
                    <Typography>Reset Stream in DB</Typography>
                  </Box>
                  <Box className="description">
                    <Typography>
                      Remove all database values for <b>{v.friendly}</b> within the <b>SyncMon</b> database.
                    </Typography>

                    <Typography>
                      <span style={{ color: "#a00" }}>
                        <b>NOTE: </b>
                        This is a destructive action and cannot be reversed. Any configuration data will be lost
                      </span>
                    </Typography>
                  </Box>
                  <Box className="status">
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        deleteDbEntries(v);
                      }}
                      size="small"
                    >
                      Permanently Reset
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default memo(AdminTools);
