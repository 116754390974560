import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactGa4 from "react-ga4";

/* JWPlayer Components */
import JWPlayer from "@jwplayer/jwplayer-react";

/* Material UI Components */
import { Box, CircularProgress, Container, Tooltip, Typography } from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import { grey } from "@mui/material/colors";

/* Gray Digital Components */
import { Video } from "../../context/Video";
import VideoMonitorRowItem from "./includes/VideoMonitorRowItem";

// Gray Assets
import "./styles/VideoMonitor.scss";

// class PlayerContainer extends React.Component {
const VideoPlayer = () => {
  const globalVideo = useContext(Video);
  const param = useParams();

  const [time, setTime] = useState(null);
  const [player, setPlayer] = useState(null);
  const [quality, setQuality] = useState(null);

  const [playerLogs, setPlayerLogs] = useState([]);
  const [uniqueEvents, setUniqueEvents] = useState([]);

  const configDefaults = {
    width: 420,
    height: 236,
    autostart: "viewable",
    // renderCaptionsNatively: false,
    mute: true,
    horizontalVolumeSlider: true,
  };

  useEffect(() => {
    if (globalVideo.station.station && globalVideo.stream.url) {
      const distroTitle =
        typeof param.distro !== "undefined"
          ? param.distro.toUpperCase().replace(/_/g, " ")
          : globalVideo.stream.distro.toUpperCase().replace(/_/g, " ");

      document.title = globalVideo.station.station.toUpperCase() + " " + distroTitle + " Video Monitor | SyncMon | Gray Digital";
      ReactGa4.send({ hitType: "pageview" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalVideo.station.station, globalVideo.stream.url]);

  useEffect(() => {
    if (player && globalVideo.stream.url) {
      setPlayerLogs([]);
      setUniqueEvents([]);
      setQuality(null);
      setTime(null);
      player.load({ file: globalVideo.stream.url }).play();
    }
    return () => {
      // setPlayer(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalVideo.stream.url]);

  const onCopy = (copyText) => {
    navigator.clipboard
      .writeText(copyText)
      .then(() => {
        alert("Successfully copied: " + copyText);
      })
      .catch(() => {
        alert("Something went wrong");
      });
  };

  // Registers players as they mount
  const playerMountedCallback = ({ player, id }) => {
    setPlayer(player);
  };

  // Nulls registered players as they unmount
  const playerUnmountingCallback = ({ id }) => {
    setPlayer(null);
  };

  const onAll = (event) => {
    const skip = [
      "beforePlay",
      "breakpoint",
      "buffer",
      "bufferFull",
      "captionsChanged",
      "captionsList",
      "discontinuity",
      "error",
      "firstFrame",
      // "id3",
      "levels",
      "mediaError",
      "mediaType",
      "meta",
      "metadataCueParsed",
      "play",
      "playlist",
      "playlistItem",
      "playAttempt",
      "providerFirstFrame",
      "ready",
      "resize",
      "seeked",
      "time",
      "subtitlesTracks",
      "userActive",
      "userInactive",
      "viewable",
      "visualQuality",
      "warning",
    ];

    if (skip.includes(event) === false) console.log(" - " + event);

    let uniques = [];
    playerLogs.forEach((c) => {
      if (!uniques.includes(c)) {
        uniques.push(c);
      }
    });
    setUniqueEvents(uniques);
  };

  const check = ({ type, value }) => {
    let scrub = false;
    let scrubbed = value;
    const tstamp = Math.round(Date.now() / 1000) * 1000;
    const disallowed = ["context", "loader", "networkDetails"]; // create circular objects

    if (typeof scrubbed === "object" && !Array.isArray(scrubbed) && scrubbed !== null) {
      disallowed.map((v) => {
        if (v in scrubbed) {
          console.log("Removing " + v + " from Object");
          delete scrubbed["v"];
          scrub = true;
        }
        return true;
      });
    }
    if (scrub) {
      console.log("Scrubbed: ", scrubbed);
    }

    const newLog = JSON.stringify({
      time: tstamp,
      type: type,
      value: scrubbed,
    });
    setPlayerLogs((previous) => [newLog, ...previous]);
  };

  const onReady = (e) => {
    // console.log("Ready: ", e);
  };

  const onProviderFirstFrame = (e) => {
    // console.log("Provider First Frame: ", e);
  };

  const onPlayAttempt = (e) => {
    // console.log("Play Attempt: ", e);
  };

  const onPlaylist = (e) => {
    // console.log("Playlist: ", e);
  };

  const onPlaylistItem = (e) => {
    // console.log("Playlist Items: ", e);
  };

  const onBuffer = (e) => {
    // console.log("Buffer: ", e);
  };

  const onBufferFull = (e) => {
    // console.log("Buffer Full: ", e);
  };

  const onMetadataCueParsed = (e) => {
    if (e.type !== "id3") check({ type: e.type, value: e });
  };

  const onSubtitlesTracks = (e) => {
    // console.log(e);
  };

  const onLevels = (e) => {
    check({ type: e.type, value: e.levels });
  };

  const onLevelsChanged = (e) => {
    // console.log(e);
  };

  const onDestroyPlugin = (e) => {
    console.log("onDestroyPlugin: ", e);
  };

  const onMediaType = (e) => {
    // console.log("Media Type: ", e);
  };

  const onId3 = (e) => {
    check({ type: e.type, value: e });
  };

  const onWarning = (e) => {
    // console.log(e.sourceError);
    check({ type: e.type, value: e.sourceError });
  };

  const onError = (e) => {
    // console.log(e.sourceError);
    check({ type: e.type, value: e.sourceError });
  };

  const onMediaError = (e) => {
    // console.log(e.sourceError);
    check({ type: e.type, value: e.sourceError });
  };

  const onVisualQuality = (e) => {
    setQuality(e);
    // console.log(e);
  };

  const onTime = (e) => {
    setTime(e);
    // console.log(e);
  };

  // Prevent multiple players from playing simultaneously
  const onBeforePlay = (event) => {
    //   console.log(player.getState());
  };

  const onMeta = (e) => {
    // console.log("META: ", e.metadataType);
  };

  const onCaptionsList = (e) => {
    // console.log("CaptionsList: ", e);
    check({ type: "captions", value: e.tracks });
    if (player && e.tracks.length > 1) player.setCurrentCaptions(1);
  };

  const onCaptionsChange = (e) => {
    // console.log("CaptionsChange: ", e);
    // check({ type: "captions", value: e.tracks });
    // if (player && e.tracks.length > 1) player.setCurrentCaptions(1);
  };

  const onPause = (e) => {
    // console.log(e);
  };

  // Put teal colored outline on currently playing player, remove it from all other players.
  const onPlay = (event) => {
    if (player) {
      // const container = player.getContainer();
      // if (player.getState() === "playing") {
      // container.style.borderRight = "1px solid #ccc";
      // } else {
      // container.style.border = "";
      // }
    }
  };

  return (
    <Box className="videoplayer" component="main" sx={{ flexGrow: 1 }}>
      {globalVideo.stream && globalVideo.stream.url !== "" ? (
        <Box className="station">
          <Box className="player-container">
            <Box className="player-columns">
              <JWPlayer
                config={configDefaults}
                onBeforePlay={onBeforePlay}
                onAll={onAll}
                onBuffer={onBuffer}
                onBufferFull={onBufferFull}
                onCaptionsList={onCaptionsList}
                onCaptionsChange={onCaptionsChange}
                onDestroyPlugin={onDestroyPlugin}
                onError={onError}
                onId3={onId3}
                onLevels={onLevels}
                onLevelsChanged={onLevelsChanged}
                onMediaError={onMediaError}
                onMediaType={onMediaType}
                onMeta={onMeta}
                onMetadataCueParsed={onMetadataCueParsed}
                onPause={onPause}
                onPlay={onPlay}
                onPlaylist={onPlaylist}
                onPlaylistItem={onPlaylistItem}
                onPlayAttempt={onPlayAttempt}
                onProviderFirstFrame={onProviderFirstFrame}
                onReady={onReady}
                onSubtitlesTracks={onSubtitlesTracks}
                onTime={onTime}
                onVisualQuality={onVisualQuality}
                onWarning={onWarning}
                didMountCallback={playerMountedCallback}
                willUnmountCallback={playerUnmountingCallback}
                file={globalVideo.stream.url}
                library="https://cdn.jwplayer.com/libraries/J6gZEGpb.js"
              />
              {globalVideo.stream.type === "vod" && (
                <Box className="vodMeta">
                  <Typography className="title">
                    {globalVideo.stream.title} &nbsp;
                    <span>({Math.round(globalVideo.stream.duration / 1000 / 60)}m)</span>
                  </Typography>
                  <Typography className="description">{globalVideo.stream.desc}</Typography>
                  {globalVideo.stream.adbreaks && globalVideo.stream.adbreaks.length > 0 && (
                    <Typography className="title" sx={{ marginTop: "0.5rem" }}>
                      Ad Breaks
                    </Typography>
                  )}
                  {globalVideo.stream.adbreaks &&
                    globalVideo.stream.adbreaks.length > 0 &&
                    globalVideo.stream.adbreaks.map((val, key) => {
                      return (
                        <Box
                          key={"adbreak_" + key}
                          className="adbreak"
                          sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
                        >
                          <Typography className="adbreakTitle" sx={{ fontSize: "0.75rem" }}>
                            {key + 1}. Ad Break
                          </Typography>
                          <Typography className="adbreakTime" sx={{ fontSize: "0.75rem" }}>
                            @ {Math.round(val.offset / 1000 / 60)}m {Math.round((val.offset / 1000) % 60)}s
                          </Typography>
                        </Box>
                      );
                    })}
                </Box>
              )}
              {time && (
                <Box className="metaTable">
                  <Typography variant="h5">Playback Information</Typography>
                  <Box>
                    <Typography>Station:</Typography>
                    <Typography>{globalVideo.station.station.toUpperCase()}</Typography>
                  </Box>
                  {globalVideo.stream.type === "distro" && (
                    <Box>
                      <Typography>Distrbution Partner:</Typography>
                      <Tooltip title="Copy stream URL" arrow placement="left">
                        <Typography className="copy" onClick={(e) => onCopy(globalVideo.stream.url)}>
                          <ContentCopy style={{ fontSize: "0.875rem" }} />
                          {globalVideo.stream.distro}
                        </Typography>
                      </Tooltip>
                    </Box>
                  )}
                  <Box>
                    <Typography>Position:</Typography>
                    <Typography>{(Math.round(time.currentTime * 100000) / 100000).toFixed(4)}</Typography>
                  </Box>
                  <Box>
                    <Typography>Latency:</Typography>
                    <Typography>{(Math.round(time.latency * 100) / 100).toFixed(2)} sec</Typography>
                  </Box>
                  <Box>
                    <Typography>Target Latency:</Typography>
                    <Typography>{time.targetLatency} sec</Typography>
                  </Box>
                  <Box>
                    <Typography>Quality:</Typography>
                    {quality ? (
                      <Typography>
                        {quality.level.height}p ({Math.round(quality.level.bitrate / 1000)} kbps)
                      </Typography>
                    ) : (
                      <Typography>NaN</Typography>
                    )}
                  </Box>
                  {globalVideo.station.note && (
                    <Box
                      style={{
                        justifyContent: "flex-start",
                        gap: "0.5rem",
                        fontStyle: "italic",
                        marginTop: "1rem",
                        color: grey["A700"],
                      }}
                    >
                      <Typography style={{ fontWeight: 900 }}>Note:</Typography>
                      <Typography>
                        {globalVideo.station.note} (Information current as of {globalVideo.station.noteDate})
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "stretch",
              flexGrow: 2,
              overflow: "hidden",
              padding: "1rem",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                boxShadow: "rgba(6, 26, 54, 0.16) 0 0.25rem 1rem",
              }}
            >
              Event Debugging
            </Typography>
            <Box className="logColumn">
              <Box className="logRow header">
                <Box>Time</Box>
                <Box>Type</Box>
                <Box>Information</Box>
              </Box>
              <Box className="logTable">
                {uniqueEvents.map((val, key) => {
                  return <VideoMonitorRowItem key={key + "-" + val} item={val} />;
                })}
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
          <Typography variant="body1" sx={{ marginTop: "1rem", color: "#36c" }}>
            Loading
          </Typography>
        </Container>
      )}
    </Box>
  );
};
export default VideoPlayer;
