// React Components
import React, { memo } from "react";

// MUI Components
import { Box, ListItem, ListItemButton, Skeleton } from "@mui/material";

const RowSkeleton = () => {
  return (
    <ListItem disablePadding className="station___navigation_row">
      <ListItemButton disabled={true} className="station___navigation_row_button">
        <Box sx={{ width: "100%" }}>
          <Skeleton className="skeleton___title" />
          <Skeleton className="skeleton___subtitle" width="60%" />
        </Box>
      </ListItemButton>
    </ListItem>
  );
};

export default memo(RowSkeleton);
