import React, { useContext } from "react";

// Material-UI
import { KeyboardArrowDown, KeyboardArrowRight, Search } from "@mui/icons-material";
import { Box, List } from "@mui/material";

// Gray TV Components
import { Application } from "../../../../context/Application";
import SearchBox from "./SearchBox";
import RowItem from "./RowItem";

const SearchSection = ({ searched, searchTerm, handleSearch, handleSetFavorites, handleSelect }) => {
  const global = useContext(Application);

  const handleSearchCollapsed = () =>
    global.setAppUI({ ...global.appUI, showMobile: !global.appUI.showMobile, showSearch: !global.appUI.showSearch, pinNav: false });

  const handleSearchToggle = () =>
    global.setAppUI({ ...global.appUI, showFavorites: false, showStations: false, showSearch: !global.appUI.showSearch });

  return (
    <React.Fragment>
      <Box className="station___navigation_parent" sx={{ width: "100%", flexWrap: "wrap" }}>
        <Box
          sx={{ display: "flex", flexDirection: "row", gap: "0.25rem", alignContent: "center", alignItems: "center", width: "100%" }}
          onClick={() => (global.appUI.showMobile ? handleSearchToggle() : handleSearchCollapsed())}
        >
          <Search className="home___icon" sx={{ height: "1rem" }} />
          {global.appUI.showMobile && (
            <>
              <Box sx={{ flexGrow: 2 }}>Search</Box>
              {global.appUI.showSearch ? <KeyboardArrowDown className="active" /> : <KeyboardArrowRight className="inactive" />}
            </>
          )}
        </Box>
        <SearchBox show={global.appUI.showSearch && global.appUI.showMobile} search={searchTerm} onSearch={handleSearch} />
      </Box>
      <List className="active" sx={{ display: global.appUI.showSearch && global.appUI.showMobile ? "block" : "none" }}>
        {searched &&
          global.favoriteList &&
          searched.map((val, key) => {
            return <RowItem key={key} data={val} onSelect={handleSelect} onFavorite={handleSetFavorites} favorites={global.favoriteList} />;
          })}
      </List>
    </React.Fragment>
  );
};

export default SearchSection;
