import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

// MUI Components
import { HelpOutline, PlayCircleOutline } from "@mui/icons-material";
import { Avatar, Box, Tooltip, Typography } from "@mui/material";
import { grey, orange } from "@mui/material/colors";

// Gray TV Components
import { updateLiveM3U8 } from "../../../../utilities/getStationLive";
import { Application } from "../../../../context/Application";
import { Video } from "../../../../context/Video";

const LiveList = ({ data }) => {
  const global = useContext(Application);
  const globalVideo = useContext(Video);
  const goto = useNavigate();

  // On Select Functions
  const onSelectLive = (live) => {
    const abortController = new AbortController();

    updateLiveM3U8(global.activeStation, live.id, abortController).then((response) => {
      globalVideo.setStream({
        url: response.apiResponse.streamUrl,
        title: response.apiResponse.title,
        distro: response.apiResponse.id,
        type: "live",
      });
      goto("/video/" + global.activeStation.station.toLowerCase() + "/live/" + live.id);
    });

    return () => abortController.abort();
  };

  return (
    <React.Fragment>
      <Box className="streams___container_label">
        LIVE CHANNELS
        <Tooltip title="Previously aired on demand programming from the selected Gray station for review." arrow placement="bottom-start">
          <HelpOutline className="label___tooltip" />
        </Tooltip>
      </Box>
      <Box className="streams___list">
        {globalVideo.station.streams &&
          globalVideo.station.streams.length > 0 &&
          data.map((val, key) => {
            return (
              <Box
                key={"streams___live" + key}
                className={"streams___list_row " + (globalVideo.stream.distro === val.id ? "active" : "inactive")}
                onClick={() => onSelectLive(val)}
              >
                <Box className="streams___list_meta distro">
                  <Avatar
                    sx={{
                      background:
                        globalVideo.stream.url.toLowerCase().replace(/\s+/g, "_") === val.streamUrl.toLowerCase().replace(/\s+/g, "_")
                          ? orange[500]
                          : grey[700],
                      color: "#fff",
                      height: "0.875rem",
                      width: "0.875rem",
                    }}
                  >
                    <PlayCircleOutline />
                  </Avatar>
                  <Box className="meta___title">
                    {val.taxonomy &&
                      val.taxonomy.length > 0 &&
                      val.taxonomy[0].terms &&
                      val.taxonomy[0].terms.length > 0 &&
                      val.taxonomy[0].terms[0] && <Typography className="streams___primary_label">{val.taxonomy[0].terms[0]}</Typography>}
                    <span>
                      <b>{val.title}</b>
                    </span>
                  </Box>
                </Box>
              </Box>
            );
          })}
      </Box>
    </React.Fragment>
  );
};

export default LiveList;
