// React Component
import React, { memo } from "react";

// MUI Components
import { Person } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

const ButtonAccounts = ({ status, action }) => {
  return (
    <Tooltip title="Account Info" arrow placement="bottom-end">
      <Person
        size="small"
        onClick={action}
        className="header___right_notifications"
        aria-haspopup="true"
        aria-expanded={status ? "true" : undefined}
        aria-controls={status ? "updates-menu" : undefined}
      />
    </Tooltip>
  );
};

export default memo(ButtonAccounts);
