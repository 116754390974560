import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

// Okta Components
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";

// Gray Components
import { Authentication } from "../../context/Authentication";
import { oktaConfig } from "./okta/config";
import AppRoutes from "../../routing/AppRoutes";

/* Initialize Okta Authentication */
const oktaAuth = new OktaAuth(oktaConfig.oidc);

/* Wraps all routes with Okta Authentication */
const Auth = ({ routePath }) => {
  const authGlobal = useContext(Authentication);

  /* Restore original URI after login */
  const navigate = useNavigate();
  const restoreOriginalUri = (_oktaAuth, originalUri) => {
    authGlobal.setIsAuthenticated(true);

    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <AppRoutes routePath={routePath} />
    </Security>
  );
};

export default Auth;
