// React Components
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactGa4 from "react-ga4";

// Material UI Components
import { Alert, Box, Button, Slide, Snackbar, Typography } from "@mui/material";
import { Check, CloseOutlined } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";

// Gray Components
import { getEventLogs, prepRow, warningCheck } from "../../utilities/getEventLogs";
import { getEventLogColumns } from "../../utilities/getEventLogColumns";
import { getFilterTypeName } from "../../utilities/getFilterTypeName";
import { Application } from "../../context/Application";

import LoadingSkeleton from "./subcomponents/LoadingSkeleton";
import StreamDetails from "./subcomponents/StreamDetails";
import TimelineModal from "../../layouts/TimelineModal";
import ScteWarnings from "./subcomponents/ScteWarnings";
import ShareReport from "./subcomponents/ShareReport";
import AdminPanel from "../streamsAdmin/AdminPanel";
import Footnotes from "./subcomponents/Footnotes";
import StreamDateRange from "./StreamDateRange";
import StreamMeta from "./StreamMeta";
import EventType from "./EventType";

// Gray Assets
import "./styles/EventMonitor.scss";

const EventMonitor = ({ loading, setLoading }) => {
  const global = useContext(Application);
  const goto = useNavigate();

  const [apiResponse, setApiResponse] = useState(null);
  const [eventFilter, setEventFilter] = useState(null);
  const [filteredResponse, setFilteredResponse] = useState(null);
  const [filteredMeta, setFilteredMeta] = useState(null);

  const [sortModel, setSortModel] = useState([{ field: "Time", sort: "desc" }]);
  const [totalDuration, setTotalDuration] = useState(0);
  const [timelineState, setTimelineState] = useState(false);

  const [showAdmin, setShowAdmin] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(false);

  // UI Functions
  const handleCloseAlert = () => {
    global.setAppUI((prev) => ({ ...prev, error: null }));
  };

  const onFilter = (type) => {
    let i = 1;
    let results = [];
    const localEventList = [...filteredMeta];

    global.setAppUI((prev) => ({ ...prev, error: null }));

    if (type === null) {
      setEventFilter(null);
      setFilteredResponse(localEventList);
    } else {
      localEventList.forEach((e) => {
        if (type === "warning") {
          setEventFilter("warning");
          if (warningCheck(e)) {
            results.push(prepRow(i, e));
            i++;
          }
          setEventFilter(type);
        } else if (type >= 0 && type !== null) {
          if (e.EventType === 0 || (e.EventType > 0 && e.IsBegin === true)) {
            if (e.EventType === type) {
              results.push(prepRow(i, e));
              i++;
            }
          }
          setEventFilter(type);
        }
      });

      if (results.length === 0) {
        global.setAppUI((prev) => ({
          ...prev,
          error: "No events found in the " + global.activeStream.ssname + " stream for filter: " + getFilterTypeName({ type: type }),
        }));
        setEventFilter(null);
      } else {
        setFilteredResponse(results);
      }
    }
  };

  // Hooks
  useEffect(() => {
    global.setAppUI((prev) => ({ ...prev, helpTopic: "reports" }));

    if (global.activeStation) {
      document.title = global.activeStream.ssname + " | " + global.activeStation.station.toUpperCase() + " Events Monitor | SyncMon | Gray Digital";
      ReactGa4.send({ hitType: "pageview" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [global.activeStation.station, global.activeStream]);

  /* Get Logs on load */
  useEffect(() => {
    const abortController = new AbortController();

    getEventLogs({
      startDate: global.appDates.start,
      endDate: global.appDates.end,
      timezone: global.appDates.timezone,
      stream: global.activeStream,
      abortController: abortController,
    })
      .then((response) => {
        if ("error" in response) {
          setApiResponse(null);
          setFilteredResponse(null);
          setFilteredMeta(null);
          setTotalDuration(0);

          global.setAppUI((prev) => ({ ...prev, error: "Error fetching logs from Zeam API " }));

          goto("/view/" + global.activeStation.station.toLowerCase());
        } else {
          setApiResponse(response.apiResponse);
          setFilteredResponse(response.filteredResponse);
          setFilteredMeta(response.filteredResponse);
          setTotalDuration(response.totalDuration);

          global.setAppUI((prev) => ({ ...prev, error: response.errorMsg }));
        }
        setLoading(false);
        setUpdateStatus(true);
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          console.log("   - Fetch aborted:", error.message);
        } else {
          console.log("   - Fetch problem:", error.message);
        }

        setApiResponse(null);
        setFilteredResponse(null);
        setFilteredMeta(null);
        setTotalDuration(0);

        global.setAppUI((prev) => ({ ...prev, error: "Aborted: Error fetching logs from Zeam API " }));
      });

    return () => {
      abortController.abort();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [global.activeStream.ssid, global.appDates.start, global.appDates.end]);

  useEffect(() => {
    if (filteredMeta && filteredMeta.length > 0 && eventFilter !== null) {
      onFilter(eventFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredMeta]);

  function SlideTransition(props) {
    return <Slide {...props} direction="right" />;
  }

  return (
    <Box className="eventlogs___wrapper" sx={{ display: "flex", flexDirection: "row", gap: "0", flexGrow: 2 }}>
      <StreamDetails v={global.activeStream} showAdmin={showAdmin} setShowAdmin={setShowAdmin} />

      {showAdmin ? (
        <AdminPanel v={global.activeStream} />
      ) : (
        <Box className="eventlogs___container" component="main">
          {global.appUI.error && (
            <Alert
              severity="error"
              className="error"
              action={
                <Button color="inherit" size="small">
                  <CloseOutlined onClick={() => handleCloseAlert()} />
                </Button>
              }
            >
              {global.appUI.error}
            </Alert>
          )}
          <StreamDateRange setTimelineState={setTimelineState} />

          <Snackbar open={updateStatus} autoHideDuration={10000} onClose={() => setUpdateStatus(false)} TransitionComponent={SlideTransition}>
            <Alert icon={<Check fontSize="inherit" />} severity="success">
              <b>UPDATED</b>: "Event Monitor" has been refreshed to reflect the newest event data for {global.activeStation.station.toUpperCase()}
            </Alert>
          </Snackbar>

          {filteredMeta && loading === false && (eventFilter >= 0 || eventFilter !== "") && apiResponse && <ScteWarnings logs={filteredMeta} />}
          {filteredMeta && loading === false && (eventFilter >= 0 || eventFilter !== "") && apiResponse && <StreamMeta logs={filteredMeta} />}

          {filteredResponse && loading === false && (
            <Box className="eventlogs___filter">
              <Typography variant="subtitle2">Filter {global.activeStation.station.toUpperCase()} stream by:</Typography>
              <Box>
                <Box className="eventlogs___filter_row">
                  {[0, 1, 2].map((val) => {
                    return <EventType key={val} type={val} format={true} filter={eventFilter} onFilter={onFilter} />;
                  })}
                  <EventType type={"warning"} format={true} filter={eventFilter} onFilter={onFilter} />
                </Box>
              </Box>
              <ShareReport />
            </Box>
          )}
          {filteredResponse && loading === false ? (
            <Box className="eventlogs___table" style={{ width: "100%", height: "320px", flexGrow: 2 }}>
              <DataGrid
                className="data-grid"
                density="compact"
                rows={filteredResponse}
                columns={getEventLogColumns({ timezone: global.appDates.timezone })}
                pageSize={100}
                rowsPerPageOptions={[100]}
                getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd")}
                disableSelectionOnClick
                disableColumnMenu
                hideFooterSelectedRowCount
                sortModel={sortModel}
                onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                sx={{ borderRadius: "0.5rem", border: 0 }}
              />
            </Box>
          ) : (
            <LoadingSkeleton />
          )}

          <Footnotes />
          <TimelineModal
            timelineState={timelineState}
            totalDuration={totalDuration}
            filteredResponse={filteredResponse}
            setTimelineState={setTimelineState}
          />
        </Box>
      )}
    </Box>
  );
};

export default React.memo(EventMonitor);
