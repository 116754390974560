import { Avatar, Box, Divider, Tooltip, Typography } from "@mui/material";
import React, { memo, useContext, useEffect, useState } from "react";
import { blue, blueGrey, green, grey, lightBlue, orange, purple, red } from "@mui/material/colors";
import {
  AttachMoneyOutlined,
  CancelOutlined,
  CircleOutlined,
  Error,
  ErrorOutline,
  MoneyOffOutlined,
  PlayCircleOutline,
  StopCircleOutlined,
} from "@mui/icons-material";

/* Gray Components */
import { getBoxGpiLogs } from "../../../../utilities/getBoxGpiState";
import { Application } from "../../../../context/Application";

const GpiState = ({ data }) => {
  const update_frequency = 60000;

  const global = useContext(Application);
  const [gpiState, setGpiState] = useState(null);

  /* Get GPI Logs on load */
  useEffect(() => {
    const abortController = new AbortController();

    const fetchLogs = async () => {
      const res = await getBoxGpiLogs(data, abortController);
      if (!abortController.signal.aborted) {
        if (res.apiResponse) {
          setGpiState(res.apiResponse);
        } else {
          setGpiState(null);
          global.setAppUI((prev) => ({ ...prev, error: res.errorMsg }));
        }
      }
    };

    if (data.activesb !== null) {
      fetchLogs();

      const interval = setInterval(() => {
        const newAbortController = new AbortController(); // Create a new abort controller for each fetch
        fetchLogs(newAbortController);
      }, update_frequency);

      return () => {
        clearInterval(interval);
        abortController.abort();
      };
    }

    return () => abortController.abort();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.activesb]);

  const StreamState = () => {
    var sdi1 = "";
    var sdi2 = "";

    Object.keys(gpiState).map((key) => {
      if (key < 4) {
        sdi1 += gpiState[key];
      } else {
        sdi2 += gpiState[key];
      }
      return true;
    });

    var title = "";
    var icon = <CircleOutlined />;
    var color = grey[100];

    /* Tooltip Component */
    const GpiWarning = () => {
      return (
        <Box>
          <Divider
            sx={{
              margin: "0.5rem 0",
              backgroundColor: grey[400],
              height: 1,
            }}
          />
          <Typography variant="body2" sx={{ fontSize: "0.75rem" }}>
            <b>NOTE</b>:
          </Typography>
          {data.config === "SNOWFLAKE" ? (
            <Typography variant="body2" sx={{ fontSize: "0.75rem" }}>
              Station stream state cannot be determined as it is on a non-standardized workflow. GPI pin states are solely surfaced for
              troubleshooting efforts.
            </Typography>
          ) : (
            <Typography variant="body2" sx={{ fontSize: "0.75rem" }}>
              This is a non-standard GPI pin combination as pins zero and four are ignored and thus should not be enabled. The station stream state
              should still be correctly reflected.
            </Typography>
          )}
        </Box>
      );
    };

    const GpiMsg = ({ pins, title }) => {
      return (
        <React.Fragment>
          <Typography variant="caption">{title}</Typography>
          {(pins.charAt(0) === "1" || data.config === "SNOWFLAKE") && <GpiWarning />}
        </React.Fragment>
      );
    };

    /* Switch to identify state for pins */
    const SdiStatus = ({ pins, num }) => {
      const pinArray = num === 0 ? [0, 1, 2, 3] : [4, 5, 6, 7];

      if (data.config === "TBW" || data.config === null || data.config === "MIDDLEMAN") {
        switch (pins) {
          case "0000":
          case "1000": //non-standard
            color = orange[500];
            icon = pins.charAt(0) === "1" ? <ErrorOutline /> : <StopCircleOutlined />;
            title = "SDI " + (num + 1) + ": Blackout / LNL";
            break;

          case "0100":
          case "0101":
          case "1100": //non-standard
          case "1101": //non-standard
            color = green[500];
            icon = pins.charAt(0) === "1" ? <ErrorOutline /> : <AttachMoneyOutlined />;
            title = "SDI " + (num + 1) + ": Ad Break";
            break;

          case "0011":
          case "0001":
          case "1011": //non-standard
          case "1001": //non-standard
            color = blue[500];
            icon = pins.charAt(0) === "1" ? <ErrorOutline /> : <PlayCircleOutline />;
            title = "SDI " + (num + 1) + ": Scheduled Streaming";
            break;

          case "0010":
          case "1010": //non-standard
            color = purple[500];
            icon = pins.charAt(0) === "1" ? <ErrorOutline /> : <PlayCircleOutline />;
            title = "SDI " + (num + 1) + ": Override Streaming";
            break;

          default:
            color = red[900];
            icon = <CancelOutlined />;
            title = "SDI " + (num + 1) + ": Unknown State";
            break;
        }
      } else if (data.config === "FULLSCTE") {
        switch (pins) {
          case "0000":
          case "1000": //non-standard
            color = orange[500];
            icon = pins.charAt(0) === "1" ? <ErrorOutline /> : <StopCircleOutlined />;
            title = "SDI " + (num + 1) + ": Blackout / LNL";
            break;

          case "0100":
          case "0101":
          case "1100": //non-standard
          case "1101": //non-standard
            color = green[200];
            icon = pins.charAt(0) === "1" ? <ErrorOutline /> : <MoneyOffOutlined />;
            title = "SDI " + (num + 1) + ": Ad Breaks are not controlled by GPIs in this configuration";
            break;

          case "0011":
          case "0001":
          case "1011": //non-standard
          case "1001": //non-standard
            color = blue[500];
            icon = pins.charAt(0) === "1" ? <ErrorOutline /> : <PlayCircleOutline />;
            title = "SDI " + (num + 1) + ": Scheduled Streaming";
            break;

          case "0010":
          case "1010": //non-standard
            color = purple[500];
            icon = pins.charAt(0) === "1" ? <ErrorOutline /> : <PlayCircleOutline />;
            title = "SDI " + (num + 1) + ": Override Streaming";
            break;

          default:
            color = red[900];
            icon = <CancelOutlined />;
            title = "SDI " + (num + 1) + ": Unknown State";
            break;
        }
      } else if (data.config === "SNOWFLAKE") {
        color = lightBlue[900];
        icon = <Error />;
        title = "SDI " + (num + 1) + ": Unknown State";
      }

      return (
        <>
          {data.config !== "FULLSCTE" && (data.input === "OTHER" || !data.input || `SOURCE${num + 1}` === data.input) && (
            <Box
              key={data.ssname}
              className="station___stream_left gpi_state"
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "0.5rem",
                alignItems: "center",
                paddingBottom: "0.25rem",
                justifyContent: "right",
              }}
            >
              <Typography variant="body2" sx={{ fontSize: "0.75rem" }}>
                SDI {num + 1}: &nbsp;
              </Typography>
              <Tooltip title={<GpiMsg pins={pins} title={title} />} arrow placement="left">
                <Avatar
                  className="gpi___avatar"
                  sx={{
                    backgroundColor: gpiState && Object.keys(gpiState).length >= 4 ? color : grey[100],
                  }}
                >
                  {icon}
                </Avatar>
              </Tooltip>
              <Divider orientation="vertical" flexitem="true" sx={{ minWidth: "1px", height: "0.75rem", background: grey[100] }} />
              <Box sx={{ display: "flex", flexDirection: "row", gap: "0.25rem" }}>
                {pinArray.map((val, key) => {
                  return (
                    <Avatar
                      key={data.ssname + "-gpi-" + key}
                      sx={{
                        backgroundColor: gpiState && Object.keys(gpiState).length >= 4 && gpiState[val] === 1 ? blueGrey[600] : blueGrey[100],
                        height: "1.125rem",
                        width: "1.125rem",
                        margin: "0 0",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          color: gpiState && Object.keys(gpiState).length >= 4 && gpiState[val] === 1 ? "white" : blueGrey[300],
                        }}
                      >
                        {val}
                      </Typography>
                    </Avatar>
                  );
                })}
              </Box>
            </Box>
          )}
        </>
      );
    };

    return (
      <React.Fragment>
        {data.config !== "FULLSCTE" ? (
          <>
            {[sdi1, sdi2].map((val, num) => {
              return <SdiStatus key={data.ssname + "-active-" + num} pins={val} num={num} />;
            })}
          </>
        ) : (
          <Box sx={{ fontSize: "0.675rem", margin: "0 0.5rem 0 1.75rem", fontStyle: "italic", color: "#888" }}>
            GPI triggers not enabled for full SCTE
          </Box>
        )}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {gpiState && Object.keys(gpiState).length > 4 ? (
        <StreamState />
      ) : (
        <React.Fragment>
          {data.config !== "FULLSCTE" ? (
            <>
              {[0, 1].map((val) => {
                return (
                  <Box key={data.ssname + "-inactive=" + val}>
                    {(data.input === "OTHER" || !data.input || `SOURCE${val + 1}` === data.input) && (
                      <Box
                        className="station___stream_left gpi_state"
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "0.5rem",
                          alignItems: "center",
                          paddingBottom: "0.25rem",
                          justifyContent: "right",
                        }}
                      >
                        <Avatar
                          key={data.ssname + "-gpi-offline-state"}
                          sx={{
                            backgroundColor: grey[100],
                            height: "1.25rem",
                            width: "1.25rem",
                            margin: "0 0",
                          }}
                        >
                          {""}
                        </Avatar>
                        <Divider
                          orientation="vertical"
                          flexitem="true"
                          sx={{
                            minWidth: "1px",
                            height: "0.75rem",
                            background: grey[100],
                          }}
                        />{" "}
                        {[0, 1, 2, 3].map((val, key) => {
                          return (
                            <Avatar
                              key={data.ssname + "-gpi-offline-" + key}
                              sx={{
                                backgroundColor: grey[100],
                                height: "1.125rem",
                                width: "1.125rem",
                                margin: "0 0",
                              }}
                            >
                              {""}
                            </Avatar>
                          );
                        })}
                      </Box>
                    )}
                  </Box>
                );
              })}
            </>
          ) : (
            <Box sx={{ fontSize: "0.675rem", margin: "0 0.5rem 0 1.75rem", fontStyle: "italic", color: "#888" }}>
              GPI triggers not enabled for full SCTE
            </Box>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default memo(GpiState);
