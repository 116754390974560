import React, { useContext, useEffect, useState } from "react";
import ReactGa4 from "react-ga4";

// MUI Components
import { CommentOutlined, Settings } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

// AWS Components
import { DataStore } from "aws-amplify";
import { Encoders } from "../../../models";

// Gray Components
import { getWorkflow } from "../../navigation/includes/EventsSidebar/getWorkflow";
import { getCategory, getSources, getTriggers } from "../../../utilities/utility";
import { Application } from "../../../context/Application";
import { isAdmin } from "../../../utilities/isAdmin";

import MenuRowItem from "../../navigation/includes/EventsSidebar/MenuRowItem";

const StreamDetails = ({ v, showAdmin, setShowAdmin }) => {
  const global = useContext(Application);
  const [encoder, setEncoder] = useState(null);

  const handleCommentsClose = (state) => {
    global.setAppUI((prev) => ({ ...prev, showComments: state }));
  };

  useEffect(() => {
    if (v.activesb) {
      (async () => {
        const results = await DataStore.query(Encoders, (c) => c.activesb.eq(v.activesb));

        if (results.length > 0 && results[0].hasOwnProperty("activesb")) {
          setEncoder(results[0]);
        }
      })();
    }

    return () => {};

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [global.appUI.forceUpdate]);

  return (
    <Box className="eventlogs___sidebar">
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "stretch" }}>
        <Box className="eventlogs___sidebar_label" sx={{ flexGrow: 2 }}>
          STREAM DETAILS
        </Box>
        {isAdmin(global.appUser.account, global.appUI.hideAdminTools, false) && (
          <Settings
            sx={{ cursor: "pointer", color: grey[500] }}
            onClick={() => {
              ReactGa4.event({
                category: "ui",
                action: "ui_view",
                label: "adminPanel_" + (showAdmin ? "hide" : "show"),
              });
              setShowAdmin(!showAdmin);
            }}
          />
        )}
      </Box>
      <Box sx={{}}>
        <Typography variant="h1">{v.friendly}</Typography>
        <Typography variant="h2">{v.ssname}</Typography>
      </Box>
      <Box className="eventlogs___sidebar_columns">
        <Box>
          <MenuRowItem label={"Station"} value={v.sname} />
          <MenuRowItem label={"Affiliate"} value={v.affiliate && v.affiliate.toLowerCase() !== "none" ? v.affiliate : null} />
          <MenuRowItem label={"DMA"} value={global?.activeStation?.dmaName && global.activeStation.dmaName} />
          <MenuRowItem label={"TZ"} value={global?.activeStation?.timezone && global.activeStation.timezone} />
          <MenuRowItem label={"MVPD"} value={v.mypd} />
        </Box>
        <Box>
          <MenuRowItem label={"SyncBox"} value={v.activesb} />
          <MenuRowItem label={"SDI Input"} value={v.input} />
          <MenuRowItem label={"Config"} value={encoder && encoder.hasOwnProperty("workflow") ? getWorkflow(encoder.workflow) : ""} />
        </Box>
        <Box>
          <MenuRowItem label={"Stream ID"} value={v.ssid} />
          <MenuRowItem label={"Category"} value={getCategory(v.category)} />
          <MenuRowItem
            label={"Triggering"}
            value={v && v.hasOwnProperty("config") && v.config ? getTriggers(v?.config.toUpperCase()) : "Trigger Based"}
          />
          <MenuRowItem label={"Origin"} value={v && v.hasOwnProperty("source") ? getSources(v.source) : ""} />
        </Box>
      </Box>
      {isAdmin(global.appUser.account, global.appUI.hideAdminTools, false) && (
        <Box className="admin___button">
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => {
              ReactGa4.event({
                category: "ui",
                action: "ui_view",
                label: "streamComments_" + (global.appUI.showComments ? "hide" : "show"),
              });
              handleCommentsClose(true);
            }}
            startIcon={<CommentOutlined fontSize="1rem" />}
          >
            View Stream Notes
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default React.memo(StreamDetails);
