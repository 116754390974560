import React from "react";

/* Material UI Components */
import { Box, CircularProgress, Typography } from "@mui/material";

const Loading = ({ message }) => {
  return (
    <Box
      style={{
        display: "flex",
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        background: "#ccc",
        gap: "1rem",
      }}
    >
      <CircularProgress />
      <Typography variant="body2">{message ? message : "Loading..."}</Typography>
    </Box>
  );
};

export default Loading;
