import { DataStore } from "aws-amplify";
import { Activity } from "../models";

const setActivityLog = async ({ type, note, station, author }) => {
  let response = null;

  try {
    response = await DataStore.save(
      new Activity({
        author: author,
        eventType: type,
        station: station.toLowerCase(),
        note: station.toUpperCase() + ": " + note,
        createdAt: new Date(Date.now()).toISOString(),
      })
    );
  } catch (e) {
    console.log("   - Error updating Activity Log: ", e);
  }
  return response;
};

export default setActivityLog;
