export function getWorkflow(type) {
  switch (type) {
    case "B1C1":
      return "1 Box / 1 Channel";
    case "B1C2":
      return "1 Box / 2 Channel";
    case "B1C3":
      return "1 Box / 3 Channel";
    default:
      return "Unknown";
  }
}
