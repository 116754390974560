import { fetchWithRetry, handleApiResponse } from "./apis";

const API_URL = "https://chwtgazeif.execute-api.us-east-1.amazonaws.com/default/syncmonitor-api/scte";

export const getLastAdBreaks = async (abortController) => {
  try {
    const data = await fetchWithRetry(API_URL, { signal: abortController.signal });

    // Check if the fetch was aborted
    if (abortController.signal.aborted) {
      throw new Error("Fetch aborted");
    }

    // Return the parsed response or an error message if any
    const { apiResponse, errorMsg } = handleApiResponse(data["body-json"], "message");
    if (errorMsg) {
      return { apiResponse: null, errorMsg };
    }

    return { apiResponse: apiResponse, errorMsg: null };
  } catch (error) {
    if (error.name === "AbortError") {
      return {
        apiResponse: null,
        errorMsg: "Error fetching getLastAdBreaks() logs from Zeam API ... Request aborted",
      };
    }

    return {
      apiResponse: null,
      errorMsg: `Error fetching data: ${error.message}`,
    };
  }
};
