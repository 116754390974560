// React Components
import React, { memo, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Material UI Components
import { Box } from "@mui/material";

// Gray TV Components
import { getStationLiveList, updateLiveM3U8 } from "../../utilities/getStationLive";
import { getStationVodList, updateVODM3U8 } from "../../utilities/getStationVOD";
import { Application } from "../../context/Application";
import { Video } from "../../context/Video";

import DistroList from "./includes/VideoSidebar/DistroList";
import LiveList from "./includes/VideoSidebar/LiveList";
import VodList from "./includes/VideoSidebar/VodList";

// Gray Assets
import "../../_styles/SecondarySidebars.scss";

const VideoSidebar = () => {
  const global = useContext(Application);
  const globalVideo = useContext(Video);
  const param = useParams();

  // States
  const [vodList, setVodList] = useState(null);
  const [liveList, setLiveList] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();

    global.setAppDates((prev) => ({ ...prev, timezone: global.activeStation.timezone }));

    getStationLiveList(global.activeStation, abortController).then((response) => {
      if (response.errorMsg) {
        setLiveList(null);
        global.setAppUI((prev) => ({ ...prev, error: response.errorMsg }));
      } else {
        setLiveList(response.apiResponse);
        global.setAppUI((prev) => ({ ...prev, error: null }));
      }
    });

    return () => abortController.abort();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [global.activeStation]);

  useEffect(() => {
    const abortController = new AbortController();

    global.setAppDates((prev) => ({ ...prev, timezone: global.activeStation.timezone }));

    getStationVodList(global.activeStation, abortController).then((response) => {
      if (response.errorMsg) {
        setVodList(null);
        global.setAppUI((prev) => ({ ...prev, error: response.errorMsg }));
      } else {
        setVodList(response.apiResponse);
        global.setAppUI((prev) => ({ ...prev, error: null }));
      }
    });

    return () => abortController.abort();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [global.activeStation]);

  useEffect(() => {
    // Check if distro is set in URL
    if (typeof param.distro !== "undefined") {
      const streams = globalVideo.station.streams.filter((e) => {
        return e.distro.toLowerCase().replace(/\s+/g, "_") === param.distro.toLowerCase().replace(/\s+/g, "_");
      });
      streams[0].type = "distro";
      if (streams) globalVideo.setStream(streams[0]);
    } else if (typeof param.live !== "undefined") {
      const abortController = new AbortController();

      updateLiveM3U8(globalVideo.station, param.live, abortController).then((response) => {
        globalVideo.setStream({
          url: response.apiResponse.streamUrl,
          title: response.apiResponse.title,
          distro: response.apiResponse.id,
          type: "live",
        });
      });
    } else if (typeof param.vod !== "undefined") {
      updateVODM3U8(globalVideo.station, param.vod).then((response) => {
        globalVideo.setStream({
          url: response.apiResponse.streamUrl,
          title: response.apiResponse.title,
          distro: response.apiResponse.id,
          desc: response.apiResponse.description,
          duration: response.apiResponse.duration,
          adbreaks: response.apiResponse.adBreaks,
          type: "vod",
        });
      });
    } else {
      globalVideo.setStream(global.activeStation.streams[0]);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className="streams___container">
      <Box className="streams___container_inner">
        {globalVideo.station.streams && <DistroList data={globalVideo.station.streams} />}
        {liveList && globalVideo.stream.distro && <LiveList data={liveList} />}
        {vodList && globalVideo.stream.distro && <VodList data={vodList} />}
      </Box>
    </Box>
  );
};

export default memo(VideoSidebar);
