const CLIENT_ID = process.env.CLIENT_ID || "0oa9q7wz0q030BDgg697";
const ISSUER = process.env.ISSUER || "https://go.gray.tv";
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
const BASENAME = process.env.PUBLIC_URL || "";
const REDIRECT_URI = `${window.location.origin}${BASENAME}/authorization-code/callback`;
const SIGNOUT_URI = `${window.location.origin}${BASENAME}`;

export const oktaConfig = {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    postLogoutRedirectUri: SIGNOUT_URI,
    scopes: ["openid", "profile", "email"],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  },
};
