import React, { useContext } from "react";

// MUI Components
import { Typography } from "@mui/material";

// Gray TV Components
import { Application } from "../../../context/Application";
import { getTz } from "../../../utilities/utility";

const Footnotes = () => {
  const global = useContext(Application);
  return (
    <Typography variant="subtitle2" className="eventlogs___footnote" style={{ fontStyle: "italic", color: "#666", fontSize: "12px" }}>
      <ol style={{ margin: 0, padding: "0 1rem" }}>
        <li>
          {global.activeStation.station.toUpperCase()} report for stream {global.activeStream.ssname} ({global.activeStream.ssid}) has been set to the
          station's local timzeone of {getTz(global.appDates.timezone)}
        </li>
        <li>
          Some stream event codes may be different that expected resulting in mislabeled event types. Please review in context of event duration.
        </li>
        <li>Zeam limits date range searches for stream events to the last two weeks</li>
      </ol>
    </Typography>
  );
};

export default React.memo(Footnotes);
