import React, { memo, useContext, useState } from "react";
import moment from "moment-timezone";

/* Material UI Components */
import { Box, Typography } from "@mui/material";
import { Application } from "../../../context/Application";
import { getTz } from "../../../utilities/utility";

const VideoMonitorRowItem = ({ item }) => {
  const global = useContext(Application);
  const [row] = useState(JSON.parse(item));
  const formatTime = (val) => <Box>{moment.tz(val, getTz(global.appDates.timezone)).format("MMM D, YYYY @ h:mm:ss a z")}</Box>;

  if ("value" in row && "metadataType" in row.value && row.value.metadataType === "scte-35") {
    console.log("Ad Details: ", row.value);
  }

  return (
    <React.Fragment>
      {row && row.value && (
        <React.Fragment>
          {row.type === "captions" && (
            <Box className="logRow">
              <Box>{formatTime(row.time)}</Box>
              <Box>Captions</Box>
              <Box>Total tracks: {row.value.length - 1}</Box>
              <Box></Box>
            </Box>
          )}
          {row.type === "levels" && (
            <Box className="logRow">
              <Box>{formatTime(row.time)}</Box>
              <Box>Stream Levels</Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.25rem",
                  justifyContent: "flex-start",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                {row.value.map((val, key) => {
                  return (
                    val.label !== "Auto" && (
                      <Typography key={key} component="span">
                        {val.label}
                      </Typography>
                    )
                  );
                })}
              </Box>
            </Box>
          )}
          {row.type === "warning" && (
            <Box className="logRow warning">
              <Box>{formatTime(row.time)}</Box>
              <Box>Warning</Box>
              <Box>
                {row.value.type}: {row.value.details} ({"frag" in row.value && row.value.frag.relurl})
              </Box>
            </Box>
          )}
          {row.type === "mediaError" && (
            <Box className="logRow warning error">
              <Box>{formatTime(row.time)}</Box>
              <Box>Error</Box>
              <Box>
                {row.value.type}: {row.value.details}
                {"frag" in row.value && row.value.frag.relurl}
              </Box>
            </Box>
          )}
          {"metadataType" in row.value && row.value.metadataType === "id3" && (
            <Box className="logRow id3-event">
              <Box>{formatTime(row.time)}</Box>
              <Box>ID3 Event</Box>
              <Box className="range">{Math.round(row.value.metadataTime * 100) / 100}</Box>
              <Box>Nielsen Token</Box>
              {/* <Box>{row.value.metadata.PRIV}</Box> */}
            </Box>
          )}
          {"metadataType" in row.value && row.value.metadataType === "discontinuity" && (
            <Box className="logRow discontinuity">
              <Box>{formatTime(row.time)}</Box>
              <Box>Discontinuity</Box>
              <Box>
                {Math.round(row.value.metadata.start * 100) / 100} - {Math.round(row.value.metadata.end * 100) / 100}
              </Box>
              <Box>PTS: {row.value.metadata.PTS}</Box>
            </Box>
          )}
          {"metadataType" in row.value && row.value.metadataType === "media" && (
            <Box className="logRow media-load">
              <Box>{formatTime(row.time)}</Box>
              <Box>Media Load</Box>
              <Box className="range">
                Seek Range: {Math.round(row.value.seekRange.start * 100) / 100} - {Math.round(row.value.seekRange.end * 100) / 100}
              </Box>
              <Box>Type: {row.value.duration === Infinity ? "Live" : "VOD"}</Box>
            </Box>
          )}
          {"metadataType" in row.value && row.value.metadataType === "program-date-time" && (
            <Box className="logRow program-date-time">
              <Box>{formatTime(row.time)}</Box>
              <Box>Program Date Time</Box>
              <Box className="range">
                {Math.round(row.value.metadata.start * 100) / 100} - {Math.round(row.value.metadata.end * 100) / 100}
              </Box>
              <Box>{formatTime(row.value.metadata.programDateTime)}</Box>
            </Box>
          )}
          {"metadataType" in row.value && row.value.metadataType === "scte-35" && (
            <Box className="logRow scte">
              <Box>{formatTime(row.time)}</Box>
              <Box>{row.value.metadata.tag}</Box>
              <Box className="range">
                {Math.round(row.value.metadata.start * 100) / 100} - {Math.round(row.value.metadata.end * 100) / 100}
                {row.value.metadata.content && <React.Fragment>&nbsp; ({Math.round(row.value.metadata.content)} s)</React.Fragment>}
              </Box>
            </Box>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default memo(VideoMonitorRowItem);
