// React Components
import React, { memo, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactGa4 from "react-ga4";

// Material UI Components
import { Box } from "@mui/system";
import { Tab, Tabs, Typography } from "@mui/material";

// Gray TV Functions
import { Application } from "../context/Application";
import { Video } from "../context/Video";

// Gray Components
import { TableChart, Videocam } from "@mui/icons-material";
import EventMonitor from "../components/eventMonitor/EventMonitor";
import EventsSidebar from "../components/navigation/EventsSidebar";
import EventsTimeline from "../components/timeline/EventsTimeline";
import VideoPlayer from "../components/videoMonitor/VideoMonitor";
import VideoSidebar from "../components/navigation/VideoSidebar";
import AdAnalysis from "../components/adAnalysis/AdAnalysis";


const SelectedStation = () => {
  const global = useContext(Application);
  const globalVideo = useContext(Video);
  const goto = useNavigate();

  // Set States
  const [loading, setLoading] = useState(true);
  const [tabStatus, setTabStatus] = useState(getTabStatus());

  const [station, setStation] = useState(null);
  const [stream, setStream] = useState(null);

  function getTabStatus() {
    if (global.appUI.section === "video") {
      return 1;
    } else if (global.appUI.section === "elementals") {
      return 2;
    } else {
      return 0;
    }
  }

  // Set Analytics
  useEffect(() => {
    if (global.activeStation?.station) {
      document.title = global.activeStation.station.toUpperCase() + " Dashboard | SyncMon | Gray Digital";
      ReactGa4.send({ hitType: "pageview" });
    }
  }, [global.activeStation?.station]);

  useEffect(() => {
    if (global.activeStation?.station) {
      const results = global.stationList.filter((e) => {
        return e.station.toLowerCase() === global.activeStation.station.toLowerCase();
      });
      const activeStation = results[0];

      global.setActiveStation(activeStation);

      if (activeStation) {
        if (activeStation.streams && activeStation.streams.length > 0) {
          activeStation.streams[0].type = "distro";
          setStream(activeStation.streams[0]);
          if (global.appUI.section === "video") {
            setTabStatus(1);
          } else {
            setTabStatus(0);
          }
        } else {
          setStream(null);
          setTabStatus(0);
        }
        setStation(activeStation);
      } else {
        setStream(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [global.activeStation?.station, global.appUI.section]);

  const handleTabChange = (event, newValue) => {
    setTabStatus(newValue);

    if (newValue === 1) {
      global.setAppUI((prev) => ({ ...prev, helpTopic: "monitor" }));

      goto(
        "/video/" + global.activeStation.station + (globalVideo && globalVideo.stream && globalVideo.stream.id ? "/" + globalVideo.stream.id : "")
      );
    } else {
      global.setAppUI((prev) => ({ ...prev, helpTopic: "dashboard" }));

      goto("/view/" + global.activeStation.station);
    }
  };

  // Enhanced Readability of Conditional Rendering
  const renderMainContent = () => {
    // if (!stream) return null;

    if (stream && tabStatus === 1) {
      return (
        <Video.Provider
          value={{
            stream,
            setStream,
            station,
            setStation,
          }}
        >
          <VideoPlayer />
        </Video.Provider>
      );
    } else {
      if (global.activeStream && global.activeStream !== null) {
        switch (global.appUI.section) {
          case "eventsTimeline":
            return <EventsTimeline loading={loading} setLoading={setLoading} />;
          case "ad_analysis":
            return <AdAnalysis />;
          default:
            return <EventMonitor loading={loading} setLoading={setLoading} />;
        }
      } else {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flexStart",
              alignContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "100%",
              gap: "0.5rem",
              width: "calc(100% - 0.5rem)",
              overflowX: "hidden",
              overflowY: "auto",
              padding: "1rem 0.5rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flexStart",
                alignContent: "center",
                alignItems: "center",
                flexDirection: "row",
                gap: "0.5rem",
                textAlign: "left",
                width: "100%",
                paddingBottom: "0.25rem",
              }}
            >
              <TableChart sx={{ color: "#1976d2" }} />
              <Typography variant="h3" component="h3" sx={{ color: "#111", fontSize: "1.4rem", fontWeight: "normal" }}>
                {global.activeStation?.station.toUpperCase()} Event Monitor
              </Typography>
            </Box>
            <Box
              sx={{
                textAlign: "left",
                width: "100%",
                padding: "1rem",
                background: "#ffffff",
                borderRadius: "0.5rem",
                boxShadow: "rgba(6, 26, 54, 0.16) 0 0.25rem 1rem",
              }}
            >
              <Typography variant="h6" component="h6" sx={{ fontSize: "1rem", paddingBottom: "1rem" }}>
                Please select one of <b>{global.activeStation?.station.toUpperCase()}'s</b> streams (listed to the left) to view it within{" "}
                <b>Event Monitor</b> where you can monitor and assess streaming events; visually view events plotted against a timeline; or analyze ad
                breaks to help identify issues (MiddleMan and Full SCTE stations only).
              </Typography>
              <Typography variant="h6" component="h6" sx={{ fontSize: "1rem" }}>
                You can also monitor owned and FAST streams in real-time, or review past video-on-demand (VOD) programming by selecting the{" "}
                <b>Video Monitor</b> tab.
              </Typography>
            </Box>
          </Box>
        );
      }
    }
  };

  return (
    <React.Fragment>
      {global.activeStation && (
        <Box className={"sidebar " + (global.appUI.showMobile ? "open" : "closed")}>
          <Box className="sidebar___tabs">
            <Tabs className="sidebar___tabs_top" value={tabStatus} onChange={handleTabChange} aria-label="basic tabs example">
              <Tab icon={<TableChart />} iconPosition="start" label="Event Monitor"></Tab>
              {stream && <Tab icon={<Videocam />} iconPosition="start" label="Video Monitor"></Tab>}
              {/* <Tab label="Station Info"></Tab> */}
              {/* <Tab label="Elementals"></Tab> */}
            </Tabs>
          </Box>
          <Box className="sidebar___wrapper">
            <Box className="sidebar___streams" component="main" sx={{ flexGrow: 1 }}>
              {stream && tabStatus === 1 ? (
                <Video.Provider
                  value={{
                    stream,
                    setStream,
                    station,
                    setStation,
                  }}
                >
                  <VideoSidebar />
                </Video.Provider>
              ) : (
                <EventsSidebar loading={loading} setLoading={setLoading} />
              )}
            </Box>
            <Box className="sidebar___main">{renderMainContent()}</Box>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};

export default memo(SelectedStation);
