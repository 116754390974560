import React from "react";

// Material UI Components
import { AttachMoneyOutlined, ErrorOutline, PlayCircleOutline, StopCircleOutlined } from "@mui/icons-material";
import { Avatar, Box, Chip, Tooltip, Typography } from "@mui/material";
import { blue, green, orange, yellow } from "@mui/material/colors";

export const EventName = ({ type, isBegin }) => {
  const getName = () => {
    switch (type) {
      case 0:
        return isBegin === true ? "Ad Break Start (Cue-Out)" : "Ad Break End (Cue-In)";
      case 1:
        return "Blackout / LNL";
      case 2:
        return "Streaming";
      default:
        return "Unknown";
    }
  };

  return <React.Fragment>{getName()}</React.Fragment>;
};

const EventType = ({ type, format = false, filter, onFilter, isBegin, margin }) => {
  const getType = () => {
    switch (type) {
      case 0:
        return (
          <Tooltip title={"Stream registered " + (isBegin === true ? "Cue-Out" : "Cue-In") + " 'Ad Break' event"} arrow placement="right">
            <Box
              style={{
                display: "flex",
                gap: "0.25rem",
                alignItems: "center",
              }}
            >
              <Avatar
                sx={{
                  backgroundColor: isBegin === true ? green[500] : green[300],
                  width: isBegin === true ? "1.5rem" : "1.35rem",
                  height: isBegin === true ? "1.5rem" : "1.35rem",
                  margin: margin ? margin : "0 auto",
                }}
              >
                <AttachMoneyOutlined
                  sx={{
                    height: isBegin === true ? "1rem" : "0.875rem",
                    width: isBegin === true ? "1rem" : "0.875rem",
                  }}
                />
              </Avatar>
              {isBegin === true ? (
                <Box component="span" sx={{ fontSize: "1.25rem", color: green[500] }}>
                  ⏵
                </Box>
              ) : (
                <Box component="span" sx={{ fontSize: "1rem", color: green[300] }}>
                  ⏴
                </Box>
              )}
              {format && <Typography variant="subtitle2"> Ad Break</Typography>}
            </Box>
          </Tooltip>
        );
      case 1:
        return (
          <Tooltip title="Stream registered 'Blackout' event. This could imply a switch to 'Local News Live'" arrow placement="right">
            <Box
              style={{
                display: "flex",
                gap: "0.25rem",
                alignItems: "center",
              }}
            >
              <Avatar
                sx={{
                  backgroundColor: orange[500],
                  width: "1.5rem",
                  height: "1.5rem",
                  margin: margin ? margin : "0 auto",
                  // margin: "0 auto",
                }}
              >
                <StopCircleOutlined />
              </Avatar>
              {format && <Typography variant="subtitle2"> Blackout / LNL</Typography>}
            </Box>
          </Tooltip>
        );
      case 2:
        return (
          <Tooltip title="Stream entered a 'Streaming' event" arrow placement="right">
            <Box
              style={{
                display: "flex",
                gap: "0.25rem",
                alignItems: "center",
              }}
            >
              <Avatar
                sx={{
                  backgroundColor: blue[500],
                  width: "1.5rem",
                  height: "1.5rem",
                  margin: margin ? margin : "0 auto",
                }}
              >
                <PlayCircleOutline />
              </Avatar>
              {format && (
                <Typography variant="subtitle2" sx={{ fontSize: "0.75rem" }}>
                  Live Streaming
                </Typography>
              )}
            </Box>
          </Tooltip>
        );

      default:
        return "Unknown";
    }
  };

  const getChip = () => {
    switch (type) {
      case 0:
        return (
          <Chip
            onClick={(e) => (filter === 0 ? onFilter(null) : onFilter(0))}
            label="Ad Break"
            variant={filter === 0 ? "filled" : "outlined"}
            icon={<AttachMoneyOutlined style={{ color: filter === 0 ? "#fff" : "inherit" }} />}
            style={{
              backgroundColor: filter === 0 ? green[500] : "white",
              color: filter === 0 ? "#fff" : "inherit",
            }}
          />
        );
      case 1:
        return (
          <Chip
            onClick={(e) => (filter === 1 ? onFilter(null) : onFilter(1))}
            label="Blackout / LNL"
            variant={filter === 1 ? "filled" : "outlined"}
            icon={<StopCircleOutlined style={{ color: filter === 1 ? "#fff" : "inherit" }} />}
            style={{
              backgroundColor: filter === 1 ? orange[700] : "white",
              color: filter === 1 ? "#fff" : "inherit",
            }}
          />
        );
      case 2:
        return (
          <Chip
            onClick={(e) => (filter === 2 ? onFilter(null) : onFilter(2))}
            label="Streaming"
            variant={filter === 2 ? "filled" : "outlined"}
            icon={<PlayCircleOutline style={{ color: filter === 2 ? "#fff" : "inherit" }} />}
            style={{
              backgroundColor: filter === 2 ? blue[500] : "white",
              color: filter === 2 ? "#fff" : "inherit",
            }}
          />
        );
      case "warning":
        return (
          <Chip
            onClick={() => (filter === "warning" ? onFilter(null) : onFilter("warning"))}
            label="Warning"
            variant={filter === "warning" ? "filled" : "outlined"}
            icon={<ErrorOutline style={{ color: filter === "warning" ? "#fff" : "inherit" }} />}
            style={{
              backgroundColor: filter === "warning" ? yellow[800] : "white",
              color: filter === "warning" ? "#fff" : "inherit",
            }}
          />
        );
      default:
        return <React.Fragment></React.Fragment>;
    }
  };

  return <React.Fragment>{format ? getChip() : getType()}</React.Fragment>;
};

export default EventType;
