export const getFilterTypeName = ({ type }) => {
  switch (type) {
    case 0:
      return "Ad Break";
    case 1:
      return "Blackout / LNL";
    case 2:
      return "Streaming";
    case "warning":
      return "Warning";
    default:
      return "Unknown";
  }
};
