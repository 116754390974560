import { StreamsList } from "../data/streamList";
import { fetchWithRetry, handleApiResponse } from "./apis";
import sortStations from "./utility";

const API_URL = "https://chwtgazeif.execute-api.us-east-1.amazonaws.com/default/syncmonitor-api/stations";

export const getStations = async (abortController) => {
  try {
    const data = await fetchWithRetry(API_URL, { signal: abortController.signal });

    // Check if the fetch was aborted
    if (abortController.signal.aborted) {
      throw new Error("Fetch aborted");
    }

    // Return the parsed response or an error message if any
    const { apiResponse, errorMsg } = handleApiResponse(data, "message");
    if (errorMsg) {
      return { error: errorMsg };
    }

    // Splice Gray Meta into station list
    const sortedStations = sortStations(apiResponse);

    sortedStations.forEach((item, key) => {
      const matchedStream = StreamsList.find((e) => e.station.toLowerCase() === item.station.toLowerCase());

      if (matchedStream) {
        sortedStations[key].streams = matchedStream.streams || [];
      }
    });

    return sortedStations;
  } catch (error) {
    console.log("   - Error fetching data:", error);
    return { error };
  }
};
