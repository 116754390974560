import React, { useContext } from "react";

// Moment.js Components
import moment from "moment-timezone";

// MUI Components
import { Box, Button } from "@mui/material";

// Gray TV Components
import { Application } from "../../../context/Application";
import { getTz } from "../../../utilities/utility";
import { Share } from "@mui/icons-material";

const ShareReport = () => {
  const global = useContext(Application);

  return (
    <Box className="eventlogs___share">
      <Button
        onClick={(e) => {
          window.location.href =
            "mailto:" +
            "?subject=Gray SyncMonitor Shared Report" +
            "&body=Hello!%0D%0A%0D%0A" +
            (global.appUser.name ? global.appUser.name : global.appUser.email) +
            " wants to share the Gray SyncMonitor report for " +
            global.activeStation.station.toUpperCase() +
            " between " +
            moment(global.appDates.start, getTz(global.appDates.timezone)).format("dddd, MMMM Do YYYY, h:mm:ss a") +
            " and " +
            moment(global.appDates.end, getTz(global.appDates.timezone)).format("dddd, MMMM Do YYYY, h:mm:ss a") +
            " with you.%0D%0A%0D%0A" +
            global.activeStream.ssname +
            " stream report:%0D%0A" +
            "https://syncmonitor.gdm-connect.com/view/" +
            global.activeStation.station.toLowerCase() +
            "/" +
            global.activeStream.ssid +
            "/" +
            global.appDates.start +
            "/" +
            global.appDates.end +
            "%0D%0A%0D%0A" +
            "Thanks!%0D%0A" +
            "Gray SyncMonitor";
          e.preventDefault();
        }}
        size="small"
        variant="contained"
        className="eventlogs___share_button"
        startIcon={<Share />}
      >
        Share
      </Button>
    </Box>
  );
};

export default React.memo(ShareReport);
