import React, { useContext } from "react";
import ReactGa4 from "react-ga4";

import { blue } from "@mui/material/colors";
import { ChevronRight, Support } from "@mui/icons-material";
import { Box, Divider, Drawer, IconButton, List, ListItem, Typography } from "@mui/material";

// Gray TV Components
import { Application } from "../../context/Application";
import { topics } from "../../data/knowledgeBase";

const drawerWidth = 420;

const KnowledgeBase = () => {
  const global = useContext(Application);
  // const goto = useNavigate();

  const handleClose = (state) => {
    global.setAppUI({ ...global.appUI, showHelp: state });
    ReactGa4.event({
      category: "ui",
      action: "ui_view",
      label: "knowledgeBase_hide",
    });
  };

  return (
    <Drawer
      variant="temporary"
      open={global.appUI.showHelp}
      anchor="right"
      onClose={() => handleClose(false)}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
          backgroundColor: "#ffffff",
        },
      }}
    >
      <Box
        style={{
          position: "fixed",
          bgColor: "#fff",
          background: "#fff",
          display: "flex",
          alignItems: "center",
          width: "inherit",
          height: "3rem",
          padding: "0 1rem",
          justifyContent: "flex-start",
          gap: "0.5rem",
          zIndex: 1000,
          borderBottom: "1px solid #ccc",
        }}
      >
        <Support />
        <Typography component={"h3"} style={{ flex: 2 }}>
          SyncMon Knowledge Center
        </Typography>
        <IconButton onClick={(e) => handleClose(false)}>
          <ChevronRight />
        </IconButton>
      </Box>
      <Box sx={{ marginTop: "4rem" }}>
        <List>
          {topics[global.appUI.helpTopic].map((val, key) => {
            return (
              <ListItem key={key} sx={{ borderBottom: "1px solid #e4e4e4", marginBottom: "2rem" }}>
                <Box
                  sx={{
                    maxWidth: 400,
                    padding: "0 0 1rem",
                  }}
                >
                  <Typography
                    component="h2"
                    sx={{
                      fontWeight: 400,
                      fontSize: "1.4rem",
                      textTransform: "uppercase",
                      color: blue[700],
                    }}
                  >
                    {val.title}
                  </Typography>

                  {val.topics.map((subval, subkey) => {
                    return (
                      <Box key={subkey}>
                        <Typography
                          component="h3"
                          sx={{
                            fontSize: "0.925rem",
                            padding: "1.25rem 0 0.5rem 1rem",
                            // fontWeight: 600,
                            textTransform: "uppercase",
                            color: blue[600],
                          }}
                        >
                          {subval.title}
                        </Typography>
                        {subval.message.map((paragraph) => {
                          return (
                            <Typography
                              key={paragraph}
                              variant="subtitle1"
                              // component="li"
                              sx={{
                                fontSize: "0.875rem",
                                mb: 1.25,
                                paddingLeft: "1rem",
                                lineHeight: "1.2rem",
                              }}
                              dangerouslySetInnerHTML={{ __html: paragraph }}
                            />
                          );
                        })}
                      </Box>
                    );
                  })}
                </Box>
                <Divider />
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Drawer>
  );
};

export default KnowledgeBase;
