// React Components
import React, { useContext, useEffect, useState } from "react";
import moment from "moment-timezone";
import ReactGa4 from "react-ga4";

// MUI Components
import { blue, grey, red } from "@mui/material/colors";
import { CheckCircle, ChevronRight, CommentOutlined, Delete, RadioButtonUncheckedOutlined, RestoreFromTrash } from "@mui/icons-material";
import { Alert as MuiAlert, Box, Button, Divider, Drawer, IconButton, List, ListItem, Tooltip, Typography, Snackbar, Switch } from "@mui/material";

// DataStore Components
import { DataStore, SortDirection } from "aws-amplify";
import { Activity, EventNotes } from "../../models";

// Gray TV Components
import { Application } from "../../context/Application";
import { isAdmin, isSuperAdmin } from "../../utilities/isAdmin";
import { getTz } from "../../utilities/utility";

const drawerWidth = 420;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CommentDrawer = ({ station, ssid }) => {
  const global = useContext(Application);

  const [note, setNote] = useState("");
  const [refresh, setRefresh] = useState(null);
  const [comments, setComments] = useState(null);
  const [showHidden, setShowHidden] = useState(false);
  const [showDeleted, setShowDeleted] = useState(false);

  // On-Screen Alerting
  const [alert, setAlert] = useState({ show: false });

  useEffect(() => {
    if (ssid && ssid !== "") {
      (async () => {
        const comments = await DataStore.query(EventNotes, (c) => c.and((c) => [c.station.eq(station.toLowerCase()), c.ssid.eq(+ssid)]), {
          sort: (s) => s.status(SortDirection.ASCENDING).createdAt(SortDirection.DESCENDING),
        });

        if (comments.length > 0 && comments[0].hasOwnProperty("note")) {
          setComments(comments);
        }
      })();
    }

    return () => {
      setComments(null);
      // cleanup();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ssid, refresh]);

  const updateCommentTotals = () => {
    (async () => {
      const comments = await DataStore.query(EventNotes, (c) => c.and((c) => [c.station.eq(station.toLowerCase()), c.ssid.eq(+ssid)]), {
        sort: (s) => s.status(SortDirection.ASCENDING).createdAt(SortDirection.DESCENDING),
      });

      const counts = comments.reduce((acc, note) => {
        if (note.status === "OPEN" && !note.deletedAt) {
          acc[note.station] = (acc[note.station] || 0) + 1;
        }
        return acc;
      }, {});

      const boxCounts = comments.reduce((acc, note) => {
        if (note.status === "OPEN" && !note.deletedAt) {
          acc[note.ssid] = (acc[note.ssid] || 0) + 1;
        }
        return acc;
      }, {});

      const stationIndex = global.stationList.findIndex((item) => item.station === station.toLowerCase());

      if (stationIndex !== -1) {
        const newData = [...global.stationList];
        newData[stationIndex].notes = counts[station.toLowerCase()] ? counts[station.toLowerCase()] : 0;
        Object.keys(newData[stationIndex].boxes).forEach((boxId) => {
          if (boxId === ssid) newData[stationIndex].boxes[boxId].notes = boxCounts[ssid] ? boxCounts[ssid] : 0;
        });

        // Update the state
        global.setStationList(newData);
        global.setActiveStation(newData[stationIndex]);
        global.setAppUI((prev) => ({ ...prev, forceUpdate: new Date().getTime() }));
      }
    })();
  };

  function scrubDomain(email) {
    return email.substring(0, email.indexOf("@"));
  }

  const updateActivityLog = async ({ type, note }) => {
    let response = null;
    try {
      response = await DataStore.save(
        new Activity({
          station: global.activeStation.station.toLowerCase(),
          eventType: type,
          note: global.activeStation.station.toUpperCase() + ": " + note,
          author: global.appUser.email,
          createdAt: new Date(Date.now()).toISOString(),
        })
      );
    } catch (e) {
      console.log("   - Error updating Activity Log: ", e);
    }
    return response;
  };

  const switchDeleteState = async ({ id, status }) => {
    let deleted = true;
    const original = await DataStore.query(EventNotes, id);

    try {
      if (original) {
        if (status === false) {
          await DataStore.save(
            EventNotes.copyOf(original, (updated) => {
              updated.deletedAt = new Date(Date.now()).toISOString();
            })
          );
        } else {
          deleted = false;
          await DataStore.save(
            EventNotes.copyOf(original, (updated) => {
              updated.deletedAt = null;
            })
          );
        }
        setRefresh(Date.now());
        setAlert({
          show: true,
          status: "success",
          message: `${station && station.toUpperCase()} note was ${status ? "RESTORED" : "DELETED"}`,
        });
        updateCommentTotals();
      }
    } catch (error) {
      setAlert({
        show: true,
        status: "error",
        message: `Update was unsuccessful. Please try again`,
      });
      console.log("ERROR: Failure updating comment delete state. ", error);
    }

    /* Log to Activity table */
    await updateActivityLog({
      type: "STREAM NOTE STATE",
      note: `Stream Note was ${deleted ? "DELETED" : "RESTORED"} for # ${original.id}`,
    });
  };

  const switchStatus = async ({ id, status }) => {
    const original = await DataStore.query(EventNotes, id);

    try {
      if (original) {
        await DataStore.save(
          EventNotes.copyOf(original, (updated) => {
            updated.status = status;
          })
        );
        setRefresh(Date.now());
        setAlert({
          show: true,
          status: "success",
          message: `${station && station.toUpperCase()} note was marked ${status.toLowerCase()}`,
        });
        updateCommentTotals();
      }
    } catch (error) {
      setAlert({
        show: true,
        status: "error",
        message: `Update was unsuccessful. Please try again`,
      });
      console.log("ERROR: Failure updating comment status. ", error);
    }

    /* Log to Activity table */
    await updateActivityLog({
      type: "STREAM NOTE STATUS",
      note: `Stream Note marked ${status} for # ${original.id}`,
    });
  };

  const createNote = async () => {
    try {
      const results = await DataStore.save(
        new EventNotes({
          station: station.toLowerCase(),
          ssid: +ssid,
          note: note,
          author: global.appUser.email,
          status: "OPEN",
          createdAt: new Date(Date.now()).toISOString(),
        })
      );
      if (results && results.hasOwnProperty("id")) {
        setNote("");
        setAlert({
          show: true,
          status: "success",
          message: `${station && station.toUpperCase()} note was successfully created }`,
        });

        /* Log to Activity table */
        await updateActivityLog({
          type: "STREAM NOTE CREATED",
          note: `Stream Note has created I# ${results.id})`,
        });
      } else {
        setAlert({
          show: true,
          status: "error",
          message: `Create was unsuccessful. Please try again`,
        });
      }
      updateCommentTotals();
      setRefresh(Date.now());
    } catch (error) {
      console.log("ERROR: Failure creating/updating comment. ", error);
    }
  };

  const handleClose = (state) => {
    global.setAppUI((prev) => ({ ...prev, showComments: state }));
    ReactGa4.event({
      category: "ui",
      action: "ui_view",
      label: "streamComments_hide",
    });
  };

  return (
    <Drawer
      // className="station___navigation"
      variant="temporary"
      open={global.appUI.showComments}
      anchor="right"
      onClose={(e) => handleClose(false)}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
          backgroundColor: "#ffffff",
        },
      }}
    >
      <Box
        style={{
          position: "fixed",
          bgColor: "#fff",
          background: "#fff",
          display: "flex",
          alignItems: "center",
          width: "inherit",
          height: "3rem",
          padding: "0 1rem",
          justifyContent: "flex-start",
          gap: "0.5rem",
          zIndex: 1000,
          borderBottom: "1px solid #ccc",
        }}
      >
        <CommentOutlined sx={{ fontSize: "0.875rem" }} />
        <Typography component={"h3"} style={{ fontSize: "0.875rem", flex: 2 }}>
          Notes for Stream ID #{ssid}
        </Typography>
        <IconButton onClick={(e) => handleClose(false)}>
          <ChevronRight />
        </IconButton>
      </Box>
      <Box
        sx={{
          marginTop: "3rem",
          display: "flex",
          flexDirection: "row",
          justfiyContent: "flex-start",
          background: grey[700],
          color: "white",
          padding: "0.25rem 1rem",
          gap: "1rem",
        }}
      >
        {isAdmin(global.appUser.account, global.appUI.hideAdminTools, false) && (
          <Typography variant="caption">
            Show Completed <Switch size="small" onChange={(e) => setShowHidden(!showHidden)} />
          </Typography>
        )}
        {isSuperAdmin(global.appUser.account, global.appUI.hideAdminTools, false) && (
          <Typography variant="caption">
            Show Deleted <Switch size="small" onChange={(e) => setShowDeleted(!showDeleted)} />
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          flexGrow: 2,
          overflow: "scroll",
          background: grey[50],
        }}
      >
        {comments && comments.length > 0 && (
          <List sx={{ paddingTop: 0, paddingBottom: 0 }}>
            {comments.map((val, key) => {
              const show =
                (val.status === "OPEN" && !val.deletedAt) ||
                (val.status === "SOLVED" && !val.deletedAt && showHidden === true) ||
                (val.deletedAt && showDeleted === true)
                  ? true
                  : false;
              if (show) {
                return (
                  <ListItem
                    key={key}
                    sx={{
                      borderBottom: "1px solid #e4e4e4",
                      paddingTop: "1rem",
                      background: val.deletedAt ? grey[200] /*red[400]*/ : val.status === "OPEN" ? "white" : grey[200],
                      color: val.deletedAt ? red[200] : val.status === "OPEN" ? "inherit" : grey[500],
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: 400,
                        padding: "0 0 1rem",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "flex-start",
                        }}
                      >
                        <Box sx={{ paddingBottom: "0.5rem", flexGrow: 2 }}>
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: "0.75rem",
                              fontWeight: "bold",
                              margin: 0,
                              padding: 0,
                            }}
                          >
                            {scrubDomain(val.author)}
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{
                              fontSize: "0.65rem",
                            }}
                          >
                            {moment.tz(val?.createdAt, getTz(global.appDates.timezone)).format("MMM D, YYYY @ h:mm:ss a z")}
                          </Typography>
                        </Box>
                        <Tooltip title={val.status === "OPEN" ? "Mark Closed" : "Re-Open"} arrow placement="bottom-end">
                          <IconButton
                            size="small"
                            onClick={(e) =>
                              switchStatus({
                                status: val.status === "OPEN" ? "SOLVED" : "OPEN",
                                id: val.id,
                              })
                            }
                          >
                            {val.status === "OPEN" ? (
                              <RadioButtonUncheckedOutlined
                                sx={{
                                  color: grey[300],
                                  "&:hover": { color: blue[500] },
                                }}
                              />
                            ) : (
                              <CheckCircle
                                sx={{
                                  color: grey[400],
                                  "&:hover": {
                                    color: blue[500],
                                  },
                                }}
                              />
                            )}
                          </IconButton>
                        </Tooltip>
                        {isSuperAdmin(global.appUser.account, global.appUI.hideAdminTools, false) && (
                          <Tooltip title={val.deletedAt ? "Restore" : "Delete"} arrow placement="bottom-end">
                            <IconButton
                              size="small"
                              onClick={(e) =>
                                switchDeleteState({
                                  status: val.deletedAt ? true : false,
                                  id: val.id,
                                })
                              }
                            >
                              {val.deletedAt ? (
                                <RestoreFromTrash
                                  sx={{
                                    color: grey[400],
                                    "&:hover": {
                                      color: blue[500],
                                    },
                                  }}
                                />
                              ) : (
                                <Delete
                                  sx={{
                                    color: grey[300],
                                    "&:hover": { color: blue[500] },
                                  }}
                                />
                              )}
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: "0.875rem" }}
                        dangerouslySetInnerHTML={{
                          __html: val.note.replace(/(?:\r\n|\r|\n)/g, "<br>"),
                        }}
                      ></Typography>
                    </Box>
                    <Divider />
                  </ListItem>
                );
              } else {
                return <React.Fragment key={"empty_" + key}></React.Fragment>;
              }
            })}
          </List>
        )}
      </Box>
      {isAdmin(global.appUser.account, global.appUI.hideAdminTools, false) && (
        <Box
          id="gaffney"
          sx={{
            padding: "1rem",
            borderTop: "1px solid rgba(0,0,0,0.1)",
            background: grey[300],
          }}
        >
          <Typography
            component={"h3"}
            style={{
              flex: 2,
              fontSize: "0.875rem",
              paddingBottom: "0.25rem",
            }}
          >
            Add a note to Stream ID #{ssid}:
          </Typography>
          <textarea
            onChange={(e) => setNote((previous) => e.target.value)}
            style={{
              width: "100%",
              borderRadius: "0.25rem",
              border: "1px solid rgba(0,0,0,0.25)",
              minHeight: "6rem",
              padding: "0.5rem",
              fontSize: "0.875rem",
            }}
            value={note}
          />
          <Button
            variant="contained"
            onClick={(e) => createNote()}
            disabled={note.trim().length <= 5 ? true : false}
            sx={{
              width: "100%",
              marginTop: "1rem",
              marginLeft: "auto",
              // background: "dodgerblue",
              "&:hover": { background: "dodgerblue", color: "white" },
            }}
          >
            Submit
          </Button>
        </Box>
      )}
      <Snackbar open={alert ? alert.show : false} autoHideDuration={3000} onClose={(e) => setAlert({ show: false })}>
        <Alert onClose={(e) => setAlert({ show: false })} severity={alert && alert.status ? alert.status : "info"} sx={{ width: "100%" }}>
          {alert && alert.message ? alert.message : ""}
        </Alert>
      </Snackbar>
    </Drawer>
  );
};

export default CommentDrawer;
