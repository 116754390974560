/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getApplication = /* GraphQL */ `
  query GetApplication($id: ID!) {
    getApplication(id: $id) {
      id
      appVersion
      releaseNotes
      releaseDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listApplications = /* GraphQL */ `
  query ListApplications(
    $id: ID
    $filter: ModelApplicationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listApplications(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        appVersion
        releaseNotes
        releaseDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncApplications = /* GraphQL */ `
  query SyncApplications(
    $filter: ModelApplicationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncApplications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        appVersion
        releaseNotes
        releaseDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const applicationsByAppVersion = /* GraphQL */ `
  query ApplicationsByAppVersion(
    $appVersion: String!
    $sortDirection: ModelSortDirection
    $filter: ModelApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    applicationsByAppVersion(
      appVersion: $appVersion
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        appVersion
        releaseNotes
        releaseDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getScteAverages = /* GraphQL */ `
  query GetScteAverages($id: ID!) {
    getScteAverages(id: $id) {
      id
      station
      ssid
      dotw
      hr_avgs
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listScteAverages = /* GraphQL */ `
  query ListScteAverages(
    $id: ID
    $filter: ModelScteAveragesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listScteAverages(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        station
        ssid
        dotw
        hr_avgs
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncScteAverages = /* GraphQL */ `
  query SyncScteAverages(
    $filter: ModelScteAveragesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncScteAverages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        station
        ssid
        dotw
        hr_avgs
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const scteAveragesByStationAndSsidAndDotw = /* GraphQL */ `
  query ScteAveragesByStationAndSsidAndDotw(
    $station: String!
    $ssidDotw: ModelScteAveragesByStationSsidDotwCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelScteAveragesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    scteAveragesByStationAndSsidAndDotw(
      station: $station
      ssidDotw: $ssidDotw
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        station
        ssid
        dotw
        hr_avgs
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getScteAlerts = /* GraphQL */ `
  query GetScteAlerts($id: ID!) {
    getScteAlerts(id: $id) {
      id
      station
      ssid
      status
      message
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listScteAlerts = /* GraphQL */ `
  query ListScteAlerts(
    $filter: ModelScteAlertsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScteAlerts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        station
        ssid
        status
        message
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncScteAlerts = /* GraphQL */ `
  query SyncScteAlerts(
    $filter: ModelScteAlertsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncScteAlerts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        station
        ssid
        status
        message
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUsers = /* GraphQL */ `
  query GetUsers($id: ID!) {
    getUsers(id: $id) {
      id
      email
      name
      favorites
      showGPI
      showHidden
      showTelemundos
      appVersion
      lastLogin
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $id: ID
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        email
        name
        favorites
        showGPI
        showHidden
        showTelemundos
        appVersion
        lastLogin
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        email
        name
        favorites
        showGPI
        showHidden
        showTelemundos
        appVersion
        lastLogin
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const usersByEmail = /* GraphQL */ `
  query UsersByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        name
        favorites
        showGPI
        showHidden
        showTelemundos
        appVersion
        lastLogin
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getStations = /* GraphQL */ `
  query GetStations($id: ID!) {
    getStations(id: $id) {
      id
      station
      ssa
      hidden
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listStations = /* GraphQL */ `
  query ListStations(
    $filter: ModelStationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        station
        ssa
        hidden
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncStations = /* GraphQL */ `
  query SyncStations(
    $filter: ModelStationsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncStations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        station
        ssa
        hidden
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getElementalNotes = /* GraphQL */ `
  query GetElementalNotes($id: ID!) {
    getElementalNotes(id: $id) {
      id
      station
      note
      author
      status
      createdAt
      deletedAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listElementalNotes = /* GraphQL */ `
  query ListElementalNotes(
    $filter: ModelElementalNotesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listElementalNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        station
        note
        author
        status
        createdAt
        deletedAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncElementalNotes = /* GraphQL */ `
  query SyncElementalNotes(
    $filter: ModelElementalNotesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncElementalNotes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        station
        note
        author
        status
        createdAt
        deletedAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getElementals = /* GraphQL */ `
  query GetElementals($id: ID!) {
    getElementals(id: $id) {
      id
      station
      contact
      email
      address
      shippedAt
      model
      serialnumber
      version
      bmcCredentials
      internalIP
      rtmp
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listElementals = /* GraphQL */ `
  query ListElementals(
    $filter: ModelElementalsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listElementals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        station
        contact
        email
        address
        shippedAt
        model
        serialnumber
        version
        bmcCredentials
        internalIP
        rtmp
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncElementals = /* GraphQL */ `
  query SyncElementals(
    $filter: ModelElementalsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncElementals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        station
        contact
        email
        address
        shippedAt
        model
        serialnumber
        version
        bmcCredentials
        internalIP
        rtmp
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getActivity = /* GraphQL */ `
  query GetActivity($id: ID!) {
    getActivity(id: $id) {
      id
      station
      eventType
      note
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listActivities = /* GraphQL */ `
  query ListActivities(
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        station
        eventType
        note
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncActivities = /* GraphQL */ `
  query SyncActivities(
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncActivities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        station
        eventType
        note
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getEncoders = /* GraphQL */ `
  query GetEncoders($id: ID!) {
    getEncoders(id: $id) {
      id
      station
      activesb
      workflow
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listEncoders = /* GraphQL */ `
  query ListEncoders(
    $filter: ModelEncodersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEncoders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        station
        activesb
        workflow
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncEncoders = /* GraphQL */ `
  query SyncEncoders(
    $filter: ModelEncodersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEncoders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        station
        activesb
        workflow
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getStreamNotes = /* GraphQL */ `
  query GetStreamNotes($id: ID!) {
    getStreamNotes(id: $id) {
      id
      station
      distro
      note
      author
      status
      createdAt
      deletedAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listStreamNotes = /* GraphQL */ `
  query ListStreamNotes(
    $filter: ModelStreamNotesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStreamNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        station
        distro
        note
        author
        status
        createdAt
        deletedAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncStreamNotes = /* GraphQL */ `
  query SyncStreamNotes(
    $filter: ModelStreamNotesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncStreamNotes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        station
        distro
        note
        author
        status
        createdAt
        deletedAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getEventNotes = /* GraphQL */ `
  query GetEventNotes($id: ID!) {
    getEventNotes(id: $id) {
      id
      station
      ssid
      note
      author
      status
      createdAt
      deletedAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listEventNotes = /* GraphQL */ `
  query ListEventNotes(
    $filter: ModelEventNotesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        station
        ssid
        note
        author
        status
        createdAt
        deletedAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncEventNotes = /* GraphQL */ `
  query SyncEventNotes(
    $filter: ModelEventNotesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEventNotes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        station
        ssid
        note
        author
        status
        createdAt
        deletedAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPermissions = /* GraphQL */ `
  query GetPermissions($id: ID!) {
    getPermissions(id: $id) {
      id
      email
      permission
      alt_email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPermissions = /* GraphQL */ `
  query ListPermissions(
    $id: ID
    $filter: ModelPermissionsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPermissions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        email
        permission
        alt_email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPermissions = /* GraphQL */ `
  query SyncPermissions(
    $filter: ModelPermissionsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPermissions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        email
        permission
        alt_email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const permissionsByEmail = /* GraphQL */ `
  query PermissionsByEmail(
    $email: AWSEmail!
    $sortDirection: ModelSortDirection
    $filter: ModelPermissionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    permissionsByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        permission
        alt_email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getStreams = /* GraphQL */ `
  query GetStreams($id: ID!) {
    getStreams(id: $id) {
      id
      station
      ssid
      activesb
      primary
      hidden
      category
      source
      author
      config
      input
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listStreams = /* GraphQL */ `
  query ListStreams(
    $filter: ModelStreamsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStreams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        station
        ssid
        activesb
        primary
        hidden
        category
        source
        author
        config
        input
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncStreams = /* GraphQL */ `
  query SyncStreams(
    $filter: ModelStreamsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncStreams(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        station
        ssid
        activesb
        primary
        hidden
        category
        source
        author
        config
        input
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
