import React, { useContext, useState } from "react";

// MUI Components
import { Category, ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";

// AWS Components
import { DataStore } from "aws-amplify";
import { Streams } from "../../models";

// Gray Componets
import { Application } from "../../context/Application";
import setActivityLog from "../../utilities/setActivityLog";
import { getCategory } from "../../utilities/utility";

const AdminStreamCategory = ({ streamData, forceUpdate }) => {
  const global = useContext(Application);
  // const [encoder, setEncoder] = useState(null);

  /* Drop Down Elements */
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  /* Admin Tool - Set SyncBox Workflow */
  const updateCategory = async (data, category) => {
    const original = await DataStore.query(Streams, (c) =>
      c.and((c) => [c.ssid.eq(+data.ssid), c.station.eq(data.sname.toLowerCase().replace(/-\D\D/, ""))])
    );

    try {
      if (original.length > 0 && original[0].hasOwnProperty("ssid")) {
        /* Update Record */
        console.log("Update");
        await DataStore.save(
          Streams.copyOf(original[0], (updated) => {
            updated.category = category;
            updated.author = global.appUser.email;
          })
        );
      } else {
        /* Create Record */
        console.log("Create");
        await DataStore.save(
          new Streams({
            station: data.sname.toLowerCase().replace(/-\D\D/, ""),
            ssid: +data.ssid,
            activesb: data.activesb,
            category: category,
            author: global.appUser.email,
          })
        );
      }

      await setActivityLog({
        type: "STREAM CATEGORY",
        note: `Stream category set to ${category} for #${+data.ssid}`,
        author: global.appUser.email,
        station: global.activeStation.station,
      });

      // Refresh Navigation
      forceUpdate();
    } catch (e) {
      console.log("   - ERROR", e);
    }
  };

  return (
    <Box className="admin___panel_button">
      <Box className="title">
        <Category fontSize="1rem" />
        <Typography>Stream Categorization</Typography>
      </Box>
      <Box className="description">
        <Typography>
          Identify the stream category for <b>{streamData.friendly}</b> to help in station discussions and in troubleshooting efforts.
        </Typography>
        <Typography>
          <b>NOTE:</b> This is solely informational and used on <b>SyncMon</b>. It does not affect data with Zeam.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "0.25rem",
          flexDirection: "column",
        }}
      >
        <Box className="status">
          <Button
            id="category-button"
            variant="outlined"
            aria-controls={open ? "category-source-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            size="small"
            endIcon={open ? <ExpandLess /> : <ExpandMore />}
          >
            {open ? "Select Category..." : streamData?.category ? getCategory(streamData?.category) : "Select Category"}
          </Button>
          <Menu
            id="category-source-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {[
              { value: "NEWS", name: "News Channel" },
              { value: "BREAKING", name: "24/7 Breaking " },
              { value: "WEATHER", name: "24/7 Weather" },
              { value: "TRAFFIC", name: "24/7 Traffic" },
              { value: "ALT", name: "Alt Network" },
              { value: "CAMS", name: "Tower Cams" },
              { value: "SPANISH", name: "Spanish Language" },
              { value: "ARC", name: "Arc XP" },
              { value: "INACTIVE", name: "Unknown" },
            ].map((item, key) => {
              return (
                <MenuItem
                  key={item.value}
                  onClick={() => {
                    handleClose();
                    updateCategory(streamData, item.value);
                  }}
                  sx={{
                    minWidth: "16.25rem",
                    fontSize: "0.875rem",
                    fontWeight: streamData?.category === item.value ? 700 : "inherit",
                    color: streamData?.category === item.value ? "rgba(15, 72, 255, 0.75)" : "inherit",
                  }}
                >
                  {item.name}
                </MenuItem>
              );
            })}
          </Menu>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(AdminStreamCategory);
