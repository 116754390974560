/**
 * Checks if the user has admin-level access.
 *
 * @param {string} level - The user's access level.
 * @param {boolean} hideTools - Whether admin tools should be hidden.
 * @param {boolean} override - Whether to override default behavior.
 * @returns {boolean} - True if the user is an admin and conditions are met, false otherwise.
 */
export function isAdmin(level, hideTools = false, override = true) {
  const isLevelAdmin = ["ADMIN", "SUPERADMIN"].includes(level);

  return isLevelAdmin && !hideTools && !override;
}

/**
 * Checks if the user has super admin-level access.
 *
 * @param {string} level - The user's access level.
 * @param {boolean} hideTools - Whether admin tools should be hidden.
 * @param {boolean} override - Whether to override default behavior.
 * @returns {boolean} - True if the user is a super admin and conditions are met, false otherwise.
 */
export function isSuperAdmin(level, hideTools = false, override = false) {
  const isLevelSuperAdmin = level === "SUPERADMIN";

  return isLevelSuperAdmin && !hideTools && !override;
}
