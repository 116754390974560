import { Box, Typography } from "@mui/material";
import React, { memo } from "react";

const MenuRowItem = ({ label, value }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        padding: "0.25rem 0",
      }}
    >
      <Typography variant="body2" sx={{ fontSize: "0.75rem", minWidth: "4rem", fontWeight: 600 }}>
        {label}:
      </Typography>
      <Typography variant="body2" sx={{ fontSize: "0.75rem", textAlign: "right", minHeight: "1rem", lineHight: "0.875rem" }}>
        {value ? (
          value
        ) : (
          <span
            style={{
              fontStyle: "italic",
              color: "#ccc",
            }}
          >
            Not Set
          </span>
        )}
      </Typography>
    </Box>
  );
};

export default memo(MenuRowItem);
