// React Components
import React, { memo } from "react";

// Material UI Components
import { Avatar, Tooltip, Box } from "@mui/material";
import { green, orange, red } from "@mui/material/colors";

import { CheckCircleOutline, ErrorOutline, HelpOutline } from "@mui/icons-material";

const BoxStatus = ({ status }) => {
  const getState = () => {
    const code = status.length === 1 ? status[0].toLowerCase() : "ERROR";

    switch (code) {
      case "good":
        return (
          <Tooltip title="Server stream is operational" arrow placement="right">
            <Avatar
              className="meta___status"
              sx={{
                backgroundColor: green[600],
                height: "0.875rem",
                width: "0.875rem",
                margin: "0.175rem auto",
              }}
            >
              <CheckCircleOutline sx={{ height: "0.875rem" }} />
            </Avatar>
          </Tooltip>
        );
      case "alert":
        return (
          <Tooltip title="Server stream is flagged as alerted" arrow placement="right">
            <Avatar
              className="meta___status"
              sx={{
                backgroundColor: red[600],
                height: "0.875rem",
                width: "0.875rem",
                margin: "0.175rem auto",
              }}
            >
              <ErrorOutline sx={{ height: "1rem" }} />
            </Avatar>
          </Tooltip>
        );
      default:
        return (
          <Tooltip title="Syncmonitor did not provide a clear server stream state" arrow placement="right">
            <Avatar
              className="meta___status"
              sx={{
                backgroundColor: orange[600],
                height: "0.875rem",
                width: "0.875rem",
                margin: "0.175rem auto",
              }}
            >
              <HelpOutline sx={{ height: "1rem" }} />
            </Avatar>
          </Tooltip>
        );
    }
  };

  return <Box> {getState()} </Box>;
};

export default memo(BoxStatus);
