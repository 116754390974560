// React Components
import React, { memo, useContext } from "react";
import ReactGa4 from "react-ga4";

// MUI Components
import { HelpOutline as HelpIcon } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

// Gray Components
import { Application } from "../../../context/Application";

const ButtonHelp = () => {
  const global = useContext(Application);

  const handleClick = (state) => {
    global.setAppUI({ ...global.appUI, showHelp: state });
    ReactGa4.event({
      category: "ui",
      action: "ui_view",
      label: "knowledgeBase_" + (state ? "show" : "hide"),
    });
  };

  return (
    <Tooltip title={"Open SyncMon Knowledge Center"} arrow placement="bottom">
      <HelpIcon className="header___right_icons" aria-label="help" onClick={() => handleClick(!global.appUI.showHelp)} />
    </Tooltip>
  );
};

export default memo(ButtonHelp);
