import React, { useContext } from "react";

// MUI
import { HelpOutline, PlayCircleOutline } from "@mui/icons-material";
import { grey, orange } from "@mui/material/colors";
import { Avatar, Box, Tooltip } from "@mui/material";

// Gray Components
import { Video } from "../../../../context/Video";
import { useNavigate } from "react-router-dom";

const DistroList = ({ data, onSelect }) => {
  const globalVideo = useContext(Video);
  const goto = useNavigate();

  // On Select Functions
  const onSelectDistro = (distro) => {
    const streams = globalVideo.station.streams.filter((e) => {
      return e.distro.toLowerCase().replace(/\s+/g, "_") === distro.toLowerCase().replace(/\s+/g, "_");
    });
    if (streams) {
      streams[0].type = "distro";
      globalVideo.setStream(streams[0]);
      goto("/video/" + globalVideo.station.station.toLowerCase() + "/distro/" + distro.toLowerCase().replace(/\s+/g, "_"));
    }
  };

  return (
    <React.Fragment>
      <Box className="streams___container_label">
        FAST CHANNEL STREAMS
        <Tooltip
          title="Live stream distributions from the station selected to Gray owned platforms or to select Gray partners."
          arrow
          placement="bottom-start"
        >
          <HelpOutline className="label___tooltip" />
        </Tooltip>
      </Box>
      <Box className="streams___list">
        {data &&
          data.length > 0 &&
          data.map((val, key) => {
            return (
              <Box
                key={"streams___distro_" + key}
                className={
                  "streams___list_row " +
                  (globalVideo.stream.distro.toLowerCase().replace(/\s+/g, "_") === val.distro.toLowerCase().replace(/\s+/g, "_")
                    ? "active"
                    : "inactive")
                }
                onClick={(e) => onSelectDistro(val.distro)}
              >
                <Box className="streams___list_meta distro">
                  <Avatar
                    sx={{
                      background:
                        globalVideo.stream.distro.toLowerCase().replace(/\s+/g, "_") === val.distro.toLowerCase().replace(/\s+/g, "_")
                          ? orange[500]
                          : grey[700],
                      color: "#fff",
                      height: "0.875rem",
                      width: "0.875rem",
                    }}
                  >
                    <PlayCircleOutline />
                  </Avatar>

                  <Box className="meta___title">
                    <span>
                      <b>{val.distro}</b>
                    </span>
                  </Box>
                </Box>
              </Box>
            );
          })}
      </Box>
    </React.Fragment>
  );
};

export default DistroList;
