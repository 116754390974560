import { fetchWithRetry, handleApiResponse } from "./apis";
const API_URL = "https://chwtgazeif.execute-api.us-east-1.amazonaws.com/default/syncmonitor-api/videos?";

/**
 * Updates the VOD M3U8 file for a specific station and ID.
 *
 * @param {Object} station - The station object containing station details.
 * @param {string} id - The ID of the VOD item.
 * @returns {Promise<Object>} - The response from the API or an error message.
 */
export const updateVODM3U8 = async (station, id) => {
  const url = `${API_URL}station=${station.station.toLowerCase()}&ssid=${id}&queryType=vod`;

  try {
    const data = await fetchWithRetry(url);
    const { apiResponse, errorMsg } = handleApiResponse(data, "message");

    // Return the parsed response or an error message if any
    if (errorMsg) {
      return { apiResponse: null, errorMsg };
    }

    return { apiResponse: apiResponse.videoOnDemandItem, errorMsg: null };
  } catch (error) {
    console.log("   - Error fetching data:", error);
    return {
      apiResponse: null,
      errorMsg: `Error fetching data: ${error.message}`,
    };
  }
};

/**
 * Retrieves the list of VOD items for a specific station.
 *
 * @param {Object} station - The station object containing station details.
 * @returns {Promise<Object>} - The response from the API or an error message.
 */
export const getStationVodList = async (station, abortController) => {
  const url = `${API_URL}station=${station.station.toLowerCase()}&queryType=vod`;

  try {
    const data = await fetchWithRetry(url, { signal: abortController.signal });

    // Check if the fetch was aborted
    if (abortController.signal.aborted) {
      throw new Error("Fetch aborted");
    }

    // Return the parsed response or an error message if any
    const { apiResponse, errorMsg } = handleApiResponse(data, "message");
    if (errorMsg) {
      return { apiResponse: null, errorMsg };
    }

    return { apiResponse: apiResponse.videoOnDemand, errorMsg: null };
  } catch (error) {
    if (error.name === "AbortError") {
      return {
        apiResponse: null,
        errorMsg: "Error fetching getStationVodList() logs from Zeam API ... Request aborted",
      };
    }

    return {
      apiResponse: null,
      errorMsg: `Error fetching data: ${error.message}`,
    };
  }
};
