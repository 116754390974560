import React, { useContext, useEffect, useState } from "react";

// MUI Components
import { AltRoute, ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";

// AWS Components
import { DataStore } from "aws-amplify";
import { Encoders } from "../../models";

// Gray Componets
import { getWorkflow } from "../navigation/includes/EventsSidebar/getWorkflow";
import { Application } from "../../context/Application";

import setActivityLog from "../../utilities/setActivityLog";

const AdminWorkflowConfig = ({ streamData, forceUpdate }) => {
  const global = useContext(Application);
  const [encoder, setEncoder] = useState(null);

  /* Drop Down Elements */
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (streamData.activesb) {
      (async () => {
        const results = await DataStore.query(Encoders, (c) => c.activesb.eq(streamData.activesb));

        if (results.length > 0 && results[0].hasOwnProperty("activesb")) {
          setEncoder(results[0]);
        }
      })();
    }

    return () => {};

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [global.appUI.forceUpdate]);

  /* Admin Tool - Set SyncBox Workflow */
  const updateWorkflow = async (data, workflow) => {
    // const original = await DataStore.query(Encoders, (c) => c.activesb.eq(data.activesb));
    const original = await DataStore.query(Encoders, (c) =>
      c.and((c) => [c.activesb.eq(data.activesb), c.station.eq(data.sname.toLowerCase().replace(/-\D\D/, ""))])
    );

    try {
      if (original.length > 0 && original[0].hasOwnProperty("activesb")) {
        /* Update Record */
        // console.log("Update");
        await DataStore.save(
          Encoders.copyOf(original[0], (updated) => {
            updated.workflow = workflow;
            updated.author = global.appUser.email;
          })
        );
      } else {
        /* Create Record */
        // console.log("Create");
        await DataStore.save(
          new Encoders({
            station: data.sname.toLowerCase().replace(/-\D\D/, ""),
            activesb: data.activesb,
            workflow: workflow,
            author: global.appUser.email,
          })
        );
      }

      await setActivityLog({
        type: "ENCODER WORKFLOW",
        note: `Encoder workflow set to ${workflow} for #${data.activesb}`,
        author: global.appUser.email,
        station: global.activeStation.station,
      });

      // Refresh Navigation
      forceUpdate();
    } catch (e) {
      console.log("   - ERROR", e);
    }
  };

  return (
    <Box className="admin___panel_button">
      <Box className="title">
        <AltRoute fontSize="1rem" />
        <Typography>SyncBox Config</Typography>
      </Box>
      <Box className="description">
        <Typography>
          Identify the SyncBox setup for <b>{streamData.activesb}</b> to the following configuration.
        </Typography>
        <Typography>
          <b>NOTE:</b> This is solely informational for troubleshooting.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "0.25rem",
          flexDirection: "column",
        }}
      >
        <Box className="status">
          <Button
            id="workflow-button"
            variant="outlined"
            aria-controls={open ? "workflow-source-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            size="small"
            endIcon={open ? <ExpandLess /> : <ExpandMore />}
          >
            {open ? "Select Config..." : encoder?.workflow ? getWorkflow(encoder?.workflow) : "Select Config"}
          </Button>
          <Menu
            id="workflow-source-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {[
              { value: "B1C1", name: "1 Box / 1 Channel" },
              { value: "B1C2", name: "1 Box / 2 Channel" },
              { value: "B1C3", name: "1 Box / 3 Channel " },
            ].map((item, key) => {
              return (
                <MenuItem
                  key={item.value}
                  // variant={encoder?.workflow === "B1C1" ? "contained" : "outlined"}
                  onClick={() => {
                    handleClose();
                    updateWorkflow(streamData, item.value);
                  }}
                  sx={{
                    minWidth: "16.25rem",
                    fontSize: "0.875rem",
                    fontWeight: encoder?.workflow === item.value ? 700 : "inherit",
                    color: encoder?.workflow === item.value ? "rgba(15, 72, 255, 0.75)" : "inherit",
                  }}
                >
                  {item.name}
                </MenuItem>
              );
            })}
          </Menu>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(AdminWorkflowConfig);
