import { Box, Typography } from "@mui/material";
import React, { memo } from "react";

const UpdatesRow = ({ data }) => {
  return (
    <Box className="updates___menu_list_row">
      <Typography className="list_row___date"># {data.date}</Typography>
      {data.message.map((paragraph) => {
        return <Typography key={paragraph} dangerouslySetInnerHTML={{ __html: paragraph }} />;
      })}
    </Box>
  );
};

export default memo(UpdatesRow);
