import React, { useContext } from "react";

// MUI Components
import { CheckCircleOutlineOutlined, LabelImportant } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { green } from "@mui/material/colors";

// AWS Components
import { DataStore } from "aws-amplify";
import { Streams } from "../../models";

// Gray Componets
import setActivityLog from "../../utilities/setActivityLog";
import { Application } from "../../context/Application";

const AdminPrimaryStream = ({ streamData, forceUpdate }) => {
  const global = useContext(Application);

  /* Admin Tool - Set Stream as Primary for ActiveSB */
  const updatePrimary = async (data) => {
    // Reset all streams on this SyncBox to not primary
    const results = await DataStore.query(Streams, (c) => c.and((c) => [c.activesb.eq(data.activesb), c.primary.eq(true)]));

    if (results && results.length > 0) {
      results.forEach(async function (result) {
        await DataStore.save(
          Streams.copyOf(result, (item) => {
            item.primary = null;
          })
        );
      });
    }

    // Check if this stream already exists
    const original = await DataStore.query(Streams, (c) =>
      c.and((c) => [c.ssid.eq(+data.ssid), c.station.eq(data.sname.toLowerCase().replace(/-\D\D/, ""))])
    );

    try {
      if (original.length > 0 && original[0].hasOwnProperty("ssid")) {
        /* Update Record */
        console.log("Update");
        await DataStore.save(
          Streams.copyOf(original[0], (updated) => {
            updated.primary = true;
            updated.author = global.appUser.email;
          })
        );
      } else {
        await DataStore.save(
          /* Create Record */
          new Streams({
            station: data.sname.toLowerCase().replace(/-\D\D/, ""),
            ssid: +data.ssid,
            activesb: data.activesb,
            primary: true,
            author: global.appUser.email,
          })
        );
      }

      await setActivityLog({
        type: "PRIMARY STREAM",
        note: `New Primary Stream set to #${+data.ssid})`,
        author: global.appUser.email,
        station: global.activeStation.station,
      });

      // Refresh Navigation
      forceUpdate();
    } catch (e) {
      console.log("   - ERROR", e);
    }
  };

  return (
    <Box className="admin___panel_button">
      <Box className="title">
        <LabelImportant />
        <Typography>Primary Stream</Typography>
      </Box>
      <Box className="description">
        <Typography>
          Set <b>{streamData.friendly}</b> as Primary Stream for SyncBox <b>{streamData.activesb}</b>
        </Typography>
        <Typography>
          <b>NOTE:</b> Setting the primary stream on a SyncBox helps users navigate to their most important streams
        </Typography>
      </Box>
      {streamData.primary ? (
        <Box className="status">
          <CheckCircleOutlineOutlined sx={{ color: "green" }} />
          <Typography sx={{ color: green[500] }}>Primary for {streamData.activesb}</Typography>
        </Box>
      ) : (
        <Box className="status">
          <Button variant="outlined" size="small" onClick={() => updatePrimary(streamData)}>
            Activate
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default React.memo(AdminPrimaryStream);
