// React Components
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactGa4 from "react-ga4";
import moment from "moment-timezone";

// MUI Components
import { Alert, Box, Divider, Slide, Snackbar, Tooltip, Typography } from "@mui/material";
import { Bolt, Check, MoreHoriz, Warning } from "@mui/icons-material";
import { blue, green, orange, red } from "@mui/material/colors";

// Gray Components
import { Application } from "../../context/Application";
import { getTz } from "../../utilities/utility";

import StreamDetails from "../eventMonitor/subcomponents/StreamDetails";
import StreamDateRange from "../eventMonitor/StreamDateRange";
import AdminPanel from "../streamsAdmin/AdminPanel";

const AdAnalysis = () => {
  const goto = useNavigate();

  const global = useContext(Application);
  const [metrics, setMetrics] = useState(null);
  const [adBreaks, setAdBreaks] = useState(null);
  const [showAdmin, setShowAdmin] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(false);

  // Handle Fetch Failures
  var retry = 0;
  const maxRetry = 4;

  // const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  useEffect(() => {
    global.setAppUI((prev) => ({ ...prev, helpTopic: "reports" }));

    if (global.activeStation.station) {
      document.title =
        global.activeStream.ssname + " | " + global.activeStation.station.toUpperCase() + " Ad Break Analysis | SyncMon | Gray Digital";
      ReactGa4.send({ hitType: "pageview" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [global.activeStation.station, global.activeStream.ssname]);

  const getLogs = useCallback(
    (localStartDate, localEndDate) => {
      const api_url =
        "https://" +
        "chwtgazeif.execute-api.us-east-1.amazonaws.com/default/syncmonitor-api/logs?" +
        "ssid=" +
        global.activeStream.ssid +
        "&start=" +
        moment.utc(localStartDate).format() +
        "&end=" +
        moment.utc(localEndDate).format();

      fetch(api_url)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          if ("errorMessage" in data) {
            goto("/view/" + global.activeStation.station.toLowerCase());
            setAdBreaks(null);

            global.setAppUI((prev) => ({ ...prev, error: data.errorType + ": " + data.errorMessage }));
            global.setActiveStream(null);

            console.log("FAILED");
          } else {
            const data_object = JSON.parse(data.message);

            if (data_object.events) {
              let results = [];
              global.setAppUI((prev) => ({ ...prev, error: null }));

              /* Loop through each event looking for CUE-OUT & corresponding CUE-INs */
              data_object.events.map((e) => {
                /* CUE-OUT Detected */
                if (e.EventType === 0 && e.IsBegin === true) {
                  /* Check if previous ad break has completed */
                  if (results.length > 0 && results[results.length - 1].endTime === null) {
                    results[results.length - 1].cueOuts.push(e.Time);
                    console.log("    - Ad Break Failure");
                  } else {
                    /* Start new ad break */
                    results.push({
                      type: "CUE-Out",
                      duration: Math.round(e.EstimatedDuration / 1000000000),
                      startTime: e.Time,
                      endTime: null,
                      realDuration: null,
                      cueOuts: [],
                      break: null,
                      status: "Open",
                    });
                  }

                  /* CUE-IN Detected */
                } else if (e.EventType === 0 && e.IsBegin === false) {
                  /* Skip if first Ad Beak is a CUE-IN */
                  if (results.length > 0) {
                    /* Check if its a dangling CUE-IN */
                    if (results[results.length - 1].endTime === null) {
                      const st = moment.utc(results[results.length - 1].startTime);
                      const et = moment.utc(e.Time);

                      var duration = moment.duration(et.diff(st));
                      var seconds = duration.asSeconds();

                      results[results.length - 1].endTime = e.Time;
                      results[results.length - 1].realDuration = seconds;

                      if (Math.round(results[results.length - 1].duration) > Math.round(results[results.length - 1].realDuration))
                        results[results.length - 1].break = "short";
                      if (Math.round(results[results.length - 1].duration) < Math.round(results[results.length - 1].realDuration))
                        results[results.length - 1].break = "long";
                      if (Math.round(results[results.length - 1].duration) === Math.round(results[results.length - 1].realDuration))
                        results[results.length - 1].break = "match";

                      results[results.length - 1].status = "Good";
                    } else {
                      // TODO: Handler for dangling CUE-INs? Show as a flush clear?
                    }
                  }
                }
                return true;
              });

              if (results[results.length - 1].endTime === null) {
                console.log("     - Last ad break still open");

                const st = moment.utc(results[results.length - 1].startTime);
                const et = moment.utc();

                var duration = moment.duration(et.diff(st));
                var seconds = duration.asSeconds();

                results[results.length - 1].realDuration = seconds;

                if (Math.round(results[results.length - 1].duration) > Math.round(results[results.length - 1].realDuration))
                  results[results.length - 1].break = "short";
                if (Math.round(results[results.length - 1].duration) < Math.round(results[results.length - 1].realDuration))
                  results[results.length - 1].break = "long";

                if (Math.round(results[results.length - 1].duration) === Math.round(results[results.length - 1].realDuration))
                  results[results.length - 1].status = "Good";
              }

              const metrics = {
                breaksTotal: results.length,
                durationDeclared: 0,
                durationActual: 0,
                durationDelta: 0,
                breaksShort: 0,
                breaksMatch: 0,
                breaksLong: 0,
              };

              results.forEach((val, key) => {
                if (Math.round(val.duration) > Math.round(val.realDuration)) ++metrics.breaksShort;
                if (Math.round(val.duration) < Math.round(val.realDuration)) ++metrics.breaksLong;
                if (Math.round(val.duration) === Math.round(val.realDuration)) ++metrics.breaksMatch;
                metrics.durationDeclared = metrics.durationDeclared + val.duration;
                metrics.durationActual = metrics.durationActual + val.realDuration;

                return true;
              });
              metrics.durationDeclared = Math.round(metrics.durationDeclared);
              metrics.durationActual = Math.round(metrics.durationActual);
              metrics.durationDelta = metrics.durationDeclared - metrics.durationActual;

              setMetrics(metrics);
              setAdBreaks(results.reverse());
              setUpdateStatus(true);
            } else {
              global.setAppUI((prev) => ({
                ...prev,
                error: "No event logs exist for stream " + global.activeStream.ssname + "(" + global.activeStream.ssid + ")",
              }));

              setAdBreaks(null);
              console.log("FAILED");
            }
          }
        })
        .catch((error) => {
          console.log("   - Error fetching data: ", error);

          if (retry < maxRetry) {
            global.setAppUI((prev) => ({
              ...prev,
              error: "Error fetching logs from Zeam API ... Retry attempt #" + retry,
            }));

            /* TODO: Review is skipping retries is smart */
            // retry++;
            // sleep(3000);
            // getLogs(global.appDates.start, global.appDates.end);
          } else {
            global.setAppUI((prev) => ({
              ...prev,
              error: "Error fetching logs from Zeam API ... Final attempt failed ... Please retry reloading the page or reach out to Zeam",
            }));
          }
        })
        .finally();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [global.activeStream] // Ignore warning
  );

  /* Get Logs on load
   * Do not pass global start/end date as dependency as
   * it would change creating unnecessary calls to SB
   */
  useEffect(() => {
    global.setAppDates((prev) => ({ ...prev, timezone: global.activeStation.timezone }));
    if (global.appUI.refresh >= 120) {
      global.setAppUI((prev) => ({ ...prev, refresh: 120 }));
    }
    getLogs(global.appDates.start, global.appDates.end);

    return () => {
      // cleanup()
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLogs, global.appDates.start, global.appDates.end]); // Ignore warning

  const setColor = ({ type, defined, actual }) => {
    if (type === "percent") {
      const percent = Math.round((actual / defined) * 100);

      switch (true) {
        case percent < 50:
          return [red[500], "white"];
        case percent < 90:
          return [orange[500], "white"];
        case percent < 110:
          return [green[500], "white"];
        case percent < 150:
          return [orange[500], "white"];
        default:
          return [red[500], "white"];
      }
    } else if (type === "delta") {
      switch (true) {
        case actual < -5:
          return [orange[500], "white"];
        case actual < 5:
          return [green[500], "white"];
        case actual < 10:
          return [orange[500], "white"];
        case actual < 15:
          return [red[500], "white"];
        default:
          return [red[500], "white"];
      }
    } else if (type === "value") {
      return [blue[100], "#333"];
    }
  };

  const Scorecard = ({ type, value, denominator, label, conditional }) => {
    const colors = setColor({ type: type, defined: denominator, actual: value });
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "0.5rem 1rem",
          borderRadius: "0.5rem",
          flexGrow: 2,
          background: colors[0],
          color: colors[1],
          boxShadow: "rgba(6, 26, 54, 0.16) 0 0.25rem 1rem",
        }}
      >
        <Typography
          sx={{
            fontSize: "2rem",
            fontWeight: "600",
            color: conditional && (value < -5 || value > 5) ? red[600] : colors[1],
          }}
        >
          {value}
          {denominator && (
            <Typography component="span" sx={{ fontSize: "1rem", color: colors[1] }}>
              {type === "percent" ? " / " : ""}
              {denominator}
            </Typography>
          )}
        </Typography>
        <Typography sx={{ fontSize: "0.65rem", textTransform: "uppercase" }}>{label}</Typography>
      </Box>
    );
  };

  const handleCloseAlert = () => {
    global.setAppUI((prev) => ({ ...prev, error: null }));
  };

  function SlideTransition(props) {
    return <Slide {...props} direction="right" />;
  }

  return (
    <Box className="eventlogs___wrapper" sx={{ display: "flex", flexDirection: "row", gap: "0", flexGrow: 2 }}>
      <StreamDetails v={global.activeStream} showAdmin={showAdmin} setShowAdmin={setShowAdmin} />
      {global.appUI.error && (
        <Alert severity="error" className="error" onClose={() => handleCloseAlert()}>
          {global.appUI.error}
        </Alert>
      )}

      {showAdmin ? (
        <AdminPanel v={global.activeStream} />
      ) : (
        <Box className="eventlogs___container" component="main" sx={{ marginLeft: 0 }}>
          <StreamDateRange route="ad_analysis" getLogs={getLogs} />

          <Snackbar open={updateStatus} autoHideDuration={10000} onClose={() => setUpdateStatus(false)} TransitionComponent={SlideTransition}>
            <Alert icon={<Check fontSize="inherit" />} severity="success">
              <b>UPDATED</b>: "Ad Analysis" has been refreshed to reflect the newest event data for {global.activeStation.station.toUpperCase()}
            </Alert>
          </Snackbar>

          {metrics && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "stretch",
                gap: "0.5rem",
                width: "100%",
              }}
            >
              <Scorecard value={metrics.durationDeclared.toLocaleString("en-US")} type="value" denominator={" sec"} label={"Expected Duration"} />
              <Scorecard value={metrics.durationActual.toLocaleString("en-US")} type="value" denominator={" sec"} label={"Actual Duration"} />
              <Scorecard value={metrics.breaksShort} type="percent" denominator={metrics.breaksTotal} label={"Shortened Breaks"} />
              <Scorecard value={metrics.breaksMatch} type="percent" denominator={metrics.breaksTotal} label={"Matching Breaks"} />
              <Scorecard value={metrics.breaksLong} type="percent" denominator={metrics.breaksTotal} label={"Extended Breaks"} />
              <Scorecard
                value={Math.round((metrics.durationActual / metrics.durationDeclared - 1) * 100)}
                type="delta"
                conditional={true}
                denominator={"%"}
                label={"Duration Delta"}
              />
            </Box>
          )}
          <Typography
            variant="h5"
            sx={{
              display: "flex",
              gap: "0.25rem",
              fontWeight: "bold",
              fontSize: "0.75rem",
              textTransform: "uppercase",
              backgroundColor: "white",
              borderRadius: "0.5rem",
              padding: "1rem",
              boxShadow: "rgba(6, 26, 54, 0.16) 0 0.25rem 1rem",
            }}
          >
            Ad Break Debugging
          </Typography>
          <Box
            sx={{
              // width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "stretch",
              flexWrap: "wrap",
              overflowY: "scroll",
              height: "100%",
              maxWidth: "100%",
              background: "white",
              borderRadius: "0.5rem",
              boxShadow: "rgba(6, 26, 54, 0.16) 0 0.25rem 1rem",
            }}
          >
            {adBreaks &&
              adBreaks.map((val, key) => {
                const colors = setColor({ type: "percent", defined: val.duration, actual: val.realDuration });

                return (
                  <Box
                    key={key}
                    sx={{
                      borderRadius: "0.5rem",
                      width: "100%",
                      marginBottom: "1rem",
                    }}
                  >
                    {val.cueOuts.length > 0 && (
                      <Box
                        sx={{
                          padding: "0.25rem 1rem",
                          display: "flex",
                          flexDirection: "row",
                          gap: "0.5rem",
                          background: red[800],
                          color: "white",
                          alignItems: "center",
                        }}
                      >
                        <Warning sx={{ color: orange[500], fontSize: "1rem" }} />
                        <Typography variant="caption">
                          {`Missing corresponding CUE-IN. Spanned ${val.cueOuts.length} additional ad ${
                            val.cueOuts.length > 1 ? "breaks" : "break"
                          } before ending.`}
                        </Typography>
                      </Box>
                    )}
                    {val.endTime === null && (
                      <Box
                        sx={{
                          padding: "0.25rem 1rem",
                          display: "flex",
                          flexDirection: "row",
                          gap: "0.5rem",
                          background: green[800],
                          color: "white",
                          alignItems: "center",
                        }}
                      >
                        <Warning sx={{ color: orange[500], fontSize: "1rem" }} />
                        <Typography variant="caption">Stream is actively in a break that hasn't completed</Typography>
                      </Box>
                    )}
                    <Box
                      sx={{
                        padding: "1rem",
                        background: val.cueOuts.length > 0 ? red[50] : val.endTime === null ? green[50] : "inherit",
                      }}
                    >
                      <Box
                        variant="caption"
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          paddingBottom: "0.5rem",
                          overflow: "scroll",
                          whiteSpace: "nowrap",
                          alignItems: "center",
                          gap: "0.25rem",
                        }}
                      >
                        <Box component="span" sx={{ fontSize: "1rem", color: green[500] }}>
                          ⏵
                        </Box>
                        <Typography variant="caption">
                          {moment.tz(val.startTime, getTz(global.appDates.timezone)).format("MMM D, YYYY @ h:mm:ssa z")}
                        </Typography>
                        <Typography variant="caption">&rArr;</Typography>
                        {val.cueOuts.length > 0 && (
                          <Tooltip
                            title={
                              <Box>
                                <Typography sx={{ fontSize: "0.875rem" }}>Additional CUE-OUTs</Typography>
                                <Divider
                                  sx={{
                                    background: "white",
                                    margin: "0.25rem 0 .5rem",
                                  }}
                                />
                                {val.cueOuts.map((v, k) => {
                                  return (
                                    <Typography
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                      variant="caption"
                                      key={"cueouts_" + k}
                                    >
                                      {moment.tz(v, getTz(global.appDates.timezone)).format("MMM D, YYYY @ h:mm:ssa z")}
                                    </Typography>
                                  );
                                })}
                              </Box>
                            }
                            arrow
                            placement="bottom"
                          >
                            <MoreHoriz
                              sx={{
                                borderRadius: "1rem",
                                "&:hover": {
                                  cursor: "pointer",
                                  background: "#333",
                                  color: "#fff",
                                },
                              }}
                            />
                          </Tooltip>
                        )}
                        {val.cueOuts.length > 0 && <Typography variant="caption">&rArr;</Typography>}
                        <Typography variant="caption">
                          {val.endTime ? (
                            moment.tz(val.endTime, getTz(global.appDates.timezone)).format("MMM D, YYYY @ h:mm:ss a z")
                          ) : (
                            <span style={{ fontStyle: "italic", fontWeight: "bold" }}>Open</span>
                          )}
                        </Typography>
                        {val.endTime && (
                          <Box component="span" sx={{ fontSize: "1rem", color: green[500] }}>
                            ⏴
                          </Box>
                        )}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          fontSize: "0.875rem",
                          gap: "0.25rem",
                          whiteSpace: "nowrap",
                          paddingLeft: "2rem",
                        }}
                      >
                        <Box sx={{ flexGrow: 0, flexShrink: 0, width: "8rem" }}>Expected Duration:</Box>
                        <Box
                          sx={{
                            width: val.duration / 2 + "px",
                            background: blue[500],
                            height: "1rem",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          {val.duration / 2 > 37 * 16 && <Bolt sx={{ marginTop: "-0.175rem", fontSize: "1.375rem" }} />}
                        </Box>
                        <Box sx={{ flexGrow: 0, flexShrink: 0, width: "4rem" }}>~{Math.round(val.duration)} sec</Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          fontSize: "0.875rem",
                          gap: "0.25rem",
                          whiteSpace: "nowrap",
                          paddingLeft: "2rem",
                        }}
                      >
                        <Box sx={{ flexGrow: 0, flexShrink: 0, width: "8rem" }}>Actual Duration:</Box>
                        <Box
                          sx={{
                            width: val.realDuration ? val.realDuration / 2 + "px" : 0,
                            background: colors[0],
                            // background: setColor({
                            //   defined: val.duration,
                            //   actual: val.realDuration,
                            // }),
                            height: "1rem",
                            color: colors[1],
                            textAlign: "center",
                            overflow: "hidden",
                          }}
                        >
                          {val.realDuration / 2 > 37 * 16 && <Bolt sx={{ marginTop: "-0.175rem", fontSize: "1.375rem" }} />}
                        </Box>
                        <Box sx={{ flexGrow: 0, flexShrink: 0, width: "4rem" }}>~{Math.round(val.realDuration)} sec</Box>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default React.memo(AdAnalysis);
