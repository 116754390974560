// utilities/api.js

const SLEEP_DURATION = 3000;

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const fetchWithRetry = async (url, options = {}, maxRetry = 4) => {
  const { signal } = options;

  for (let retry = 0; retry <= maxRetry; retry++) {
    try {
      const response = await fetch(url, options);
      if (response.ok) {
        return await response.json();
      }

      if (signal?.aborted) {
        throw new Error("Fetch aborted");
      }

      throw new Error(`HTTP error! status: ${response.status}`);
    } catch (error) {
      if (error.name === "AbortError") {
        throw new Error("Fetch aborted");
      }

      if (retry < maxRetry - 1) {
        await sleep(SLEEP_DURATION);
      } else {
        throw new Error(`   - Failed after ${maxRetry} attempts: ${error.message}`);
      }
    }
  }
};

export const handleApiResponse = (data, key = "message") => {
  if ("errorMessage" in data) {
    return {
      apiResponse: null,
      errorMsg: `Error: ${data.errorMessage}`,
    };
  } else {
    const dataObject = JSON.parse(data[key]);
    return {
      apiResponse: dataObject,
      errorMsg: null,
    };
  }
};
