export const StreamsList = [
  {
    station: "LNL",
    priority: null,
    scte: "dbi",
    streams: [
      {
        distro: "General",
        url: "https://dai2-playlistserver.aws.syncbak.com/cpl/20675223/scte35hls/1.0/7b2264657669636554797065223a387d/master.m3u8?access_token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IkdyYXkyMDE2MDgyOSJ9.eyJtaWQiOiIyMDY3NTIyMyIsImNpZCI6MjExMTUsInNpZCI6MTU4LCJtZDUiOiI5OGQ1ZmM0YTUwNzBkZDhiMmZjNDY0Nzg5MzY5YTc2YSIsImlhdCI6MTY3Mjk1MzIxMSwiaXNzIjoiU3luY2JhayAoVEcpIn0.1DXgi9e6F4T6wmckXenEiom4j0HMMeBi9ALd6lJdY1s",
      },
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/07a6767fb7f8892dcf1f708a1674755b4f67bee0289e6742f835779d4dcca8c621b88f6a433c/master.m3u8",
      },
      {
        distro: "Softron",
        url: "https://roku-dai2-playlistserver.aws.syncbak.com/m2/roku/05f9298ca378918be4f41305b2db1b78e630cc3ec459e377c5ec0d65389e4c19985dc4c6dbe9/master.m3u8",
      },
      {
        distro: "Vizio",
        url: "https://dai2-playlistserver.aws.syncbak.com/m2/vizio/fdeaa1c16168d01a95bcac99667b3352bde579e833d9bc66b272ad8658b57238777e9e3f313f/master.m3u8",
      },
    ],
  },
  {
    station: "INTV",
    priority: null,
    scte: "middleman",
    streams: [
      {
        distro: "General",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/a52fa30e3562afaa9bff7f1e388b5d6f8998b00ab17072618f1a9836010e0ec5a0be52633578/master.m3u8",
      },
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/532995f5f62ffdb6db8cb054c16632155f18c98a7a549802c8740c3f91afd2b6c49f449d32fa/master.m3u8",
      },
    ],
  },
  {
    station: "KBJR",
    priority: null,
    scte: "tbw",
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/d0528f4d0f0ca025c53fb05242a84b42eb56d7f6f090ea64e6cd7895afc87519c0fb8f2d7ff0/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/fe17f47e53884305f7b14be452583331e6e67be531d531e463c8cb8988913dda4e9712ac08e4/master.m3u8",
      },
    ],
  },
  {
    station: "KSCW",
    prority: null,
    streams: [],
  },
  {
    station: "KXDF",
    priority: null,
    streams: [],
  },
  {
    station: "KYOU",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/ad53c768015022fb5045b7f68fe7c25d36838b5d99a7e0c55e583323dcb2ed7ed2f894c689e5/master.m3u8",
      },
    ],
  },
  {
    station: "WECP",
    priority: null,
    streams: [],
  },

  {
    station: "WOIO",
    priority: 1,
    scte: "middleman",
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/8b3d250de54d3097ae66a47f0ee4a515c01eebb300695b4ab455d5394356bb5879cdc6760a02/master.m3u8",
      },
      {
        distro: "Roku",
        url: "https://roku-dai2-playlistserver.aws.syncbak.com/m2/roku/8305da0800d09849c1ea2c25be9a4d982b5654d415fc9dea701fbfbd6868c9a5ba7a7b9a8e0a/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/bebf391ebac8323b3003ba8e6dc2db294bd5a006680b3539bfd14482d13e339dbf689d638ae8/master.m3u8",
      },
      {
        distro: "Vizio",
        url: "https://dai2-playlistserver.aws.syncbak.com/m2/vizio/c13cd68b2eec23da08c69c7378f81f34ea29d3957b0aed09636b7972af1f25a7e3bff94d7540/master.m3u8",
      },
    ],
  },
  {
    station: "WBTV",
    priority: 2,
    scte: "middleman",
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/f8ab2fa812971602f1fa7a6fddb6ae2421a006bafb1385b605beb159d092c3bc5633fc90f32a/master.m3u8",
      },
      {
        distro: "Roku",
        url: "https://roku-dai2-playlistserver.aws.syncbak.com/m2/roku/869239dfc580b9ca2b3c3c3ac019de1ffb00ee44911b387a5de4bc5227b3abc183f3ddcd157b/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/6ea583967d20d085aae1f5377b1b880ccb7308ae02b3020195700a93cac3d5e2f00064c27ee8/master.m3u8",
      },
      {
        distro: "Vizio",
        url: "https://dai2-playlistserver.aws.syncbak.com/m2/vizio/600b076efb62b996c24388dac4f1d7763cc74faf125c84dddcd0157a8a577ea570f6454dafc8/master.m3u8",
      },
    ],
  },
  {
    station: "WXIX",
    priority: 3,
    scte: "middleman",
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/034ef51c9c1780880342fd78be42ec420f0338721108680959753e01b72c5c7a4cf540c6d9aa/master.m3u8",
      },
      {
        distro: "Roku",
        url: "https://roku-dai2-playlistserver.aws.syncbak.com/m2/roku/f9aba722211f8effa431b06cd83902bb685877c8b0e4f15abc6aa47fa427adb1266234f8598e/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/9a5758eca88bfdb66a4a4f8fcf53d9eafff0834f76d24fefd0ae5ae480f87d8d8c437627aad3/master.m3u8",
      },
      {
        distro: "Vizio",
        url: "https://dai2-playlistserver.aws.syncbak.com/m2/vizio/78e78ac8f84d3b68f4fc841972110aded1bc49e9c2e96ce8bf40e1e77c2aa559484bbf989f4c/master.m3u8",
      },
    ],
  },
  {
    station: "WAVE",
    priority: 4,
    scte: "middleman",

    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/72db5a19346ae85c2207d3475f97ce1db910666a1d2f71b15900288c8b7079b8059e3f095513/master.m3u8",
      },
      {
        distro: "Roku",
        url: "https://roku-dai2-playlistserver.aws.syncbak.com/m2/roku/34ac25ef72cc66c1ff274ff0c7437fdf56c2af2f3914a324a611a5fe64433f9c638933662083/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/363142c8475c36e57a5b50ee7a10ad149a9ee395ab69769fb2beef3319f50df0447343f82e86/master.m3u8",
      },
      {
        distro: "Vizio",
        url: "https://dai2-playlistserver.aws.syncbak.com/m2/vizio/e84de9e00f31b1a65af9df794d3bfb7e6fbf64d34aaf6ec7131cfc7ce89deecac1ddd9409648/master.m3u8",
      },
    ],
  },
  {
    station: "WWBT",
    priority: 5,
    scte: "middleman",

    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/23c64a93a872fcb433e555cd18560488f846d2f4825d39044d975e9b28e0880f0287dc1d5c17/master.m3u8",
      },
      {
        distro: "Roku",
        url: "https://roku-dai2-playlistserver.aws.syncbak.com/m2/roku/4016d0f8610e4b5f3ad134ae4ca4a25d458c349a9785f3808e81ab708c140c276d6ef6ea92e4/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/37cf659563bb7911da661ba1fe0f56440d2a7ca4201789cfc468e5ed7ef5a6714686d9995dae/master.m3u8",
      },
      {
        distro: "Vizio",
        url: "https://dai2-playlistserver.aws.syncbak.com/m2/vizio/38dc5367ce2abd517f1d0302aff343f9e9b15575e39a457b16cd5bd64ffeed249e0d4ee21a31/master.m3u8",
      },
    ],
  },
  {
    station: "WMC",
    priority: 6,
    scte: "middleman",

    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/4dd59425afc33c607e0101091d1ad7eaee13596ddb4709628a238fbc281934bae5c9414cdf8e/master.m3u8",
      },
      {
        distro: "Roku",
        url: "https://roku-dai2-playlistserver.aws.syncbak.com/m2/roku/9d27a23e8027b1d9b37d550b267282218ed60a63c041c98327c4311732c0b720d915ae8e4589/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/f1bea59c3a67d9f92aa870681e85d2e9ca5d193d0e03d00581177cc897cb4611601dab28acd9/master.m3u8",
      },
      {
        distro: "Vizio",
        url: "https://dai2-playlistserver.aws.syncbak.com/m2/vizio/ad64601b5a6cadb8fd244b447854031bfcfc45676d388e2fe17779d1968ba1055bb396bc99aa/master.m3u8",
      },
    ],
  },
  {
    station: "WWSB",
    priority: 7,
    scte: "middleman",

    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/6d898d937f2f4cac43ff9316ece89512ad82ae719c2af35f3eeca7d2ad8267a73bd00db558bd/master.m3u8",
      },
      {
        distro: "Roku",
        url: "https://roku-dai2-playlistserver.aws.syncbak.com/m2/roku/fa0fd87dbdd6201694e7adfd73e0286410037757c4505ad5b62bed38f40b6ddd1e08c3ada8c4/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/f96a0b3f1d297a4959e8da34a10bfbeb2339b3477b86220bfd4e4682ecb8054fc3eba819b6d2/master.m3u8",
      },
      {
        distro: "Vizio",
        url: "https://dai2-playlistserver.aws.syncbak.com/m2/vizio/9d1d67d142ac5a6a4df3a3f4fc0f2b86e31f113bde80821d118ce569b63cb5834b0d49b7cf66/master.m3u8",
      },
    ],
  },
  {
    station: "WBRC",
    priority: 8,
    scte: "middleman",

    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/d942409830923fa678c3d0ff904a7241ba4cf9554c4d06d9459fc22dd4c626e8b5a8e67a9a37/master.m3u8",
      },
      {
        distro: "Roku",
        url: "https://roku-dai2-playlistserver.aws.syncbak.com/m2/roku/a3cc3c3c7c71d4d017d8fcd5c65fab5cc279d9ad400973d6ab84394f9d7e59dd28e7d965d773/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/976e9fee3ed276de0a7d6bf048da890d582d99cb58b48390e70d2269cbf390ca878403425862/master.m3u8",
      },
      {
        distro: "Vizio",
        url: "https://dai2-playlistserver.aws.syncbak.com/m2/vizio/0d867a1ec1bd867e8da879d59b5da3c9a3ff6d2dc437ed79847ab9e520b7324ab9f649562454/master.m3u8",
      },
    ],
  },
  {
    station: "WVUE",
    priority: 9,
    scte: "middleman",
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/0042b53f59e4579329525b65dd2a5ac11f172430fb6911dbb143b635f9a3697afb7dc1c50134/master.m3u8",
      },
      {
        distro: "Roku",
        url: "https://roku-dai2-playlistserver.aws.syncbak.com/m2/roku/8bb9e897ab9d9c8f412d3e04868508ce70984804d4fd26e486aa69562ae56a6e05d630f8302c/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/b79a843f8f9fb69ce8c1214f067ef61c6f53f733ad95aaf4296d2539f248f6bec0c4409a237a/master.m3u8",
      },
      {
        distro: "Vizio",
        url: "https://dai2-playlistserver.aws.syncbak.com/m2/vizio/5797f1cb1f595a5397800fe4d06a6c58adcf91a75176d014bf2c8c25e0c72e1d6486baf54a6a/master.m3u8",
      },
    ],
  },
  {
    station: "WVLT",
    priority: 10,
    scte: "middleman",

    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/cdd23c34fce6d55613fdcf996a04945002354510b071e66702d0a10848d07eed46c8dcee28fe/master.m3u8",
      },
      {
        distro: "Roku",
        url: "https://roku-dai2-playlistserver.aws.syncbak.com/m2/roku/d7e927789d0a166d616720137d9021d43c0756e1ca92c761735de8c063ac800602a15fc4ec85/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/4b1df7611fa26e2a7b8f6d22d6d8aa2f32280af97d9eefdb4545f54623e600caa98d8cc711c3/master.m3u8",
      },
      {
        distro: "Vizio",
        url: "https://dai2-playlistserver.aws.syncbak.com/m2/vizio/158a6de897184cb0a473e5326ba2563aaf28a59a24abcffe85e6ac53a20c0eec10399bd05b27/master.m3u8",
      },
    ],
  },
  {
    station: "WKYT",
    priority: 11,
    scte: "middleman",

    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/ebf2629f113d00524c3763d0e0a6154aab7c6f5d6fcaba49d2d4568d8125bf96fe627432b338/master.m3u8",
      },
      {
        distro: "Roku",
        url: "https://roku-dai2-playlistserver.aws.syncbak.com/m2/roku/1eb742142c0fce84a10965e3537ac1323839041953d63f8f924662c2cfa96e22b4dc63276ade/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/19fd0395a09b8cc72ed983a747fcdb5ba1db88c5726b9fdd0ae28981fd813deadf2767e06935/master.m3u8",
      },
      {
        distro: "Vizio",
        url: "https://dai2-playlistserver.aws.syncbak.com/m2/vizio/1afd2295113db9ea6eb145926a6eb2c14a0c714ed6e0d5d9b84ddeba1996f509abc73f6f4c3e/master.m3u8",
      },
    ],
  },
  {
    station: "WDBJ",
    priority: 12,
    scte: "middleman",

    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/d4b0347b832903c199af31c5dfb0f8115b842608495ee807fa8518ca069707dc222d0f30a2ee/master.m3u8",
      },
      {
        distro: "Roku",
        url: "https://roku-dai2-playlistserver.aws.syncbak.com/m2/roku/3534b7daf65fef47b9095147f55add030bd2afcc89dbbb937ab772f248b3af37303ac35cde2a/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/de0d050ab38811e7b5a04491c5555e8ae7271650bd1de032594224f774bbd9581e09e6f506c4/master.m3u8",
      },
      {
        distro: "Vizio",
        url: "https://dai2-playlistserver.aws.syncbak.com/m2/vizio/f654bbbbe70f637b82c43eb429073dfd2aca88a9e3911c96eb167d741400be4877059a93600e/master.m3u8",
      },
    ],
  },
  {
    station: "KOLD",
    priority: 13,
    scte: "middleman",

    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/a48cea9db82accf8a8536e21108f2835e5c0e5e78819dcc7852a6c4d608dc697a07a29e85793/master.m3u8",
      },
      {
        distro: "Roku",
        url: "https://roku-dai2-playlistserver.aws.syncbak.com/m2/roku/3241523d776275961a8166bd438c42ce745a07be700725b2b7c81e120f6c026eba98b2cdc83f/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/914338b0507b2ec31bdf3c14b0cd8aeb36bf2e23f29fc42325d15f81659175758523bd3413f5/master.m3u8",
      },
      {
        distro: "Vizio",
        url: "https://dai2-playlistserver.aws.syncbak.com/m2/vizio/33b76b490e765c0255748b30d70c627f6627c9b1d61c4acc42f17ba7b475c08b2078b4c3fdf8/master.m3u8",
      },
    ],
  },
  {
    station: "WBAY",
    priority: 14,
    scte: "middleman",

    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/a72127793ec1fd133ddc0e4b118356ca399120087be150d5da4b3c688a676348e6415bfd9d5d/master.m3u8",
      },
      {
        distro: "Roku",
        url: "https://roku-dai2-playlistserver.aws.syncbak.com/m2/roku/ae4f2687e296f74be36ffeaa958a0a554b148cc385cfb00aaaada3ec01b154fc031db3d54346/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/2cdf9ce45b067d4a92d73934368964e7ff53f63c9a102061864f2e3a5cb619ccae5c2f6b38b2/master.m3u8",
      },
      {
        distro: "Vizio",
        url: "https://dai2-playlistserver.aws.syncbak.com/m2/vizio/b29011cd5a88161e57e8d88344a3bf58c641b6cc579957cc6c782b43010b10ac18bf864d7d6c/master.m3u8",
      },
    ],
  },
  {
    station: "KWCH",
    priority: 15,
    scte: "middleman",
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/8d53fffb553ca806556f9aea9c216643b31d80ff2ac4be3821b98861ec5bdc19f3084ae192a6/master.m3u8",
      },
      {
        distro: "Roku",
        url: "https://roku-dai2-playlistserver.aws.syncbak.com/m2/roku/b79660008b80ca30abb6d321569d9e2b67504dd8677fb6d047fe31f12f41b88be29f88b328c3/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/3cf4b9339300440454ae61b7b23644f373c81a3e6b20f86d9ec1879ef2b687d09ea90aec5c5b/master.m3u8",
      },
      {
        distro: "Vizio",
        url: "https://dai2-playlistserver.aws.syncbak.com/m2/vizio/82f1d1be13f9b396023d0c5926a13f9ee2061f8ef46aff5f542640825204ce73d802b340d4c0/master.m3u8",
      },
    ],
  },
  {
    station: "KTVK",
    priority: 16,
    scte: "middleman",

    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/2b208d84d12b5800b755f3fc337e464b3bf1bb1dd8392e8110f2c35bb4c00a1a87168afdff3c/master.m3u8",
      },
      {
        distro: "Roku",
        url: "https://roku-dai2-playlistserver.aws.syncbak.com/m2/roku/06cf46fe86c314feaaddc98ef28031f078504d782a8000ad1b8b2366eecc16117c3ea52e0315/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/f4ff83b0b681d610eb88284b48ada820a2e6e9a103208e6cc462ecff9c47278afed961a15ef0/master.m3u8",
      },
      {
        distro: "Vizio",
        url: "https://dai2-playlistserver.aws.syncbak.com/m2/vizio/26baa51e067acfa5945219e3c6bd1722f3827d43f6c855f8f6bfffe2130f4bebc73844a0d63a/master.m3u8",
      },
    ],
  },
  {
    station: "WANF",
    priority: 17,
    scte: "middleman",

    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/15b0ac11614e2fad19b1917fa9fa2cfb989dca7e9735b4699d0a3b0a848e6428b29478e44bcb/master.m3u8",
      },
      {
        distro: "Roku",
        url: "https://roku-dai2-playlistserver.aws.syncbak.com/m2/roku/201e7f6b514f250bc9cb648b5ce2cabba95fd53bf379da79ba510b8a9775d788e20286236b00/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/9be0648beea60c877a7ae01f701a3df45cfec7e3e985c813938e66e2bb25fab308f5b715a4f0/master.m3u8",
      },
      {
        distro: "Vizio",
        url: "https://dai2-playlistserver.aws.syncbak.com/m2/vizio/c6e90270f8dea5d1e5eba8caa21ee236734c98f66116dcc73866340a84a805222bc2babbb3b6/master.m3u8",
      },
    ],
  },
  {
    station: "KPTV",
    priority: 18,
    scte: "middleman",

    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/00a8e436408bb9400007c65b12a9b8e59865399ac9dcb53c5d2cf6821f6e420755e242d90289/master.m3u8",
      },
      {
        distro: "Roku",
        url: "https://roku-dai2-playlistserver.aws.syncbak.com/m2/roku/6998b2908a4d46198d5d8fda3f39ecb87a52df8c750d3471dd8daf8a019f0ee8a282f5ce580f/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/b556a36dedeed59538a5a2cfb17a48908c811b874b9f7da682b4a06272607fc4f73e5d903ea2/master.m3u8",
      },
      {
        distro: "Vizio",
        url: "https://dai2-playlistserver.aws.syncbak.com/m2/vizio/0b45bbb59e6a84e400cb8ab6c5b323b5bb670d40f162d3a53b7286897caedd155e4e1ea483d8/master.m3u8",
      },
    ],
  },
  {
    station: "KMOV",
    priority: 19,
    scte: "middleman",

    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/1cda52c507ec96d5677e9c5a378aca7366ff8c33cb5ed6da570a793c5d1f70fcf760dda3b203/master.m3u8",
      },
      {
        distro: "Roku",
        url: "https://roku-dai2-playlistserver.aws.syncbak.com/m2/roku/4cef7e5e177d2603e4478138d532fa109ca0c8361c05d9aca73de2db086b36f2ce45b6be9de7/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/ea893597c6a5d0d06557b906ceb614259df25815106c7e8faa13c4b7aa20f59c78d2ff846e9c/master.m3u8",
      },
      {
        distro: "Vizio",
        url: "https://dai2-playlistserver.aws.syncbak.com/m2/vizio/0f1bec24119e91beb4c455ab4cbe669ee9a7f11c633e90f5fabc7b8a7c2644409dad70d889c2/master.m3u8",
      },
    ],
  },
  {
    station: "WSMV",
    scte: "middleman",

    priority: 20,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/c5e26513c319aec005f4b73768bd0026d5d3a8e96373973be412dd8b71fd7e93c78ebafa82a0/master.m3u8",
      },
      {
        distro: "Roku",
        url: "https://roku-dai2-playlistserver.aws.syncbak.com/m2/roku/a14db2865bf61c725e9a5b69632bf7a706993d78b32f5ef6d12a29f4f93235dab24d95fd084f/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/d444d85f0a082067c56bc647a38a8bc61de1d0bc51bd4ac060d6d716efeff9c31c568ba364d6/master.m3u8",
      },
      {
        distro: "Vizio",
        url: "https://dai2-playlistserver.aws.syncbak.com/m2/vizio/3cd1d198b4a8d0ec64582243df02eb4e750d54b029c6e5662d1f9fe4f0fb7d986aaea1eaaac7/master.m3u8",
      },
    ],
  },
  {
    station: "WFSB",
    priority: 21,
    scte: "middleman",

    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/fa8d7cf74cc494b3cb106e5824ee61f91f66ae3e3245ff646f49bb544ff75a12651e2f352c1a/master.m3u8",
      },
      {
        distro: "Roku",
        url: "https://roku-dai2-playlistserver.aws.syncbak.com/m2/roku/11f7c79f49e9814b9b21473e3f261a2c2a55252b1bf1fbbc21f99ddd2be7d66d48baa8dc6240/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/0a7c57afdbac660c165f6e71cafb95abb3caba34eed5f38d7ea923bd0c6e03ba3920c90db45f/master.m3u8",
      },
      {
        distro: "Vizio",
        url: "https://dai2-playlistserver.aws.syncbak.com/m2/vizio/40e9989b5f9df00da1da0f0200d4c1ef6c783853956c986d3f801e0d4c5cb17101d4f8f8207e/master.m3u8",
      },
    ],
  },
  {
    station: "KCTV",
    priority: 22,
    scte: "middleman",

    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/db6981743d4dc9b58b4291843035fcb9776c85e8f45b22624e984291ebf86f1c6c022ef5c9e6/master.m3u8",
      },
      {
        distro: "Roku",
        url: "https://roku-dai2-playlistserver.aws.syncbak.com/m2/roku/af8820aed429508514cd1598beb7a52542157244d1364ccad9bffdb25984aac15fdb2ef8a928/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/86016add3e9eb67b9293ac7ad139f7b7fedcd7dcfbdc3b6bef38567c6836c22d76f0b98bc4b3/master.m3u8",
      },
      {
        distro: "Vizio",
        url: "https://dai2-playlistserver.aws.syncbak.com/m2/vizio/3271ebaee0888a0a90eb476b1c96ab9e21903418386248d6078960832b5313cb8727008397c5/master.m3u8",
      },
    ],
  },
  {
    station: "WHNS",
    priority: 23,
    scte: "middleman",

    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/d22f743c01ecba2cd36a93d539b2a0a31bc987b1c1c52c1800796796160d5d5cc4f20f519d4c/master.m3u8",
      },
      {
        distro: "Roku",
        url: "https://roku-dai2-playlistserver.aws.syncbak.com/m2/roku/cef714fe3b1e91d5857294066cb348b19640ff51efa14c784e8e8cadfd62302d5d0587047047/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/cde7c6a9c6ae0ce1a8c7e05bec6cf96dd1da85f2aacdbeba348094c3eb4eb94e7085fc2123d5/master.m3u8",
      },
      {
        distro: "Vizio",
        url: "https://dai2-playlistserver.aws.syncbak.com/m2/vizio/ed4ce344160f7e143167eed557a1e0b55e3fac4ef218cc1e7033583b51dff8d68ab93cdb6490/master.m3u8",
      },
    ],
  },
  {
    station: "KVVU",
    priority: 24,
    scte: "middleman",

    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/63f48c63283940ec50d030d0d0411ebc58debd52c86c5c0db053c8ba8333cc8e91e07447edfc/master.m3u8",
      },
      {
        distro: "Roku",
        url: "https://roku-dai2-playlistserver.aws.syncbak.com/m2/roku/c667d270b9c7736ea0688710c8ded67dc4370f32e201a479e79032b2620ff22779040f1174f4/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/40717bd58495023cf366f0bd4285939c62bb1680cad3c131c28f409a65fa145e639a6b1edecf/master.m3u8",
      },
      {
        distro: "Vizio",
        url: "https://dai2-playlistserver.aws.syncbak.com/m2/vizio/f235ef1a11d54c063073637e6f548cd994c5cdd40a41859ae1adbc39e9d1efedfc9a21ddabfe/master.m3u8",
      },
    ],
  },
  {
    station: "WALA",
    priority: 25,
    scte: "middleman",

    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/5af261a2b87e33947c78984f2f5b5d6f1f8cdb11c124f981f30dfabeebb828c041688691cacf/master.m3u8",
      },
      {
        distro: "Roku",
        url: "https://roku-dai2-playlistserver.aws.syncbak.com/m2/roku/0d711aaa9ff9590168b8592b4a155ca6464b4d389db8790e932a0d1de1ab4c1c28c3472cf8ee/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/70809391b25b1d24346c6f7df1b6e73471e48fc2d4f4dc0ddb1274a7da06f8da24f953b9e4b1/master.m3u8",
      },
      {
        distro: "Vizio",
        url: "https://dai2-playlistserver.aws.syncbak.com/m2/vizio/7e50df974c5d5b9575966686f97fa6b8e28a4728ec8267a5916cdb08c83ccffe28611226e982/master.m3u8",
      },
    ],
  },
  {
    station: "WOWT",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/449a30788515d0c3a8ff745e87d1a521399eebcb55439df7c39438a7da569efaad63148f9167/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/34d071aacaa9668c3e39e2cc363a28ec350c590b99336d6620f693950bf72b5fa165788da00f/master.m3u8",
      },
    ],
  },
  {
    station: "KGMB",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/1dd38dd5ea29077c3795a5eef0832ee040cebc9a27785d7782a3eaff56fba7d044dcfc79e639/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/7179e414add1a776efbbf7fbcf9757a41b5f9c822f5b87757ae0fc32f1251af60d818a5658e7/master.m3u8",
      },
    ],
  },
  {
    station: "WGGB",
    priority: null,
    scte: "non-tbw",
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/ded1fe5fdf72cc9a04e9153443707c807af9af7bcceb57086d142284d2a74180f63ba9cd5185/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/8cbf538db26e9c19b42bc9ce5a4dbdbd5d9b52549c174c7f10d5192e27967e91ff9b6cb97621/master.m3u8",
      },
    ],
  },
  {
    station: "KAIT",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/1081934cbdea82c65c85e1ad48d0954a312645afbb83339829444ba457b3ccde75408e6f8eb5/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/8dc0f52fb12fc10e169f97808838b7df0c05b8d98e997c77c084f446ca9c61c52c75d01fd3d0/master.m3u8",
      },
    ],
  },
  {
    station: "KALB",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/62da1cdb684fd1070ba367a7388db3d8e0aae0ceddbdf77f134e79bfcb258d6642381dd2b0c2/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/51b106a494be97f7801216a9873f8cc3488b98a34d571b4f6f643d703b72a34c3d56d110ae75/master.m3u8",
      },
    ],
  },
  {
    station: "KBTX",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/8b8bd3d994d0dc957b26c48b20bf447b9f734cafc9917f4e262ea6be67767b291e32fd74039a/master.m3u8",
      },
      {
        distro: "Roku",
        url: "https://roku-dai2-playlistserver.aws.syncbak.com/m2/roku/24ae7b6b6e00c977a8dcc76aa75501de27656e5d9b3f05b885a19473cc58eca7dec0555cb935/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/887d7164bc542e8eab06788c3cd9207bc81446873f9484010a7e9549056eaf9bf92f354732f0/master.m3u8",
      },
    ],
  },
  {
    station: "KCBD",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/f8e84799f9e8e6eb1cb9f0353a2ec840553025d0ebef466041672993e01cc593dd519bccbaf6/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/f1d2573fa13285dec5b89bdbbecbfb3a353aee2b4e9d4c44309fed7c2a011f4701d37e89d93e/master.m3u8",
      },
    ],
  },
  {
    station: "KCRG",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/85b8823789a0ec7fd6865e330ca0ff21af323263e576e40c2dae181a90bcf7663b11df1f4d10/master.m3u8",
      },
      {
        distro: "Roku",
        url: "https://grayroku-dai2-playlistserver.aws.syncbak.com/m/6309cac0974f402a49fa31796dad930c96c884ad1a3a2d75b345c4fa2c32f0d74d6d5f9b8049/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/6bd68b4dc5b030efbcef084ba486109a9edc69212ff22fb1e0918122e7a451fca2bd5bc9b4a9/master.m3u8",
      },
    ],
  },
  {
    station: "KCWY",
    priority: null,
    scte: "non-tbw",
    streams: [],
  },
  {
    station: "KEVN",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/8907acd5b71ef794c32611c4f053ed3d086c399b1e1631da251df3e4460e92e4d306dfd7c782/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/d18dca17f8a80da1cbcc545f1e89b5adc7339dcf3c66f2ee1d326982209a8a0e23d4781d1d59/master.m3u8",
      },
    ],
  },
  {
    station: "KEYC",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/b1c0cfcd9e57af444ae6628a0e50653d7bf631ad06c47450933ced96f3e9ebbb9105dc65fa4d/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/3272c83221fbb8707e1fe8dddbb7e7a531d1c592faee2014daec797ca02b2472bad39913c2a6/master.m3u8",
      },
    ],
  },
  {
    station: "KEYU",
    priority: null,
    streams: [],
  },
  {
    station: "KFDA",
    priority: null,
    note: "KFDA does not stitch 'Local News Live' into their primary stream. They may run LNL on their secondary",
    noteDate: "January 3, 2023",
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/6742a181ecc09ae2b5bbb41f199515d5e519a921ed8d0c444501417dd329d8be20967316f68e/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/b6ed3205aa2d8e9b6a15f0d984b4b17193f2af40ea28e334bbd507b4d9142c4a8b05684fc26b/master.m3u8",
      },
    ],
  },
  {
    station: "KFVS",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/fe11ca2a4986fd33b1133d1e7fe336e86f9a42eed8abd28f3a80afc3282d6c9d3b81d309d401/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/acacf08e30df205f761bb15c9eb8c1e5c6e797de93a0fecce00ebf28ef6cb1e5a7f09bc20d01/master.m3u8",
      },
    ],
  },
  {
    station: "KFYR",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/d6244d75485e47c399df6094ae2bd5cc67bf1f22bdd5503b849fdd58541fa63abe43cdd1910a/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/bcdd6f2d7074afa87a9063b12252929cf7bd8b26c776abffe9e7f601dca9d17e3108f09fed05/master.m3u8",
      },
    ],
  },
  {
    station: "KGNS",
    priority: null,
    streams: [
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/8c61c0518bb795d69c341344e6a875fb3f3cfc985e6ebb93ceb1c959d79a75c1fc6fbf0f14d8/master.m3u8",
      },
    ],
  },
  {
    station: "KGWN",
    priority: null,
    scte: "non-tbw",
    streams: [
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/fee30fd62d2b8d3e6d44ce465033142345922c0da800d94270cc11d6fa3db9dfe394237a18b1/master.m3u8",
      },
    ],
  },
  {
    station: "KJCT",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/b1133c2fa5f6ba197021768b6bbce450d5775021fb23703b087ca761db52b2bdfcdd46b632ca/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/2accfa79429affd966f27dae6364c69453ff8b76813c9e3826a56f1af38de0cabb8b0430bcd2/master.m3u8",
      },
    ],
  },
  {
    station: "KKCO",
    priority: null,
    streams: [
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/7d586392a2b196423a2c26c8a6e597ec9318a84324abff30f0124a2f7819fe4b4dfc0ec7376f/master.m3u8",
      },
    ],
  },
  {
    station: "KKTV",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/81a3943386dd401b3a2ebbab170a1e48ebb47acfd96ddd013fbc843a43a9cc45b26f00de10fa/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/ae4bca2c48e53b262699f6bb11cc65537227b63e4bd63ef205258a3e7edb5e055e1a8fa5b800/master.m3u8",
      },
    ],
  },
  {
    station: "KLTV",
    priority: null,
    note: "KLTV runs a mid-morning, local program from their CTV desk titled 'East Texas Now' which runs ads directly within the stream.  As such, neither ad reporting from SB nor ad break duration from within 'Events Monitor' are accurate.",
    noteDate: "January 3, 2023",
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/4835d6fecf71f93af19a52b824f6749cfeba12f4533a0a6376ceee30c4c51700e200a1987c71/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/9cf5997e1b1aac28463040077fc9584d39c02e6b5c0f2e02290e967a0df457b823500fe368cf/master.m3u8",
      },
    ],
  },
  {
    station: "KMOT",
    priority: null,
    streams: [
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/2f874feeaf42c369aa608bc25c550d87fb8d677b6ec311355ba2bfb9b5d4cea6433247bb982c/master.m3u8",
      },
    ],
  },
  {
    station: "KMVT",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/085ea6f13d1702311bff3d25aefbd8058e9ad580a5894e136df7ed4eb5b6ff192e70ddd86887/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/3c47e3a2b4d5eff6d4086f0c713f1e5088084ea0e93b85e1f9afa872a1ff32b5610a318c3e84/master.m3u8",
      },
    ],
  },
  {
    station: "KNOE",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/c6610979e3096f99f39fb1afcc3a117ac302403ef3df715db652e79ad60e209d355d6d075ccb/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/f9334b57a7636399a73e712aa24e52084e7c69c0392f1786c054048d2deabfc46665fbcc2f60/master.m3u8",
      },
    ],
  },
  {
    station: "KNOP",
    priority: null,
    streams: [
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/a0e1c9c8356e49a34ea08555a2316b5734472d915a93b07521abc9afea61b8e665db65de1be2/master.m3u8",
      },
    ],
  },
  {
    station: "KOLN",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/c588b728da724ccf4d32c2bfe8bf5fe25c705c7f44cbe2cb69615df6613ded616d9f9ba8842f/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/221b1f4954e5575f1a897dae46cc54092b21f9cd9ce025556b7b30246c7bf85a198d435ffca6/master.m3u8",
      },
    ],
  },
  {
    station: "KOLO",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/99986f4f5dfbae1029862cdb24692550d4b8ac58bfec592723a92d9fd9f5796658f1e7f1c366/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/4b2c4f8a1147358883a6e2d75b487c93ee1848fa9cf1119893c95de39b8334d7c4d1a70c9811/master.m3u8",
      },
    ],
  },
  {
    station: "KOSA",
    priority: null,
    streams: [
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/8b6513aec18a0be53d6337f0634a2d8d53e5a1680ce55a368b0f2d94056dc9ffb623ca1df340/master.m3u8",
      },
    ],
  },
  {
    station: "KOTA",
    priority: null,
    streams: [
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/3865f791915268f959999641bb3c6657a324619404e0315d9050b8679ab5680fa3f4ad44f40a/master.m3u8",
      },
    ],
  },
  {
    station: "KPLC",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/2fd35b656394203e86a9c433666d5e2eea6fc8391d4da640ec5ba7c34a86e13a3af12eb174dd/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/fb013406a0d1119a4a742c6e173e2ef2590fd1c601e5b7a095434b94dc2f477292ed770e407c/master.m3u8",
      },
    ],
  },
  {
    station: "KSCW",
    priority: null,
    streams: [
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/a30d5c17fd2fad5b668dc275253d0dcfa0ed727aad9ba402c3621f14f1fcf0d18fb833923462/master.m3u8",
      },
    ],
  },
  {
    station: "KSFY",
    priority: null,
    scte: "non-tbw",
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/d6a66d515fa6619d106c1914978f9109f28b0b69aebdc95136d8e15d4fad38e8f1accd15a25f/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/0d6a3db9225c1db586df2f5fa77ddeb1cf0da30bbe682e4d0de6363e0e66f463576e0d740522/master.m3u8",
      },
    ],
  },
  {
    station: "KSLA",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/ce6c6f5375150a0975d8a1d956702b36df5999870df65c7f5c16a29e1d68bdb57c582e009ac5/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/dac3355027ba9be84da56200c374627c3578fbdb89b1ce22ec29d6f5952070f1f4f240d83b63/master.m3u8",
      },
    ],
  },
  {
    station: "KSNB",
    priority: null,
    streams: [
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/3578361e264ceaff24dd8529f25eb1326ed8d79d59472b92b91d284a23a5aedffba94c060a36/master.m3u8",
      },
    ],
  },
  {
    station: "KSPR",
    priority: null,
    streams: [
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/16e039bb9ed0fe9ac732545795077924c4295b5deb335515d72c0c48fad824beeaf92e48c10f/master.m3u8",
      },
    ],
  },
  {
    station: "KSWO",
    priority: null,
    streams: [
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/81cbe10a288dfc9f9562d9893f559b4ef7a90b0d1958e8ad90f41764be6863250328b8e5cd4b/master.m3u8",
      },
    ],
  },
  {
    station: "KTIV",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/e7710e1cd6f851efa4ac4a6d6adb7db232da5428cc9bd3b025d8651493c053c5b791ca65eae5/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/6fdf71c3e93a447142580bc6523825e7feffaf52c8bf2881a63bd8f3b6c85f6c961611f69c9e/master.m3u8",
      },
    ],
  },
  {
    station: "KTRE",
    priority: null,
    streams: [
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/0f52c1d5d90aa085063f5f30752ea929a5dd37d20ee742d27a08ed37e3c65c01ef4618f1558b/master.m3u8",
      },
    ],
  },
  {
    station: "KTTC",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/448b705d40b8992d802e49c6bd418838c46a05c97f59be15d26fe4c5d778cf9935ef473f74cb/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/6a1590be37b96a89dcdf9617e11693d516c23e8a2331e5aee37f9076b02d71ef1064a8fae9c6/master.m3u8",
      },
    ],
  },
  {
    station: "KTUU",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/d42517e7591c0ca946a28287b8178db35838425db85e88be39588aea3577403e3406d4d128c7/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/907716ef06ab782cc76068484aa1585e8ba68f6c3150299c5a4b9cfa4a5fcd89e333ca3e8dde/master.m3u8",
      },
    ],
  },
  {
    station: "KTVF",
    priority: null,
    streams: [
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/820bf7de139011571bf699d7bf1e18e1fdad45ce5945e7fce22f4f230c271cce95677b7c1b3d/master.m3u8",
      },
    ],
  },
  {
    station: "KVLY",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/ecc5f930daebb7e0a9dec6eb410b00c9e10b331c12e07f2a5aea3674a0c4d785b6f8783c5118/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/ed2d9510f023537598985ee14e3a3da69da7805fc7b2a3ecfe213f6a423f330a65db25865bfe/master.m3u8",
      },
    ],
  },
  {
    station: "KWQC",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/ec262006ceb674e6e26099b0aecca231423fe4c40522fe71be03c0ee4379691883aeb1234511/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/5893049443d8c9271da75ac1fb8ccb68e55f6b414ecec120d33b9271276e5d0ffa90ed9f179c/master.m3u8",
      },
    ],
  },
  {
    station: "KWTX",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/42e413624e4955ed61a3ca6da78abeefd8ceafaa59a5087a791e1fad9c81348319cf9742c7a8/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/f5d70783e9d296b969191f76a7915bc1b3ad51b72a6762b50335283ca1c0eba860ed54fab839/master.m3u8",
      },
    ],
  },
  {
    station: "KXDF",
    priority: null,
    streams: [
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/819924a5f7936ad9f90586fcb9b8a1985f557c558569c601a16c7a54162167684eb4b601c0a4/master.m3u8",
      },
    ],
  },
  {
    station: "KXII",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/a44d25cac94945adaa52965091c07dff5e9efa9a8220cb8b0ad012952cbb9bd46d026d4a189f/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/ff4d05db843ca86feaa3de303072baee3344b21538e1fee4ee3ab1b0078ad897db50f950e860/master.m3u8",
      },
    ],
  },
  {
    station: "KXLT",
    priority: null,
    streams: [
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/1b0bfdf500c1870eea315867ef621a12772d4bb737f76f3af51ea7e4e789b41e2110ff3c9584/master.m3u8",
      },
    ],
  },
  {
    station: "KYTV",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/a92ddcd72903cbe7681df5f9de2264fe72b1b404f9d0063cd6ceff76ba7523162675f97d1ea5/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/c908cb8057a5acd0b8fc9f8530a37d707999cb6ce87b843e170ed68d339e8e847104161162a1/master.m3u8",
      },
    ],
  },
  {
    station: "WZAW",
    priority: null,
    streams: [
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/dcd24180496d20de2e0f694e6b0652f3528e89cec62fe5d9dd91c8f48625c19954778c52b2cb/master.m3u8",
      },
    ],
  },
  {
    station: "WABI",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/8c44c0db13a6801b5d0b1b42e2c63efdf447ce7566e5c284f18bc622a723a336bf08391dd6b6/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/2549541ab02c9585b072102092e33030e6d97c3be9af14899ee7c60446e6e825cdd22110c517/master.m3u8",
      },
    ],
  },
  {
    station: "WAFB",
    priority: null,

    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/5c73950bfe8aec0272994d737ac496d97af0ab571e189236407a1a0fe4a40b4e097493e0d58e/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/c72378ef1b097dcf9d8b68cc0c5a5c50c587a691167fa93b5cbe94b2a1aec34bc8d4a40733ec/master.m3u8",
      },
    ],
  },
  {
    station: "WAFF",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/98ac55c378d3f75f1f8177e563f5ec7d62e57e12f1887c380d50680e7e6e7adff278bad440bb/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/c49803788730c9cd2960e58e83ec54b85eb543a01fd6ce0cc325a473af31bf5ccf066e2aca82/master.m3u8",
      },
    ],
  },
  {
    station: "WAGM",
    priority: null,
    streams: [
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/38940a7ed485634cf9121f2263c2fc4963402eb5ec67c48f5a725d72a379fcd0da3495470645/master.m3u8",
      },
    ],
  },
  {
    station: "WAGT",
    priority: null,
    streams: [
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/72b93b7ab04017a3f5fb25c575557791a2ffadf4b8e8ca40565971a72ad5002a2ffb3ccbdb56/master.m3u8",
      },
    ],
  },
  {
    station: "WALB",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/eea152f307b4befc6c4f4589ed57b823d6dcb7fa520d12c687946eb2f2af288b3a987d9e8eaf/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/52c13f2912bd20a776f0f6576dd1bf96266ffd07e27b13454d32667d8c21feabb31ef8ce4947/master.m3u8",
      },
    ],
  },
  {
    station: "WBKO",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/76a90df77d5fe93985e48d4425ab2fa54f8c3890453d0a0dd768c9a5b2c3ffdc3a3ef2de7a7a/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/2eddd64336b06f9d623b81e75f45390bf4d497b0b0184bd72eb72f9f4cd77d21827d2d46fdc1/master.m3u8",
      },
    ],
  },
  {
    station: "WBNG",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/9d78305944d9bfda3d6931e8b0e40288dc7f14061b0800823c4edf2aaa39fae705b205d9deb7/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/c6ae7570758ac9e05a429e56913beecc3bb6605f32317d3e18a3535a6d641225f81a2ab83677/master.m3u8",
      },
    ],
  },
  {
    station: "WCAX",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/85b4905944882ab77af560664e9c2c1c0d6c420c5926799303b7601e162de422c9c1276105b1/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/e83079767a8a19c6635e8ef82ea92eb5eb5c782f54b8d7e51591dcc340237481a7406eec4dc8/master.m3u8",
      },
    ],
  },
  {
    station: "WCJB",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/7aa2e401c34882c5d75aa55cc837c3955706eff69e420f56a61553e0f6a33fdce3c03810a6d0/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/7a4abdd7f56c4dc1555c79635ab08877e9c4f2f16db48834f96516f8aea31ab674da2a802332/master.m3u8",
      },
    ],
  },
  {
    station: "WCSC",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/938bce834ffd8a7faf94b3157f1e306bced42b8be930701b3bd9d00e0febd0ff23f1a2a38abf/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/eb4d24d9ef80bdbf760af9f566a408fbebe6e91d5acbb38b211e3212772633bec5c2d1ac0361/master.m3u8",
      },
    ],
  },
  {
    station: "WCTV",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/df851b54054f47d93db10dccd607efa6071657b9dfd1d93f5a4abded0ac3f718a2b1b8e89583/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/9108fbb2d7317539e360edf8e86ec46e6f95d8a107766346045e542a4d1e2e421c78dd55fe76/master.m3u8",
      },
    ],
  },
  {
    station: "WDAM",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/aadbb41eed72460111af2040816c40f48d591e0eb396fbb3e5dece0ab7610c0f4963bdaa3d9e/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/78375fb4dec63c25b55cc60cd5368589acaa5878493e1b2f2ebe60b9223f9680bc4b2d69db31/master.m3u8",
      },
    ],
  },
  {
    station: "WDTV",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/302b614e8cc6521eacc66935775259c8e8875947f44abd26d797d0be26ff32a07879d209dd6b/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/35269f0fa2c0b75cb29449cd623ec108598574f4ae4df1d9ab655e5db9f433982d1d6e34c450/master.m3u8",
      },
    ],
  },
  {
    station: "WEAU",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/969741b1a8ed69d2a5df38af4161139034dcb568dc65adc99b7f685c441333c43a7feb505237/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/71d2894919e9bbb9d21aea8d8f547abd075ef94aacc527fb948475ac177f199ddf7299a52d32/master.m3u8",
      },
    ],
  },
  {
    station: "WECP",
    priority: null,
    streams: [
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/200c9bf39ebab9b4ee154355c735a45b30baccdd1f9e7842918732ca8609603535e7943ac7d1/master.m3u8",
      },
    ],
  },
  {
    station: "WECT",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/1c54f46366368055e96b40575f01b2fdb931e0edded942f22299e0d8130e9e44764e820348d8/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/e7468a35f3eeb786bfafadffe97b7d09e3c3e0b54eb0fe28e0af0bc900a32fa3b90d652480f6/master.m3u8",
      },
    ],
  },
  {
    station: "WEEK",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/2e3f535b1519209e373bf88c5dae9a16843e3cb08b4b1ef7b365a0add9439ef67d65782b5d9f/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/954e9c5db75fbe8d53eae8a7a92e7976f423e8bcc259be722b5d5a92daf789816b90706495a3/master.m3u8",
      },
    ],
  },
  {
    station: "WEEK DT2",
    priority: null,
    streams: [
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/67764824587e47c6f785ebdae992877f20e088172dc762242205240c5c6570d13ffddc67516a/master.m3u8",
      },
    ],
  },
  {
    station: "WFIE",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/c3bcf28e1de44bb485b814fc8a5590241cc816ed687402550b8ffe1d68aee24ef02f17c1fa8c/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/b686cf3c4e9ad99c108521e4f4ee1a8a565fc9266555667a2eaedc6ce3b2d1d71abce433c9da/master.m3u8",
      },
    ],
  },
  {
    station: "WFLX",
    priority: null,
    scte: "non-tbw",
    ssa: true,
    streams: [
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/83d2d240c35c5edccb67bc358b7cef8d46eaf4b28c7b5a75ea6accfcbb0477042e7b772baf44/master.m3u8",
      },
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/6ba1b39c6b2a6d8a280681d0b8e3b1a93de9cd15012b1470f6885b625c084104b4c8ceca88db/master.m3u8",
      },
    ],
  },
  {
    station: "WGEM",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/57c7a5dd785c8d1dfb52cdba1ba319ccd1d27ed06c8ad06fea8eaf6d21c6f57425ce30661db3/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/77c81262e3f81607355177741e9b84bf96e0224171e7e30bd3f0d2d82c91054c82652c664102/master.m3u8",
      },
    ],
  },
  {
    station: "WHSV",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/8d3b2b6000eccead97fad982c1dbf533f3f83c212ce0e55530baa9a37e286d9839b9e6832d90/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/e06781dfb365208c2ff9a05badc3d175f8000df4bf7d601d3e0e005031f5040eaaae6749e187/master.m3u8",
      },
    ],
  },
  {
    station: "WIBW",
    priority: null,
    streams: [
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/71676f2f5841b1b584124df2d9f4b15bd7253165062353253ca842a3f5277b9d0167cadb16a1/master.m3u8",
      },
    ],
  },
  {
    station: "WIFR",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/965942b5c63298fdd960a9a20803e18f0f7e3c79952b7fa297a24d376afd1b26c5f5807dbc47/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/696313d047cb72e3cbf90909851cc7e4a9f64a05186e9825a96aeb107024d7c603d3ce58131f/master.m3u8",
      },
    ],
  },
  {
    station: "WILX",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/ede3156d92b4f3f30b3ac2a8234c226b8c79aa5ae3084fc4e9d978c2f2f34da95a7bf8e5b9fa/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/3ae24de2956224e40f828103f30789d32cac72968637ab03d94c0261b28ee0978aa15a76f2cd/master.m3u8",
      },
    ],
  },
  {
    station: "WIS",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/878fa4354b1225561a41297383478c7bfe208870847da19ada41b6443ee8b7ec217ebf4af20c/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/251fad40145455454abefb7691ac8cbc296a1eb2da06cfc41e2c7d2bc45751715302b5ad6ac6/master.m3u8",
      },
    ],
  },
  {
    station: "WITN",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/4521559cd373046d250b9dd048002e75746ebbb06844f1805f02e07229f5130b5b9989659097/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/57b91fa7b028b4c4284b7d2f7e4840255905551de703d0ff0957a6caf9cbd445f9e68c6d7e01/master.m3u8",
      },
    ],
  },
  {
    station: "WJHG",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/de9b1d5b8766861c332de3557a0cd6eb008fe9d77f5f380f39926dd12e30e75b9135abac051e/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/930cc37f26c899eea67a498a9b975fb68eee8184e94862fc3a032d3ea081f0698a0636dc99ef/master.m3u8",
      },
    ],
  },
  {
    station: "WLBT",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/818d90ec00517af4c8745630f448812a28b5aa7f57281d38c62cde8ba495e5fffdfae0fc3824/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/84b8ef4b2df1993d50a3c56c50250e3cadfc5735ee4594f6a743204e2e2c789ea76eb5c75bdf/master.m3u8",
      },
    ],
  },
  {
    station: "WLOX",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/f162e2be88e345f26e32dec47eb1475c7b277c09253f4c719f55873075d96059b3aca4c8d627/master.m3u8",
      },
      {
        distro: "Roku",
        url: "https://roku-dai2-playlistserver.aws.syncbak.com/m2/roku/8305da0800d09849c1ea2c25be9a4d982b5654d415fc9dea701fbfbd6868c9a5ba7a7b9a8e0a/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/5ad136206da8f712aec34c8250ae2afd59e57aab53cc8a4dad23d19472002a5d7c25d2a62215/master.m3u8",
      },
    ],
  },
  {
    station: "WLUC",
    priority: null,
    streams: [
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/f23ee9b928f7bef075a144420823030d956889a5427b514b6d5edb19cfcbfdd1d0eb46e939f8/master.m3u8",
      },
    ],
  },
  {
    station: "WMBF",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/267c9714a2c35ff5cfde6b6526b2ceb17196669cd5353d3f2d36e4b78a6690860184b2e366d4/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/d9fec207e3175e83b2f5985d946efbdf4380f4cae1705e5134f7cdfba4298f9ee9adfd5e2c5a/master.m3u8",
      },
    ],
  },
  {
    station: "WMTV",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/18850baa3db27717b7f682e85bd86dbbb939898d652fcf97c23b25f927feb1649890027536ed/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/a07f00d261cfbb87edb36a60006351970d8f5e17e74eb5d445713843b8f713333ef93fd61185/master.m3u8",
      },
    ],
  },
  {
    station: "WNDU",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/b72e7f599906ce4201abfad590956b7f480ae9d7f11a0503851c961dc58430142559b567538a/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/99808626d81161d96092d0070f06203ebf8809ed37efd67b3713a502ca2bb0ba5f3119cd7319/master.m3u8",
      },
    ],
  },
  {
    station: "WNEM",
    priority: null,
    scte: "non-tbw",
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/bd5ba68213b43ca2559dc9b73f0ceaff527813451ddc6326e9424831c1ba41bd28c853b1a659/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/f567b118329e4cf6fa8dfd613e297559951d7045c932c7f166062bb82ecadc7aef1c21f887f3/master.m3u8",
      },
    ],
  },
  {
    station: "WOVA",
    priority: null,
    streams: [
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/5bc17dae65738cd8974e13b46b81366d403638b50c0800eee8a868001b7b660066f48d30db69/master.m3u8",
      },
    ],
  },
  {
    station: "WPTA",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/0296e83a393a417b018440c5cbaa164d5a6dc37300a28fd141f06ceafd1a71d57e0baec428aa/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/0484251a3cc7d2c59a330fc32da5ce1d3fdf0289fdef86f68ac56e1b6393b44dc24bec90e764/master.m3u8",
      },
    ],
  },
  {
    station: "WPTA-DT2",
    priority: null,
    streams: [
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/3beae10aa2920ca9e5a09ea10cf585ce98c8662951398cebdd4165fbcc978f6bdd0c6b006f1f/master.m3u8",
      },
    ],
  },
  {
    station: "WQCW",
    priority: null,
    streams: [],
  },
  {
    station: "WRDW",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/5527b81ed9f96b4429939101b7c265ac5529c6dfafa441d474ac9d535af32918e2c4058d7d93/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/0bcdce61dabf024fadd4d1dc44e977228a29ffe81de65c171cd8fedd83aabb1f8c7a7a78d49a/master.m3u8",
      },
    ],
  },
  {
    station: "WRGX",
    priority: null,
    streams: [
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/ebcfc892aa66cf2aebd9d0da6d4850d4c25bb7128578136234081a65fbbc504fb7f146b769bc/master.m3u8",
      },
    ],
  },
  {
    station: "WSAW",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/5cb0c6d9d3b02232f54b864da406e7bb19898ff600f209e8ab0c066e4e38f879f9950f7f1e61/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/d6e81ee8e652a732bcac03b9df8f97a1853bb0474fb7b55b4f84d33df89ca90e5935266c0493/master.m3u8",
      },
    ],
  },
  {
    station: "WSAZ",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/686dacb82bb467c10ebfc35d18921cae5a2771eab67f99ec63a81f807c5e519859f2d05cd4ca/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/24b8201be4c4c682555cb0aef34ae667001c7e7d555c79bf1d27c61df929c9fc4a8b4b05ade4/master.m3u8",
      },
    ],
  },
  {
    station: "WSFA",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/43236c4cb281400305d890c8720cff01d0b47fb8051b8933290cdc54391d39af1f7e8a36f082/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/88c31c6860e7844711a1f678d0bfb5d51713b5836b2d8f3eedc00f0f9ba74d2fa4edbeb62f3c/master.m3u8",
      },
    ],
  },
  {
    station: "WTAP",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/10ca674a3b8640d69fa796349ec43f62fa24c78c78bfdbb01dd84d2c3a04dd073d98f67756a1/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/15ca6760a3408b8878ad99330c01d46c488f832e90e0507c0b2fb0a48d3af30154ea9c71d262/master.m3u8",
      },
    ],
  },
  {
    station: "WTOC",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/f06b13f3e4132409ac748ce5c5ef427d57d29465204988a8be2535592c4356e3ce559c8e4bc4/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/eaaba56fb120f3fe098e2dc6c6ec9896045f7fa503f0a5b0851705297594db82998cd9cff26e/master.m3u8",
      },
    ],
  },
  {
    station: "WTOK",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/ed400a258ddabdf60c819bf4b261df1b25db6642d7b289fc3fb6e7e9e27243e3a66d5b322546/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/451f8bb3fe31e7a598ac9f7678cf2ad3092006b1026cbc820e06ed4ef3c2a643c23fa2549c0d/master.m3u8",
      },
    ],
  },
  {
    station: "WTVG",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/3eca2cab7ad2b8edce4288bd3e72ce465d05fc1e4a1883e7b6268ea802d4cbee4b9f1f282e3b/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/aa91c42b2dc55e67d2b4a0d46ccb2ef43ae820ada99aa8fdf5fea8877ebe609817686cab06cf/master.m3u8",
      },
    ],
  },
  {
    station: "WTVG",
    notes: "Duplicate or Type?",
    priority: null,
    streams: [
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/99a823c3f048f2f51d712c8315530ffc4a2aff050b8876d2b85c96f9d09e8c62d7679fb707f7/master.m3u8",
      },
    ],
  },
  {
    station: "WTVY",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/579f026ad07de1df6a8fd6112c8c41d425ddf77e99be2d67888653e29d0f0f41b33973f4c118/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/17b8f8d69619ef7a1f75153a7eb19987c41146cea744043db86fed542e256850d64912709edb/master.m3u8",
      },
    ],
  },
  {
    station: "WTVM",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/b57b98876cc16539f486ced2c61d87e7d934e7de4904c9b773c598617e93bd2bee7d67b29855/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/99a823c3f048f2f51d712c8315530ffc4a2aff050b8876d2b85c96f9d09e8c62d7679fb707f7/master.m3u8",
      },
    ],
  },
  {
    station: "WVIR",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/0b923a8ae310fc87a257ad5adbb0b0e1cfbdd4f691d9a6035c4a18411e09b7485d22a4c58267/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/00f8c9f1a2a13c058601de1e216c71ebe52cd401cc416e72c7b0041bb19daf9934f010420615/master.m3u8",
      },
    ],
  },
  {
    station: "WVVA",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/04424fa15b6809b636c9660419509303d0e88e690ba4af56a5bfbac0cb9fd72ce7e4db6217d5/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/3afc28971a53cfb701781a6f148069a5a735ec466e77f88cbd6fdbc435bb04c4f3cbe9331e67/master.m3u8",
      },
    ],
  },
  {
    station: "WWNY",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/de17d86085a4b573bdeef834ad77873965e906f06817c1ee5430c413a45016a64f972876fb78/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/949fbf8e57e76b0790896af7d3ab62588827e33fea3d55db3968a031db836b6646d174952198/master.m3u8",
      },
    ],
  },
  {
    station: "WYMT",
    priority: null,
    streams: [
      {
        distro: "Amazon",
        url: "https://amazon-dai2-playlistserver.aws.syncbak.com/m2/amazon/b768a420886bb471bb2a8bdb8f4c9a3740f2b57e4c7d3a65384bfc8751000d25ef44814cf888/master.m3u8",
      },
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/81ed63784c1764ccac5f8d46c495f5a99e4ffba835bb458823cbee801499ee2ff1157da4c793/master.m3u8",
      },
    ],
  },
  {
    station: "WZAW",
    priority: null,
    streams: [
      {
        distro: "Samsung",
        url: "https://samsung-dai2-playlistserver.aws.syncbak.com/m/dcd24180496d20de2e0f694e6b0652f3528e89cec62fe5d9dd91c8f48625c19954778c52b2cb/master.m3u8",
      },
    ],
  },
];
