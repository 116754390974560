// React Components
import React, { memo } from "react";

// MUI Components
import { Clear } from "@mui/icons-material";
import { Box, IconButton, InputAdornment, OutlinedInput } from "@mui/material";

const SearchBox = ({ show, search, onSearch }) => {
  return (
    <Box className="station___navigation_search" sx={{ display: show ? "flex" : "none !important" }}>
      <OutlinedInput
        className="station___navigation_input"
        size="small"
        placeholder="Station, Affiliate or Location"
        value={search ? search : ""}
        onChange={(e) => onSearch(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            {search && (
              <IconButton aria-label="Clear search" onClick={(e) => onSearch(null)} onMouseDown={(e) => onSearch(null)} edge="end">
                <Clear />
              </IconButton>
            )}
          </InputAdornment>
        }
      />
    </Box>
  );
};

export default memo(SearchBox);
