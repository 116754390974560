import React, { useContext } from "react";

// Material UI Components
import { CellTower, KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { Box, List } from "@mui/material";

// Gray TV Components
import { Application } from "../../../../context/Application";
import RowSkeleton from "./RowSkeleton";
import RowItem from "./RowItem";

const StationsSection = ({ stations, handleSelect, handleSetFavorites }) => {
  const global = useContext(Application);

  const handleStationsCollapsed = () =>
    global.setAppUI({ ...global.appUI, showMobile: !global.appUI.showMobile, showStations: !global.appUI.showStations, pinNav: false });

  const handleStationsToggle = () => {
    global.setAppUI({ ...global.appUI, showStations: !global.appUI.showStations });
  };

  return (
    <React.Fragment>
      <Box className="station___navigation_parent" onClick={() => (global.appUI.showMobile ? handleStationsToggle() : handleStationsCollapsed())}>
        <CellTower className="home___icon" sx={{ height: "1rem" }} />
        {global.appUI.showMobile && (
          <>
            <Box sx={{ flexGrow: 2 }}>Stations</Box>
            {global.appUI.showStations ? <KeyboardArrowDown className="active" /> : <KeyboardArrowRight className="inactive" />}
          </>
        )}
      </Box>
      <List className="active" sx={{ display: global.appUI.showStations && global.appUI.showMobile ? "block" : "none" }}>
        {stations && global.favoriteList
          ? stations.map((val, key) => {
              return <RowItem key={key} data={val} onSelect={handleSelect} onFavorite={handleSetFavorites} favorites={global.favoriteList} />;
            })
          : [...Array(10).keys()].map((val) => {
              return <RowSkeleton key={val} />;
            })}
      </List>
    </React.Fragment>
  );
};

export default StationsSection;
