import React, { useContext, useEffect, useState } from "react";
import ReactGa4 from "react-ga4";
import moment from "moment";

/* MUI Components */
import { Alert, Box, Divider, Slide, Snackbar, Tooltip, Typography } from "@mui/material";
import { blue, green, orange } from "@mui/material/colors";
import { Check, North, South } from "@mui/icons-material";

/* Gray Components & Imports */
import { getEventLogs } from "../../utilities/getEventLogs";
import { Application } from "../../context/Application";
import { getTz } from "../../utilities/utility";
import { useNavigate } from "react-router-dom";

import StreamDetails from "../eventMonitor/subcomponents/StreamDetails";
import Footnotes from "../eventMonitor/subcomponents/Footnotes";
import StreamDateRange from "../eventMonitor/StreamDateRange";
import AdminPanel from "../streamsAdmin/AdminPanel";

const EventsTimeline = ({ loading, setLoading }) => {
  const global = useContext(Application);
  const goto = useNavigate();

  const [filteredResponse, setFilteredResponse] = useState(null);
  const [totalDuration, setTotalDuration] = useState(0);

  const [showAdmin, setShowAdmin] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(false);

  // Hooks
  useEffect(() => {
    global.setAppUI((prev) => ({ ...prev, helpTopic: "reports" }));

    if (global.activeStation) {
      document.title = global.activeStream.ssname + " | " + global.activeStation.station.toUpperCase() + " Event Timeline | SyncMon | Gray Digital";
      ReactGa4.send({ hitType: "pageview" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [global.activeStation.station, global.activeStream]);

  /* Get Logs on load */
  useEffect(() => {
    const abortController = new AbortController();

    if (global.appUI.refresh >= 120) {
      global.setAppUI((prev) => ({ ...prev, refresh: 120 }));
    }

    getEventLogs({
      startDate: global.appDates.start,
      endDate: global.appDates.end,
      timezone: global.appDates.timezone,
      stream: global.activeStream,
      abortController: abortController,
    })
      .then((response) => {
        if ("error" in response) {
          setFilteredResponse(null);
          setTotalDuration(0);

          global.setAppUI((prev) => ({ ...prev, error: "Error fetching logs from Zeam API " }));

          goto("/view/" + global.activeStation.station.toLowerCase());
        } else {
          console.log("  - Update Event Logs: ", response.filteredResponse.length, "events");
          setFilteredResponse(response.filteredResponse.reverse());
          setTotalDuration(response.totalDuration);

          global.setAppUI((prev) => ({ ...prev, error: response.errorMsg }));
        }
        setLoading(false);
        setUpdateStatus(true);
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          console.log("   - Fetch aborted:", error.message);
        } else {
          console.log("   - Fetch problem:", error.message);
        }

        setFilteredResponse(null);
        setTotalDuration(0);

        global.setAppUI((prev) => ({ ...prev, error: "Aborted: Error fetching logs from Zeam API " }));
      });

    return () => abortController.abort();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [global.activeStream.ssid, global.appDates.start, global.appDates.end]);

  function getColor({ type, isBegin }) {
    switch (type) {
      case 0:
        return isBegin === true
          ? { title: "Ad Break Cue-Out", color: green[500], height: "8.5rem" }
          : { title: "Ad Break Cue-In", color: green[900], height: "8.5rem" };
      case 1:
        return {
          title: "Blackout / LNL",
          color: orange[500],
          height: "8rem",
        };
      case 2:
        return { title: "Streaming", color: blue[500], height: "9rem" };
      default:
        return { title: "Unknown", color: "black", height: "5rem" };
    }
  }

  const handleCloseAlert = () => {
    global.setAppUI((prev) => ({ ...prev, error: null }));
  };

  function SlideTransition(props) {
    return <Slide {...props} direction="right" />;
  }

  return (
    <Box className="eventlogs___wrapper" sx={{ display: "flex", flexDirection: "row", gap: "0", flexGrow: 2 }}>
      <StreamDetails v={global.activeStream} showAdmin={showAdmin} setShowAdmin={setShowAdmin} />
      {global.appUI.error && (
        <Alert severity="error" className="error" onClose={() => handleCloseAlert()}>
          {global.appUI.error}
        </Alert>
      )}

      {showAdmin ? (
        <AdminPanel v={global.activeStream} />
      ) : (
        <Box className="eventlogs___container" component="main" sx={{}}>
          <StreamDateRange />

          <Snackbar open={updateStatus} autoHideDuration={10000} onClose={() => setUpdateStatus(false)} TransitionComponent={SlideTransition}>
            <Alert icon={<Check fontSize="inherit" />} severity="success">
              <b>UPDATED</b>: "Event Timeline" has been refreshed to reflect the newest event data for {global.activeStation.station.toUpperCase()}
            </Alert>
          </Snackbar>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "stetch",
              alignItems: "center",
              flex: "auto 2 2",
              background: "#fff",
              borderRadius: "0.5rem",
              padding: "0 1rem",
              boxShadow: "rgba(6, 26, 54, 0.16) 0 0.25rem 1rem",
            }}
          >
            <Typography sx={{ textAlign: "center", padding: "2rem 0 0 ", flexGrow: 2 }}>
              A linear view of <b>{global.activeStream.friendly}</b> stream events for high-level review against established norms &amp; expectations
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                margin: "0 0 1rem 1rem",
                alignContent: "center",
                width: "100%",
              }}
            >
              <South />
              <Typography>{moment.tz(global.appDates.start, getTz(global.appDates.timezone)).format("ddd, MMM Do YYYY @ h:mm:ss a z")}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row-reverse",
                flexWrap: "wrap",
                justifyContent: "center",
                borderRadius: "0.25rem",
                overflow: "hidden",
                alignItems: "center",
                width: "100%",
              }}
            >
              {filteredResponse &&
                filteredResponse.map((val, key) => {
                  return (
                    <React.Fragment key={key}>
                      {val.IsBegin === true && (
                        <Tooltip
                          key={key}
                          title=<Box>
                            <Typography sx={{ textAlign: "center" }}>
                              {
                                getColor({
                                  type: val.EventType,
                                  isBegin: val.IsBegin,
                                }).title
                              }
                            </Typography>
                            <Divider sx={{ background: "white", margin: "0.25rem 0 0.5rem" }} />
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                minWidth: "12rem",
                              }}
                            >
                              <Typography variant="subtitle2">Date:</Typography>
                              <Typography variant="subtitle2">
                                {moment.tz(val.Time, getTz(global.appDates.timezone)).format("MMMM Do YYYY")}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <Typography variant="subtitle2">Time:</Typography>
                              <Typography variant="subtitle2">
                                {moment.tz(val.Time, getTz(global.appDates.timezone)).format("h:mm:ss a z")}
                              </Typography>
                            </Box>
                            <Typography variant="subtitle2"></Typography>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <Typography variant="subtitle2">Duration:</Typography>
                              <Typography variant="subtitle2">{val.duration}</Typography>
                            </Box>
                          </Box>
                          arrow
                          placement="top"
                        >
                          <Box
                            data-type={val.EventType}
                            data-isbegin={val.IsBegin}
                            data-duration={val.EstimatedDuration}
                            sx={{
                              height: getColor({
                                type: val.EventType,
                                isBegin: val.IsBegin,
                              }).height,
                              width: ((val.EstimatedDuration / totalDuration) * 1000) / 10 + "%",
                              background: getColor({
                                type: val.EventType,
                                isBegin: val.IsBegin,
                              }).color,
                              borderRadius: "0.5rem",
                              overflow: "hidden",
                              outline: "0.25px solid #fff",
                              boxSizing: "border-box",
                            }}
                          ></Box>
                        </Tooltip>
                      )}
                    </React.Fragment>
                  );
                })}
            </Box>
            <Box
              sx={{
                margin: "1rem 1rem 0 0",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignContent: "center",
                width: "100%",
                flexGrow: 2,
              }}
            >
              <Typography>{moment.tz(global.appDates.end, getTz(global.appDates.timezone)).format("ddd, MMM Do YYYY @ h:mm:ss a z")}</Typography>
              <North />
            </Box>
          </Box>
          <Footnotes />
          <Typography variant="subtitle2" style={{ fontStyle: "italic", color: "#666", fontSize: "12px" }}>
            <ol start={4} style={{ margin: 0, padding: "0 1rem" }}>
              <li>
                Event Timeline should be used as a broad overview of a stream's behavior. Events overlapping the start and end datetimes may resulting
                in gaps in the timeline. The larger the duration of those overlapping events, the larger the gaps may appear. Gaps will appear on the
                left and right of the timeline.
              </li>
            </ol>
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default EventsTimeline;
