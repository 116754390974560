// React Components
import React, { memo, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactGa4 from "react-ga4";

// DataStore Components
import { DataStore } from "@aws-amplify/datastore";
import { Streams } from "../../models";

import { API, graphqlOperation } from "aws-amplify";
import { updateUsers } from "../../graphql/mutations";
import { listUsers } from "../../graphql/queries";

// Material UI Components
import { Box, Skeleton, Switch, Tooltip, Typography } from "@mui/material";
import { HelpOutline } from "@mui/icons-material";

// Gray TV Components
import { isHidden, sortMVPD } from "../../utilities/utility";
import { Application } from "../../context/Application";

import RowItem from "./includes/EventsSidebar/RowItem";

// Gray Assets
import "../../_styles/SecondarySidebars.scss";

const EventsSidebar = ({ loading, setLoading /*, selectedStream*/ }) => {
  const global = useContext(Application);
  const goto = useNavigate();

  // Set States
  const [showGPIs, setShowGPIs] = useState(false);
  const [showHidden, setShowHidden] = useState(false);
  const [streamMeta, setStreamMeta] = useState(null);
  const [localLoading, setLocalLoading] = useState(true);

  // New States
  const [user, setUser] = useState(null);
  const [hasStreams, setHasStreams] = useState(null);
  const [localStations, setLocalStations] = useState(null);

  // Set Show / Hide GPIs
  const toggleGPIs = async () => {
    let gpiState = !showGPIs;

    // Update Database for User
    if (!user) return;

    try {
      const updateResponse = await API.graphql(graphqlOperation(updateUsers, { input: { id: user.id, showGPI: gpiState, _version: user._version } }));
      const updatedUser = updateResponse?.data?.updateUsers;

      if (updatedUser) {
        setUser(updatedUser);
        setShowGPIs(gpiState);
        console.log("   - GPIs Updated: ", updatedUser?.showGPI);
      }
    } catch (error) {
      console.log("   - Error Updating GPIs: ", error);
      global.setAppUI((prev) => ({ ...prev, error: "Error Updating User Config: " + error }));
    }

    ReactGa4.event({
      category: "ui",
      action: "ui_view",
      label: `showGPI_${gpiState ? "show" : "hide"}`,
    });
  };

  // Set Show Hidden Streams
  const toggleHidden = async () => {
    let hiddenState = !showHidden;

    // Update Database for User
    if (!user) return;

    try {
      const updateResponse = await API.graphql(
        graphqlOperation(updateUsers, { input: { id: user.id, showHidden: hiddenState, _version: user._version } })
      );
      const updatedUser = updateResponse?.data?.updateUsers;

      if (updatedUser) {
        setUser(updatedUser);
        setShowHidden(hiddenState);
        console.log("   - Hidden Updated: ", updatedUser?.showHidden);
      }
    } catch (error) {
      console.log("   - Error Updating showHidden: ", error);
      global.setAppUI((prev) => ({ ...prev, error: "Error Updating User Config: " + error }));
    }

    // Track the event using ReactGa4
    ReactGa4.event({
      category: "ui",
      action: "ui_view",
      label: `showHidden_${hiddenState ? "show" : "hide"}`,
    });
  };

  /* Set Selected Stream in Global Context & Set URL Path */
  const selectStream = (box, tz, page = "/view/") => {
    setLoading(true);

    // Update the timezone & activate the stream
    global.setAppDates((prev) => ({ ...prev, timezone: tz }));
    global.setActiveStream(box);

    // Construct URL Path and Navigate
    const stationPath = global.activeStation.station.toLowerCase();
    goto(`${page}${stationPath}/${box.ssid}`);
  };

  /* Run useEffects */
  useEffect(() => {
    global.setAppUI((prev) => ({ ...prev, helpTopic: "dashboard" }));

    const getUserConfig = async () => {
      try {
        const response = await API.graphql(graphqlOperation(listUsers, { filter: { email: { eq: global.appUser.email } } }));
        const user = response?.data?.listUsers?.items[0];

        if (user) {
          setUser(user);
          setShowGPIs(user.showGPI);
          setShowHidden(user.showHidden);
        }
      } catch (error) {
        console.log("   - Error Getting User Config: ", error);
        global.setAppUI((prev) => ({ ...prev, error: "Error Getting User Config: " + error }));
      }
    };

    getUserConfig();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Merge activeStation data with db
  const UpdateStationStreams = useCallback(
    (dbResponse) => {
      let filtered = {};
      let local = { ...global.activeStation };
      const localStationList = [...global.stationList];

      Object.keys(local.boxes).map((key) => {
        const ssid = +local.boxes[key].ssid;

        // Search for matching stream properties once
        const stream = dbResponse.find((stream) => +stream.ssid === ssid);

        const temp = {
          ...local.boxes[key],
          hide: isHidden({ source: dbResponse, id: ssid }),
          primary: !!stream?.primary,
          source: stream?.source || null,
          config: stream?.config || null,
          input: stream?.input || null,
          category: stream?.category || null,
        };

        filtered[ssid] = temp;
      });

      local.boxes = filtered;

      const localStationIdx = localStationList.findIndex((station) => station.station === local.station);
      if (localStationIdx !== -1) {
        localStationList[localStationIdx] = local;
      }

      global.setStationList(localStationList);
      global.setActiveStation(local);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [global]
  );

  // Update Streams
  useEffect(() => {
    if (global.activeStation && streamMeta) {
      let local = { ...global.activeStation };

      /* Check if it has any streams */
      setHasStreams(!!Object.keys(local.boxes).length);

      /* Sort & Filter Streams */
      const sorted = sortMVPD(local.boxes);
      const filtered = sorted.filter((item) => !item.hide || showHidden).sort((a, b) => b.primary - a.primary);

      local.boxes = filtered;

      /* Save to state */
      setLocalStations(local);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [global.activeStation, streamMeta, showHidden]);

  // Get Streams from DB
  useEffect(() => {
    const fetchStreams = async () => {
      if (!global.activeStation) return;

      setLocalLoading(true);
      setLoading(true);

      try {
        const response = await DataStore.query(Streams, (c) => c.station.eq(global.activeStation.station.toLowerCase()));

        if (response.length && response[0]?.activesb) {
          setStreamMeta(response);
          UpdateStationStreams(response);
        } else {
          setStreamMeta([]);
        }
      } catch (error) {
        console.log("   - Error Getting Streams: ", error);
        setStreamMeta([]);
      } finally {
        setLoading(false);
        setLocalLoading(false);
      }
    };

    fetchStreams();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [global.appUI.forceUpdate]);

  return (
    <Box className="streams___container">
      <Box className="streams___container_inner">
        <Box className="streams___container_label">
          STREAMS
          <Tooltip
            title="Streams from the station selected to the left that are designed to distribute to either Gray owned platforms or to Vuit."
            arrow
            placement="bottom-start"
          >
            <HelpOutline className="label___tooltip" />
          </Tooltip>
        </Box>
        {localStations && (
          <Box className="streams___container_filter">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "1rem",
                padding: "0 1rem",
              }}
            >
              <Typography sx={{ fontSize: "0.75rem" }}>
                GPIs
                <Tooltip title={(showGPIs ? "Hide" : "Show") + " GPI Pin Config"} arrow placement="bottom">
                  <Switch size="small" checked={showGPIs} onChange={() => toggleGPIs()} />
                </Tooltip>
              </Typography>
              <Typography sx={{ fontSize: "0.75rem" }}>
                Hidden
                <Tooltip title={(showGPIs ? "Hide" : "Show") + " Hidden Streams"} arrow placement="bottom">
                  <Switch size="small" checked={showHidden} onChange={() => toggleHidden()} />
                </Tooltip>
              </Typography>
            </Box>
          </Box>
        )}
        {localStations && localLoading === false && hasStreams && streamMeta && (
          <RowItem item={localStations} showGPIs={showGPIs} selectStream={selectStream} streamMeta={streamMeta} />
        )}
        {localLoading &&
          [1, 2, 3, 4, 5].map((item, key) => (
            <Box key={"skeleton_streams_" + key} sx={{ padding: "1rem" }}>
              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "1rem" }}>
                <Skeleton variant="circular" width={12} height={12} />
                <Skeleton key={key} variant="rectangular" width={"100%"} />
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default memo(EventsSidebar);
