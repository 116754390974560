import React from "react";
import { Routes, Route } from "react-router-dom";

// Okta Components
import { LoginCallback, useOktaAuth } from "@okta/okta-react";

// Gray Components
import Loading from "../components/authentication/Loading";
import SignIn from "../components/authentication/SignIn";
import AdminRoutes from "../routing/AdminRoutes";

/* Defines the routes for the application
 *
 * @returns {React.Component} - Returns the React Component for the AppRoutes
 */
const AppRoutes = () => {
  /* Retrieve authentication state */
  const { authState } = useOktaAuth();

  return (
    <Routes>
      <Route path="/authorization-code/callback" element={<LoginCallback loadingElement={<Loading />} />} />
      <Route path="*" element={authState?.isAuthenticated ? <AdminRoutes authState={authState} /> : <SignIn />} />
    </Routes>
  );
};

export default AppRoutes;
