// React Components
import React, { memo, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Material UI Components
import { Box, Divider, IconButton, Tooltip } from "@mui/material";
import {
  Adb,
  Business,
  CellTower,
  Dashboard,
  Handshake,
  Home,
  TableChart,
  Videocam,
  ViewTimeline,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";

// Gray TV Components
import { Application } from "../../context/Application";
import { isSuperAdmin } from "../../utilities/isAdmin";
import { DataStore, SortDirection } from "aws-amplify";
import { Stations } from "../../models";

import setActivityLog from "../../utilities/setActivityLog";

const Subhead = () => {
  const global = useContext(Application);
  const goto = useNavigate();

  const setOwnership = async () => {
    let ssa = true;

    const original = await DataStore.query(Stations, (c) => c.station.eq(global.activeStation.station.toLowerCase()), {
      sort: (s) => s.updatedAt(SortDirection.DESCENDING),
    });

    try {
      if (original.length > 0 && original[0].hasOwnProperty("ssa")) {
        ssa = !original[0].ssa;
        /* Update Record */
        await DataStore.save(
          Stations.copyOf(original[0], (updated) => {
            updated.ssa = !original[0].ssa;
            updated.author = global.appUser.email;
          })
        );
      } else {
        /* Create Record */
        await DataStore.save(
          new Stations({
            station: global.activeStation.station.toLowerCase(),
            ssa: true,
            author: global.appUser.email,
          })
        );
      }
      await setActivityLog({
        station: global.activeStation.station.toLowerCase(),
        type: "SSA",
        note: `${global.activeStation.station.toUpperCase()}'s ownership set to ${ssa ? "operating" : "owned"} `,
      });

      const stationIndex = global.stationList.findIndex((item) => item.station === global.activeStation.station.toLowerCase());

      if (stationIndex !== -1) {
        const newData = [...global.stationList];
        newData[stationIndex].ssa = ssa;

        // Update the state
        global.setStationList(newData);
        global.setActiveStation(newData[stationIndex]);
      }

      // Refresh Navigation
      forceUpdate();
    } catch (e) {
      console.log("   - ERROR", e);
    }
  };

  /* Admin Tool - Set Stream as Primary for ActiveSB */
  const setVisibility = async () => {
    let hidden = true;

    const original = await DataStore.query(Stations, (c) => c.station.eq(global.activeStation.station.toLowerCase()), {
      sort: (s) => s.updatedAt(SortDirection.DESCENDING),
    });

    try {
      if (original.length > 0 && original[0].hasOwnProperty("hidden")) {
        hidden = !original[0].hidden;
        /* Update Record */
        await DataStore.save(
          Stations.copyOf(original[0], (updated) => {
            updated.hidden = !original[0].hidden;
            updated.author = global.appUser.email;
          })
        );
      } else {
        /* Create Record */
        await DataStore.save(
          new Stations({
            station: global.activeStation.station.toLowerCase(),
            hidden: true,
            author: global.appUser.email,
          })
        );
      }
      await setActivityLog({
        station: global.activeStation.station.toLowerCase(),
        type: "VISIBILITY",
        note: `${global.activeStation.station.toUpperCase()}'s visibility set to ${hidden ? "hide" : "unhide"} `,
      });

      const stationIndex = global.stationList.findIndex((item) => item.station === global.activeStation.station.toLowerCase());

      if (stationIndex !== -1) {
        const newData = [...global.stationList];
        newData[stationIndex].hidden = hidden;

        // Update the state
        global.setStationList(newData);
        global.setActiveStation(newData[stationIndex]);
      }

      // Refresh Navigation
      forceUpdate();
    } catch (e) {
      console.log("   - ERROR", e);
    }
  };

  const forceUpdate = () => {
    // Refresh Navigation
    global.setAppUI((prev) => ({ ...prev, forceUpdate: new Date().getTime() }));
  };

  const backToHome = () => {
    global.setActiveStation(null);
    global.setActiveStream(null);
    global.setAppUI((prev) => ({ ...prev, section: "welcome" }));
    goto(`/`);
  };

  const backToStation = () => {
    global.setActiveStream(null);
    global.setAppUI((prev) => ({ ...prev, section: "eventsTable" }));
    goto(`/view/${global.activeStation.station}`);
    // global.setAppUI((prev) => ({ ...prev, section: "overview" }));
  };

  useEffect(() => {
    global.setAppUI((prev) => ({ ...prev, helpTopic: "dashboard" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [global.appUI.forceUpdate]);

  return (
    <React.Fragment>
      {global.activeStation ? (
        <Box className="station___header">
          <Divider orientation="vertical" flexItem />
          <Box className="station___top_meta" sx={{ flex: 2 }}>
            <Box className="breadcrumbs">
              <Box className="child" onClick={() => backToHome()}>
                <Home sx={{ fontSize: "1rem" }} />
                Home
              </Box>
              <Box className="divider">/</Box>
              <Box className="child" onClick={() => backToStation()}>
                <CellTower sx={{ fontSize: "1rem" }} />
                {global.activeStation.station.toUpperCase()}
              </Box>
              {global.appUI.section !== "video" && global.activeStream && global.activeStream.ssid && (
                <>
                  <Box className="divider">/</Box>
                  {global.appUI.section === "eventsTable" && (
                    <Box>
                      <TableChart sx={{ fontSize: "1rem" }} /> Event Monitor
                    </Box>
                  )}
                  {global.appUI.section === "eventsTimeline" && (
                    <Box>
                      <ViewTimeline sx={{ fontSize: "1rem" }} /> Timeline
                    </Box>
                  )}
                  {global.appUI.section === "ad_analysis" && (
                    <Box>
                      <Adb sx={{ fontSize: "1rem" }} /> Ad Analysis
                    </Box>
                  )}
                </>
              )}
              {global.appUI.section === "video" && (
                <>
                  <Box className="divider">/</Box>
                  <Box>
                    <Videocam sx={{ fontSize: "1rem" }} /> Video Monitor
                  </Box>
                </>
              )}
            </Box>
            {isSuperAdmin(global.appUser.account, global.appUI.hideAdminTools, false) && (
              <Divider orientation="vertical" flexItem sx={{ paddingLeft: "0.5rem" }} />
            )}

            {isSuperAdmin(global.appUser.account, global.appUI.hideAdminTools, false) && (
              <Box>
                <Tooltip
                  title={
                    global.activeStation.hidden
                      ? "Currently hidden from users. Set to show Station"
                      : "Currently visibile to users. Set to hide Station"
                  }
                  arrow
                  placement="bottom"
                >
                  <IconButton aria-label="more" size="small" onClick={() => setVisibility()}>
                    {global.activeStation.hidden ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            {isSuperAdmin(global.appUser.account, global.appUI.hideAdminTools, false) && (
              <Box>
                <Tooltip
                  title={
                    global.activeStation.ssa ? "Station is being operated by Gray. Set to 'Owned'" : "Station is owned by Gray. Set to 'Operating'"
                  }
                  arrow
                  placement="bottom"
                >
                  <IconButton aria-label="more" size="small" onClick={() => setOwnership()}>
                    {global.activeStation.ssa ? <Handshake /> : <Business />}
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        <>
          {global.appUI.section === "overview" && (
            <Box className="station___header">
              <Divider orientation="vertical" flexItem />
              <Box className="station___top_meta" sx={{ flex: 2 }}>
                <Box className="breadcrumbs">
                  <Box className="child" onClick={() => backToHome()}>
                    <Home sx={{ fontSize: "1rem" }} />
                    Home
                  </Box>
                  <Box className="divider">/</Box>
                  <Box>
                    <Dashboard sx={{ fontSize: "1rem" }} />
                    Overview Dashboard
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default memo(Subhead);
