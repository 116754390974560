import React, { useContext } from "react";

// MUI Components
import { Box, Skeleton, Stack } from "@mui/material";

// Gray TV Components
import { Application } from "../../../context/Application";

const LoadingSkeleton = () => {
  const global = useContext(Application);

  return (
    <Stack spacing={3} sx={{ width: "100%", flex: 2 }}>
      {!global.appUI.error && (
        <>
          <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", justifyContent: "stretch", alignItems: "center" }}>
            {[1, 2, 3, 4, 5].map((key) => (
              <Box
                key={"skeleton_menu_" + key}
                sx={{ display: "flex", justifyContent: key === 7 ? "flex-end" : "flex-start", flex: key === 3 ? 4 : 0 }}
              >
                <Skeleton variant="rectangle" height={"2rem"} width={"7rem"} />
              </Box>
            ))}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", gap: "8rem", justifyContent: "flex-end", alignItems: "center" }}>
            <Skeleton variant="circular" width={160} height={160} />
            <Skeleton variant="rectangular" width={"60%"} height={220} />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", justifyContent: "stretch", alignItems: "center" }}>
            {[1, 2, 3, 4].map((key) => (
              <Box
                key={"skeleton_filters_" + key}
                sx={{ display: "flex", justifyContent: key === 4 ? "flex-end" : "flex-start", flex: key === 4 ? 4 : 0 }}
              >
                <Skeleton variant="rounded" height={"2rem"} width={"7rem"} />
              </Box>
            ))}
          </Box>

          {/* For other variants, adjust the size with `width` and `height` */}
          {[1, 2, 3, 4, 5, 6, 7, 8].map((key) => (
            <Skeleton key={"skeleton_rows_" + key} variant="rectangle" width={"100%"} height={"2rem"} />
          ))}
        </>
      )}
    </Stack>
  );
};

export default React.memo(LoadingSkeleton);
