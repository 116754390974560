// React Component
import React, { memo } from "react";

// MUI Components
import { Notifications } from "@mui/icons-material";
import { Badge, Tooltip } from "@mui/material";

const ButtonUpdates = ({ updates, status, action }) => {
  return (
    <Tooltip
      title={
        updates.length +
        " development update" +
        (updates && "s") +
        ". Click for details."
      }
      arrow
      placement="bottom-end"
    >
      <Badge badgeContent={updates.length} color="secondary">
        <Notifications
          size="small"
          onClick={action}
          className="header___right_notifications"
          aria-haspopup="true"
          aria-expanded={status ? "true" : undefined}
          aria-controls={status ? "updates-menu" : undefined}
        />
      </Badge>
    </Tooltip>
  );
};

export default memo(ButtonUpdates);
