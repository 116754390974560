import { fetchWithRetry, handleApiResponse } from "./apis";

const API_URL = "https://chwtgazeif.execute-api.us-east-1.amazonaws.com/default/syncmonitor-api/live";

export const updateLiveM3U8 = async (station, id, abortController) => {
  try {
    const url = `${API_URL}?station=${station.station.toLowerCase()}&ssid=${id}&queryType=live`;
    const data = await fetchWithRetry(url, { signal: abortController.signal });
    const { apiResponse, errorMsg } = handleApiResponse(data, "message");

    // Return the parsed response or an error message if any
    if (errorMsg) {
      return { apiResponse: null, errorMsg };
    }

    return { apiResponse: apiResponse?.liveChannel, errorMsg: null };
  } catch (error) {
    console.log("   - Error fetching data:", error);
    return {
      apiResponse: null,
      errorMsg: `Error fetching data: ${error.message}`,
    };
  }
};

export const getStationLiveList = async (station, abortController) => {
  const url = `${API_URL}?station=${station.station.toLowerCase()}&queryType=live`;

  try {
    const data = await fetchWithRetry(url, { signal: abortController.signal });

    // Check if the fetch was aborted
    if (abortController.signal.aborted) {
      throw new Error("Fetch aborted");
    }

    // Return the parsed response or an error message if any
    const { apiResponse, errorMsg } = handleApiResponse(data, "message");
    if (errorMsg) {
      return { apiResponse: null, errorMsg };
    }

    return { apiResponse: apiResponse?.liveChannels, errorMsg: null };
  } catch (error) {
    if (error.name === "AbortError") {
      return {
        apiResponse: null,
        errorMsg: "Error fetching getStationLiveList() logs from Zeam API ... Request aborted",
      };
    }

    return {
      apiResponse: null,
      errorMsg: `Error fetching data: ${error.message}`,
    };
  }
};
