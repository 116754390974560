import { useContext } from "react";

// MUI Components
import { Box, Modal, Typography } from "@mui/material";
import { Timeline } from "@mui/icons-material";

// Gray Components
import EventsTimeline from "../components/timeline/EventsTimeline";
import { Application } from "../context/Application";

const TimelineModal = ({ timelineState, setTimelineState, filteredResponse, totalDuration }) => {
  const global = useContext(Application);

  return (
    <Modal open={timelineState} onClose={() => setTimelineState(false)}>
      <Box
        sx={{
          position: "absolute",
          top: "10%",
          left: "50%",
          transform: "translate(-50%, 0%)",
          height: "80vh",
          width: "80%",
          minWidth: "60rem",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: "1rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h6" component="h2" sx={{ textAlign: "center" }}>
          <Timeline
            sx={{
              fontSize: "2rem",
              verticalAlign: "middle",
              marginRight: "0.5rem",
              color: "#333",
            }}
          />
          {global.activeStream.friendly} Event Timeline
        </Typography>
        <Typography sx={{ textAlign: "center" }}>
          A linear view of stream events for high-level review against established norms &amp; expectations
        </Typography>
        <EventsTimeline searched={timelineState ? filteredResponse : []} totalDuration={totalDuration} />
        <Typography
          variant="caption"
          sx={{
            position: "relative",
            display: "block",
            textAlign: "left",
            fontStyle: "italic",
            maxWidth: "100%",
            marginLeft: "0.5rem",
            "&:before": {
              content: '"*"',
              position: "absolute",
              left: "-0.5rem",
            },
          }}
        >
          Event Timeline should be used as a broad overview of a stream's behavior. Events overlapping the start and end datetimes may resulting in
          gaps in the timeline. The larger the duration of those overlapping events, the larger the gaps may appear. Gaps will appear on the left and
          right of the timeline.
        </Typography>
      </Box>
    </Modal>
  );
};

export default TimelineModal;
