import React, { useEffect } from "react";
import ReactGa4 from "react-ga4";

import { useOktaAuth } from "@okta/okta-react";
import { Amplify } from "aws-amplify";

// Material UI Components
import { Box, Button, Container, Icon, Paper, Typography } from "@mui/material";

// Gray TV Components
import GrayLogo from "../../_images/graytv.png";
import Logo from "../../_images/syncmon_logo.png";
import awsExports from "../../aws-exports";

Amplify.configure(awsExports);

const SignIn = () => {
  const { oktaAuth } = useOktaAuth();

  const handleLogin = () => oktaAuth.signInWithRedirect();

  useEffect(() => {
    document.title = "Sign In | SyncMon | Gray Digital";
    ReactGa4.send({ hitType: "pageview" });

    return () => {};
  });

  return (
    <Container
      maxWidth="sm"
      sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh", width: "100vw" }}
    >
      <Paper>
        <Box style={{ textAlign: "center", padding: "2rem" }}>
          <img
            src={Logo}
            alt="Gray: Television | Digital | Mobile"
            style={{
              maxHeight: "3.0rem",
              margin: "1rem 1rem 3rem",
            }}
          />
          {/* <Typography
            variant="body2"
            style={{
              fontSize: "1.6rem",
              textAlign: "center",
              padding: "1rem 1rem 3rem ",
            }}
          >
            Sign in to your SyncMon account
          </Typography> */}
          <Button
            startIcon={
              <Icon
                style={{
                  margin: 0,
                  padding: 0,
                  fontSize: "1rem",
                  height: "1.5rem",
                  width: "1.5rem",
                }}
              >
                <img src={GrayLogo} alt="Gray TV" />
              </Icon>
            }
            variant="outlined"
            style={{ marginBottom: "2rem" }}
            onClick={() => handleLogin()}
          >
            Sign in with Okta
          </Button>
        </Box>
      </Paper>
      <Box style={{ textAlign: "center", padding: "1rem" }}>
        <Typography variant="body2" sx={{ color: "#888" }}>
          &copy; 2024 <b>Gray Media</b>. Proudly made in <b>Rhode Island</b>
        </Typography>
      </Box>
    </Container>
  );
};

export default SignIn;
