import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./_includes/reportWebVitals";

// Gray TV Components
import HomeScreen from "./views/HomeScreen";
import "./_styles/index.css";

ReactDOM.render(
  <React.Fragment>
    <HomeScreen />
  </React.Fragment>,
  document.getElementById("root")
);

reportWebVitals();
