import React, { useContext } from "react";

// Material-UI
import { Box, List, Typography } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowRight, Star } from "@mui/icons-material";

// Gray TV Components
import { Application } from "../../../../context/Application";
import RowSkeleton from "./RowSkeleton";
import RowItem from "./RowItem";

const FavoritesSection = ({ stations, navTopRef, handleSelect, handleSetFavorites }) => {
  const global = useContext(Application);

  const handleFavoritesCollapsed = () =>
    global.setAppUI({ ...global.appUI, showMobile: !global.appUI.showMobile, showFavorites: !global.appUI.showFavorites, pinNav: false });

  const handleFavoritesToggle = () => global.setAppUI({ ...global.appUI, showFavorites: !global.appUI.showFavorites });

  return (
    <React.Fragment>
      {global.favoriteList && global.favoriteList.length > 0 ? (
        <>
          <Box
            ref={navTopRef}
            className="station___navigation_parent"
            onClick={() => (global.appUI.showMobile ? handleFavoritesToggle() : handleFavoritesCollapsed())}
          >
            <Star className="home___icon" sx={{ height: "1rem" }} />
            {global.appUI.showMobile && (
              <>
                <Box sx={{ flexGrow: 2 }}>Favorites</Box>
                {global.appUI.showFavorites ? <KeyboardArrowDown className="active" /> : <KeyboardArrowRight className="inactive" />}
              </>
            )}
          </Box>
          <List
            className={global.favoriteList && global.favoriteList.length > 0 ? "active" : "inactive"}
            sx={{ display: global.appUI.showFavorites && global.appUI.showMobile ? "block" : "none" }}
          >
            {stations
              ? stations.map((val, key) => {
                  return (
                    global.favoriteList.includes(val.station.toLowerCase()) && (
                      <RowItem key={key} data={val} onSelect={handleSelect} onFavorite={handleSetFavorites} favorites={global.favoriteList} />
                    )
                  );
                })
              : [...Array(3).keys()].map((val) => {
                  return <RowSkeleton key={val} />;
                })}
          </List>
        </>
      ) : (
        <Box className="favorites___inactive" sx={{ display: global.appUI.showFavorites ? "block" : "none" }}>
          <Typography variant="caption" style={{ color: "#ccc", fontStyle: "italic" }}>
            No Favorite Stations Added
          </Typography>
        </Box>
      )}
    </React.Fragment>
  );
};

export default FavoritesSection;
