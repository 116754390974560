export function updateArray({ primary, data }) {
  // Add or Remove from Array
  if (primary && primary.length > 0 && primary.includes(data)) {
    return primary.filter((item) => item !== data).sort();
  } else if (primary && primary.length > 0) {
    return [...primary, data].sort();
  } else {
    return [data].sort();
  }
}

// Set Station as Favorite for Easier Visibility
export const setFavorites = ({ favorites, data }) => {
  let favArray = [];
  favArray = updateArray({ primary: favorites, data: data });

  return favArray;
};

export function scrubStations({ data }) {
  let newList = [];

  data.forEach((element) => {
    const isWithin = Object.values(element.boxes).some((item) => {
      if (item.mvpd.toLowerCase() === "gray" || item.mvpd === "") return true;
      return false;
    });
    if (isWithin) {
      newList.push(element);
    }
  });

  return newList;
}

export const getSearched = ({ key, stations, favorites }) => {
  // Clean out any stations that do not have a Gray MVPD
  const scrubbedStations = scrubStations({ data: stations });

  // Search station call-letters
  const results = scrubbedStations.filter((e) => {
    return e.station.toLowerCase().includes(key.toLowerCase());
  });

  const affiliate = scrubbedStations.filter((e) => {
    return Object.values(e.boxes).some((box) => box.affiliate.toLowerCase().includes(key.toLowerCase()));
  });

  // Search station DMAs
  const locations = scrubbedStations.filter((e) => {
    return e.dmaName.toLowerCase().includes(key.toLowerCase());
  });

  // Unique merge station and location arrays
  const ids = new Set(results.map((d) => d.station));
  const merged = [
    ...results,
    ...locations.filter((d) => !ids.has(d.station)),
    ...affiliate.filter((d) => !ids.has(d.station)),
    /*, ...workflows.filter((d) => !ids.has(d.station))*/
  ];

  return merged;
};
