// React Components
import React, { memo, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// AWS Amplify GraphQL
import { API, graphqlOperation } from "aws-amplify";
import { updateUsers } from "../../graphql/mutations";
import { listUsers } from "../../graphql/queries";

// Material UI Components
import { Box, Drawer } from "@mui/material";
import { Dashboard, Home, Info } from "@mui/icons-material";

// Gray TV Components
import { getSearched, scrubStations, setFavorites } from "../templates/includes/mainSidebarFunctions";
import { Application } from "../../context/Application";
import { getRoutePath } from "../../utilities/utility";
import { isAdmin } from "../../utilities/isAdmin";

import FavoritesSection from "./includes/MainSidebar/FavoritesSection";
import SearchSection from "./includes/MainSidebar/SearchSection";
import StationsSection from "./includes/MainSidebar/StationsSection";

// Gray Assets
import LogoFull from "../../_images/syncmon-reverse-text.png";
import LogoMark from "../../_images/syncmon-mark.png";
import "../../_styles/MainSidebar.scss";

// Constants
const drawerWidth = 220;

const MainSidebar = () => {
  const global = useContext(Application);
  const navTopRef = useRef();
  const goto = useNavigate();

  // Set States
  const [searchTerm, setSearchTerm] = useState(global.appUI.searchTerm);
  const [stations, setStations] = useState(null);
  const [searched, setSearched] = useState(null);

  // Search station array for matching stations
  const handleSearch = (key) => {
    const isSearchKeyPresent = key && key.length > 0;

    setSearchTerm(isSearchKeyPresent ? key : null);
    global.setAppUI((prev) => ({ ...prev, searchTerm: isSearchKeyPresent ? key : null }));

    // Search Stations against searchterm
    if (isSearchKeyPresent) {
      try {
        const merged = getSearched({ key: key, stations: global.stationList, favorites: global.favoriteList });
        if (merged) setSearched(merged);
      } catch (e) {
        console.log("   - Error Searching Stations: ", e);
      }

      // If search/keyterm is empty
    } else {
      setSearched(null);
      setSearchTerm(null);
      global.setAppUI((prev) => ({ ...prev, searchTerm: null }));
    }
  };

  // Unset previously active station to show new station details
  const handleSelect = (station) => {
    const matchingStation = global.stationList.find((item) => item.station === station);

    global.setActiveStream(null);
    global.setActiveStation(matchingStation);
    global.setAppUI((prev) => ({ ...prev, error: null, forceUpdate: new Date().getTime() }));

    goto("/" + getRoutePath(global.appUI.section) + "/" + station);
  };

  // Handlers
  const handleClickHome = () => {
    global.setActiveStation(null);

    goto("/");
  };

  function handleClickOverview() {
    global.setActiveStation(null);
    global.setAppUI({ ...global.appUI, section: "overview" });
    goto("/overview");
  }

  // Set Station as Favorite for Easier Visibility
  const handleSetFavorites = async (data) => {
    try {
      const favArray = setFavorites({ favorites: global.favoriteList, data: data });

      // Update User with new Favorites
      if (!favArray) return;

      global.setFavoriteList(favArray);

      // Fetch users by email
      const response = await API.graphql(graphqlOperation(listUsers, { filter: { email: { eq: global.appUser.email } } }));
      const user = response?.data?.listUsers?.items[0];

      if (!user) return;

      // Update user's favorites
      const updateResponse = await API.graphql(
        graphqlOperation(updateUsers, { input: { id: user.id, favorites: JSON.stringify(favArray), _version: user._version } })
      );

      console.log("   - Update Response: ", updateResponse?.data?.updateUsers?.favorites);

      // Update UI & Scroll to Favorites
      global.setAppUI({ ...global.appUI, showFavorites: true });
      navTopRef.current.scrollIntoView({ behavior: "smooth" });
    } catch (e) {
      console.log("   - ERROR: Failure updating favorites list. ", e);
    }
  };

  // Create separate station array for search filtering
  useEffect(() => {
    if (global.stationList?.length) {
      // Clean out any stations that do not have a Gray MVPD
      const scrubbedStations = scrubStations({ data: global.stationList });
      setStations(scrubbedStations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [global.stationList]);

  return (
    <Drawer
      className={"station___navigation "}
      variant="permanent"
      open={global.appUI.showMobile}
      sx={{
        width: global.appUI.showMobile ? drawerWidth : "3rem",
        backgroundColor: "#333",
      }}
    >
      <Box
        className="logo___mark"
        sx={{
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          justifyContent: "flex-start",
          width: global.appUI.showMobile ? drawerWidth : "3rem",
          flexShrink: 0,
          padding: global.appUI.showMobile ? "0.5rem 1rem" : "0.5rem 0.5rem ",
          overflow: "hidden",
          cursor: "pointer",
        }}
        onClick={() => handleClickHome()}
      >
        <Box
          className="home___logo"
          alt="Gray: Television | Digital | Mobile"
          sx={{
            backgroundImage: `url(${LogoMark})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            height: "2.5rem",
            width: "2rem !important",
            flexShrink: 0,
            flexGrow: 0,
            transition: "all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
          }}
        />
        <Box
          className="home___logo"
          alt="Gray: Television | Digital | Mobile"
          sx={{
            backgroundImage: `url(${LogoFull})`,
            backgroundSize: "auto 1.75rem",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center left",
            height: "2.5rem",
            width: global.appUI.showMobile ? "100%" : "0%",
            opacity: global.appUI.showMobile ? 1 : 0,
            marginLeft: global.appUI.showMobile ? "0.5rem" : "0.5rem",
            transition: "opacity 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
          }}
        />
      </Box>
      <Box className="station___navigation_wrapper" sx={{ width: global.appUI.showMobile ? drawerWidth : "3rem" }}>
        <Box className="station___navigation_rows">
          <Box className="station___navigation_parent" onClick={() => handleClickHome()}>
            <Home className="home___icon" sx={{ height: "1rem" }} />
            {global.appUI.showMobile && <Box>Home</Box>}
          </Box>

          {isAdmin(global.appUser.account, global.appUI.hideAdminTools, false) && (
            <Box className="station___navigation_parent" onClick={() => handleClickOverview()}>
              <Dashboard className="home___icon" sx={{ height: "1rem" }} />
              {global.appUI.showMobile && <Box>Overview</Box>}
            </Box>
          )}

          {/* SEARCH  */}
          <SearchSection
            searched={searched}
            searchTerm={searchTerm}
            handleSearch={handleSearch}
            handleSelect={handleSelect}
            handleSetFavorites={handleSetFavorites}
          />

          {/* FAVORITES */}
          <FavoritesSection stations={stations} navTopRef={navTopRef} handleSelect={handleSelect} handleSetFavorites={handleSetFavorites} />

          {/* STATIONS */}
          <StationsSection stations={stations} handleSelect={handleSelect} handleSetFavorites={handleSetFavorites} />

          <Box
            sx={{
              padding: global.appUI.showMobile ? "1rem 0.5rem 1rem 1rem" : "1rem 0.25rem",
              color: "#888",
              fontSize: "0.75rem",
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              alignContent: "center",
              alignItems: "center",
              justifyContent: global.appUI.showMobile ? "flex-start" : "center",
              whiteSpace: "nowrap",
            }}
          >
            {global.appUI.showMobile && <Info sx={{ fontSize: "0.75rem", marginRight: "0.5rem" }} />}
            {global.appUI.showMobile ? <Box>SyncMon Build v{global.appUI.appVersion}</Box> : <Box>v{global.appUI.appVersion}</Box>}
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default memo(MainSidebar);
