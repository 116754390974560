import React, { useContext, useEffect, useState } from "react";
import { Avatar, Box } from "@mui/material";

// Moment.js Components
import moment from "moment-timezone";

// GraphQL Components
import { API, graphqlOperation } from "aws-amplify";
import { listScteAverages } from "../../../graphql/queries";

// Material-UI Components
import { AutoAwesome, Warning } from "@mui/icons-material";

// Gray Components
import { Application } from "../../../context/Application";
import { getTz } from "../../../utilities/utility";

const ScteWarnings = ({ logs }) => {
  let isMounted = true;
  const maxTime = 15 * 60;

  const global = useContext(Application);
  const [scteError, setScteError] = useState({ secondsAgo: 0, color: "#fffff00", message: "" });
  const [scteAverages, setScteAverages] = useState([]);

  const checkInsights = () => {
    let response = "";

    console.log("--------------------------------------------");
    console.log("SCTE Insights:");

    // If there are any SCTE benchmarks for insights
    if (scteAverages > 0) {
      const hoursAgo = moment.tz(getTz(global.appDates.timezone)).subtract(scteAverages, "hours").format("ha");

      console.log(`   - Historical: ${scteAverages} hrs prior`);
      response = `For ${moment()
        .tz(getTz(global.appDates.timezone))
        .format("dddd")}s, at this time of day, the last ad break typically occurs during the ${hoursAgo} hour`;

      // If the last ad break was seen within the last 30 minutes
    } else if (scteAverages === 0) {
      console.log(`   - Historical: Immediate (${scteAverages})`);
      response = `Ad breaks are typically seen during this hour. If one isn't seen soon, please investigate.`;

      // If there are no SCTE benchmarks for insights
    } else {
      console.log(`   - Historical: Undefined for this stream`);
    }

    return response;
  };

  useEffect(() => {
    if (!isMounted) return;

    setScteError({ secondsAgo: 0, color: "#ffffff00", message: "" });

    const colorCode = (val) => {
      if (val >= 86400) {
        return "#000";
      } else if (val > 72000) {
        return "#d32f2f";
      } else if (val > 43200) {
        return "#ed6c02";
      } else if (val > 21600) {
        return "gold";
      } else if (val > 10800) {
        return "cornsilk";
      } else if (val > 1800) {
        return "#f0f8ff";
      } else {
        return "#ffffff";
      }
    };

    const fontCode = (val) => (val > 43200 ? "#fff" : "#000");
    const insightsCode = (val) => (val > 43200 ? "#fff" : "#369");

    // Filter the logs for the most recent ad break event
    const filteredEvents = logs.filter((event) => event.EventType === 0 && event.IsBegin === true);
    const sortedEvents = filteredEvents.sort((a, b) => new Date(b.Time) - new Date(a.Time));

    // If there are any ad break events
    if (sortedEvents.length > 0) {
      const mostRecentEvent = sortedEvents[0];

      // Calculate the time difference from now
      const now = moment(); // Current time in local timezone
      const eventTime = moment.tz(mostRecentEvent.Time, getTz(global.appDates.timezone));
      const timeDifference = now.diff(eventTime); // Time difference in milliseconds
      const secondsAgo = Math.floor(timeDifference / 1000); // Convert milliseconds to seconds

      if (secondsAgo > maxTime) {
        getScteAverage(); // Fetch the SCTE averages for insights
        setScteError({
          secondsAgo: secondsAgo,
          color: colorCode(secondsAgo),
          fontColor: fontCode(secondsAgo),
          insightsColor: insightsCode(secondsAgo),
          message: timeCalc(secondsAgo),
          benchmark: checkInsights(),
        });
      } else {
        console.log("SCTE Insights: No alerts needed");
      }
    } else {
      setScteError({
        secondsAgo: global.appUI.refresh * 60 * 60,
        color: colorCode(global.appUI.refresh * 60 * 60),
        fontColor: fontCode(global.appUI.refresh * 60 * 60),
        insightsColor: insightsCode(global.appUI.refresh * 60 * 60),
        message: `No Ad Break Events (CUE-OUT) seen within the reporting timerange (trailing ${global.appUI.refresh} hours)`,
      });
    }

    return () => (isMounted = false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [global.appUI.refresh, logs, scteAverages]);

  const timeCalc = (seconds) => {
    const weeks = Math.floor(seconds / 604800);
    const days = Math.floor((seconds % 604800) / 86400);
    const hours = Math.floor((seconds % 86400) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    if (seconds > 0) {
      return `Last Ad Break Event (CUE-OUT) seen  
      ${weeks > 0 ? weeks + (weeks > 1 ? " weeks" : " week") : ""} 
      ${days > 0 ? days + (days > 1 ? " days" : " day") : ""}
      ${hours > 0 ? hours + (hours > 1 ? " hours" : " hour") : ""} 
      ${minutes > 0 ? minutes + (minutes > 1 ? " minutes" : " minutes") : ""} ago`;
    } else {
      return "...";
    }
  };

  async function processScteAverages(response) {
    const utcHour = moment.utc().format("H"); // Get the current hour in UTC

    // Assuming hr_avgs is a JSON string in the response
    if (!response || !response.hr_avgs) {
      console.log("   - No hr_avgs found in response:", response);
      return null;
    }

    const hrAvgsString = response.hr_avgs;

    try {
      const hrAvgsObject = JSON.parse(hrAvgsString);
      const matchingHourData = hrAvgsObject[utcHour];

      return matchingHourData;
    } catch (error) {
      console.log("   - Error parsing hr_avgs:", error);
      return null;
    }
  }

  const getScteAverage = async () => {
    const input = {
      station: { eq: global.activeStation.station.toLowerCase() },
      ssid: { eq: +global.activeStream.ssid },
      dotw: { eq: moment.utc().format("dddd") },
      _deleted: { ne: true },
    };

    const response = await API.graphql(graphqlOperation(listScteAverages, { filter: input, limit: 100 }));

    // Handle pagination if there are more items
    let items = response.data.listScteAverages.items;
    let nextToken = response.data.listScteAverages.nextToken;

    while (nextToken) {
      const nextResponse = await API.graphql(graphqlOperation(listScteAverages, { filter: input, limit: 100, nextToken }));
      items = [...items, ...nextResponse.data.listScteAverages.items];
      nextToken = nextResponse.data.listScteAverages.nextToken;
    }

    const processed = await processScteAverages(items[0]);
    setScteAverages(processed);
  };

  return (
    <Box>
      {scteError.secondsAgo >= maxTime && (
        <Box
          style={{
            background: scteError.color,
            borderRadius: "0.5rem",
            padding: "0.5rem 1rem",
            color: scteError.fontColor,
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            alignItems: "center",
            justifyItems: "flex-start",
            boxShadow: "rgba(6, 26, 54, 0.16) 0 0.25rem 1rem",
          }}
        >
          <Avatar style={{ border: "1.2px solid #fff", background: "#444" }}>
            <Warning style={{ fontSize: "2rem", color: "gold", padding: "0.2rem" }} />
          </Avatar>
          <div>
            <h3 style={{ fontSize: "0.75rem", textTransform: "uppercase", fontWeight: "bold", margin: 0, padding: 0 }}>
              SCTE AD BREAK RECENCY ALERT
            </h3>
            <h2 style={{ fontSize: "0.875rem", margin: 0, padding: 0, fontWeight: 500 }}>{scteError.message}.</h2>
            {scteError.benchmark && (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.35rem",
                  justifyItems: "flex-start",
                  alignItems: "center",
                  alignItems: "center",
                  marginTop: "0.5rem",
                }}
              >
                <AutoAwesome style={{ fontSize: "1.2rem", margin: 0, padding: 0, fontWeight: 500, color: "#369" }} />
                <h2 style={{ fontSize: "0.875rem", margin: 0, padding: 0, fontWeight: 500, color: "#369" }}>{scteError.benchmark}</h2>
                <Box
                  style={{
                    fontSize: "0.75rem",
                    marginLeft: "0.25rem",
                    padding: "0 0 0 0.5rem",
                    fontWeight: 200,
                    color: scteError.insightsColor,
                    borderLeft: scteError.insightsColor === "#fff" ? `1px solid #fff` : `1px solid #33669988`,
                    textAlign: "center",
                  }}
                >
                  βeta Insight
                </Box>
              </Box>
            )}
          </div>
        </Box>
      )}
    </Box>
  );
};

export default ScteWarnings;
