import React, { useState, useEffect, useMemo } from "react";

const LoadingPhrases = () => {
  const phrases = useMemo(
    () => [
      "Brewing some coffee for the pixels…",
      "Waking up the hamsters on the wheel...",
      "If this takes too long, blame the gnomes.",
      "Fetching some bytes... they’re on a coffee break.",
      "Hold on, we're rearranging the 1s and 0s.",
      "Just a second… dusting off the circuits.",
      "Loading… because good things come to those who wait.",
      "Hang tight… the squirrels are powering up the servers.",
      "Almost there… just telling the data to behave.",
      "Transmitting awesomeness... please stand by.",
      "Beep boop... computing something amazing!",
      "Polishing the pixels… they like to look their best.",
      "Calculating the meaning of life… and loading your content.",
      "Serving fresh data with a side of humor.",
      "Loading… please don’t press the red button.",
      "Reassembling the universe… or just your content.",
      "Hold on… giving the electrons a pep talk.",
      "Our data ninjas are working their magic…",
      "Loading… the suspense is killing us too.",
      "Hang on… counting all the stars in the digital sky.",
      "Preparing your content... and a little surprise!",
      "Our servers are warming up... almost there!",
      "Fetching your data... it's worth the wait!",
    ],
    []
  );

  const [currentPhrase, setCurrentPhrase] = useState(phrases[0]);

  useEffect(() => {
    const getRandomPhrase = () => {
      const randomIndex = Math.floor(Math.random() * phrases.length);
      return phrases[randomIndex];
    };

    setCurrentPhrase(getRandomPhrase()); // Set initial random phrase

    const interval = setInterval(() => {
      setCurrentPhrase(getRandomPhrase);
    }, 3000); // Change phrase every 3 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, [phrases]);

  return <>{currentPhrase}</>;
};

export default React.memo(LoadingPhrases);
