export const topics = {
  monitor: [
    {
      title: "Video Monitor",
      topics: [
        {
          title: "Overview",
          message: [
            "The <b>Video Monitor</b> tab provides a means to view different iterations of the station's stream to various distribution partners.",
            "The page featured a video player that auto-plays with captions enabled and audio off; meta information about the stream including latency, position, and quality; as well as a running log of different information/events detected within the stream.",
          ],
        },
      ],
    },
    {
      title: "Player & Playback Info",
      topics: [
        {
          title: "Overview",
          message: [
            "Powered by <b>JWPlayer</b>, the video player is set to auto-play as the tab is opened. Captions are on by default (for troublshooting) and auido is disabled.",
            "<b>Tip</b>: If a caption box is not visible nor captions rendered in the player, check your captioning workflow. The system does not currently specify whether both 608 or 708 are included.",
          ],
        },
        {
          title: "Playback Information",
          message: [
            "The <b>Playback Information</b> box exposes high-level information to help understand and troubleshoot station partner distribution streams.",
            "This included the streams current position and latency to review upcoming events, as well as the current quality of the stream as seen within the video player.",
            "<b>Tip</b>: Use the stream latency to monitor and review upcoming CUE-OUT/IN events. Noting the position in the 'Event Debugging' table ('Information' column), and watch the transition for cleanliness.",
            "<b>Tip</b>: Different stream partners have different requirements so the stream, slates, quality, may be different.",
            "<b>NOTE</b>: Do not use the stream urls provided for anything other than testing/debugging.",
          ],
        },
      ],
    },
  ],
  reports: [
    {
      title: "Event Monitor",
      topics: [
        {
          title: "Overview",
          message: [
            "The <b>Event Monitor</b> tab provides a full view of a stream (when available) in terms of construction -- blackout/LNL, local streaming, ad breaks -- as well as detaileed information about the events including datetimes, durations, and warnings.",
            "The Event Monitor is <b>limited to a rolling two weeks</b> for stream events. This limitation is based on Zeam's API capabilities.",
            "The period duration defaults to 'Last 12 hours', but also offers quick selections for 'Last 24 hours' and 'Last 48 hours'.",
            "A roll-up report is available at the top of the page that shows event totals and averages for a high-level view of a stream's construction.",
            "The tabular view offers a more detailed view of the events happening during the defined period including: local streaming, blackout/LNL, and CUE-OUT/IN ad breaks.",
          ],
        },
      ],
    },
    {
      title: "Roll-Up Report",
      topics: [
        {
          title: "Stream Event Percentage",
          message: [
            "The donut chart that highlights the percent duration that the stream was designated as 'Local Streaming', in a 'Local Ad Break', or in blackout/Local News Live.",
            '<div style="background: #2196f3; float: left; height: 0.875rem; width: 0.875rem; margin-right: 0.25rem; margin-top:0.125rem"></div><b>Local Streaming</b> show data for the station\'s programming (blue) which does not include local ad breaks. ',
            '<div style="background: #4caf50; float: left; height: 0.875rem; width: 0.875rem; margin-right: 0.25rem; margin-top:0.125rem"></div><b>Ad Break Duration</b> only shows data for local ad breaks (green) NOT ad breaks within Local News Live (LNL). Local News Live ad break duration can be viewed by selecting <b>LNL</b> from the left-hand hamburger menu button.',
            "<div style=\"background: #ff9800; float: left; height: 0.875rem; width: 0.875rem; margin-right: 0.25rem; margin-top:0.125rem\"></div><b>Blackout/LNL</b> metrics are for when the station is not streaming locally (orange). This should in most cases enable 'Local News Live' in the stream. It does not separate into blackout streaming vs ad breaks. To view that data, select 'LNL' from the left-hand hamburger menu button.",
          ],
        },
        {
          title: "Tips",
          message: [
            "Review the ad break duration against expectations. A larger that normal duration could highlight CUE-IN events are not being triggered.",
            "A local streaming duration that is greater than expected may suggest that triggers to close it out (LS2) are not seing tripped. Check your CTV desk if this is happening.",
          ],
        },
      ],
    },
    {
      title: "Event Log Table",
      topics: [
        {
          title: "Event Type Glossary",
          message: [
            '<div style="background: #2196f3; float: left; height: 0.875rem; width: 0.875rem; margin-right: 0.25rem; margin-top:0.125rem"></div><b>Local Streaming</b>: Station has triggered a stream start (ie: LS1) and is in control off the contents of the stream.',
            "<div style=\"background: #ff9800; float: left; height: 0.875rem; width: 0.875rem; margin-right: 0.25rem; margin-top:0.125rem\"></div><b>Blackout/LNL</b>: Station has terminated control of the stream (ie: LS2). 'Local News Live' is mostly likely now being played on the station's stream.",
            '<div style="background: #4caf50; float: left; height: 0.875rem; width: 0.875rem; margin-right: 0.25rem; margin-top:0.125rem"></div><b>Ad Break CUE-OUT</b>: Station has triggered the start of an ad break either via triggered based workflow, DBI or MiddleMan. Duration is the specified duration from within the SCTE decoration.',
            '<div style="background: #4caf50; float: left; height: 0.875rem; width: 0.875rem; margin-right: 0.25rem; margin-top:0.125rem"></div><b>Ad Break CUE-IN</b>: Station has triggered the end of an ad break (via triggered based workflow, DBI or MiddleMan). Expected duration is zero as the event should be completed immediately.',
          ],
        },
        {
          title: "Warnings",
          message: [
            "<b>Warnings</b> are highlighted when an event lasts either longer or shorter than an expected duration. For example, an ad break that is 20 minutes is an unexpectedly long.",
            "<b>NOTE</b>: The most recent event will always be flagged with a warning as its duration is zero until a new event is triggered. Check the datetime of the event for context.",
          ],
        },
      ],
    },
  ],
  dashboard: [
    {
      title: "The Dashboard",
      topics: [
        {
          title: "Overview",
          message: [
            "The Dashboard serves as a quick view of a station's configured Zeam streams with the option to view additional event details or monitor different platform streams for QA/QC.",
            "The tabs at the top of the dashboard focus the view on stream at the beginning or the end of its digital lifecycle.",
            "<i>The information displayed is only as good as the information that Zeam has captured for the streams listed. Information may be incomplete.</i>",
          ],
        },
        {
          title: "Event Monitor",
          message: [
            "The <b>Event Monitor</b> is focused on the streams configured with Zeam and the events that define how the stream is constructed: local streaming, blackout/LNL, or ad breaks. More information can be found within the <b>Event Monitor</b> help.",
          ],
        },
        {
          title: "Video Monitor",
          message: [
            "The <b>Video Monitor</b> focuses on the streams prior to distribution to a partner and highlights captions, SCTE, warnings, errors, etc. More information can be found within the <b>Video Monitor</b> help.",
            "<b>NOTE</b>: Not all stations will have <b>Video Monitor</b> exposed. Only those who have been configured a distribution partner -- Roku, Amazon or Samsung -- will have this option.",
          ],
        },
        {
          title: "Station Dashboard",
          message: [
            "A tabular view of a selected station's Zeam streams is meant to surface the generalize state of the stream, partner distributions, whether ads are enabled, enabled for trigger-based workflow, and affiliate . ",
            "Table rows can be clicked on to end the <b>Event Monitor</b> for additional stream information.",
          ],
        },
      ],
    },
    {
      title: "Table Columns",
      topics: [
        {
          title: "Stream Status",
          message: [
            "The <b>Status</b> column indicates whether Zeam has currently identified an issue with the stream. Usually this is indicative of 'not streaming'. If this is an unknown issue, please reach out to Zeam.",
          ],
        },
        {
          title: "MVPD/Platform",
          message: [
            "The <b>MVPD</b> column helps to designate the primary partner for which the stream is intended. It is common to see platforms like Vuit, Hulu, Apple, Fubo, or Amazon in this list. Gray TV streams had been identified in the MVPD column with a Gray logo and will more likely have richer information about the stream.",
          ],
        },
        {
          title: "Ads Enable/Disabled",
          message: [
            "The <b>Ads</b> column highlights streams that have been enabled for dynamic ad insertion within the stream. This will mostly likely be disabled for partner MVPDs and enabled for just the primary station stream and Vuit.",
          ],
        },
        // {
        //   title: "Ad Duration",
        //   message: [
        //     "The <b>Ad Duration</b> column shows the default fixed ad break duration configured with Zeam for partner streams that require duration passed and isn't defined within our SCTE decorations. ",
        //   ],
        // },
        {
          title: "Trigger Based Workflow (TBW)",
          message: [
            "The <b>TBW</b> column specified whether trigger-based workflow is enabled for a stream. For streams that require ad breaks to be identified, this value will be true (even if on MiddleMan). For MVPD partners simulcasting our complete broadcast (programming and ads), this will be disabled.",
          ],
        },
        {
          title: "Affiliate",
          message: ["The <b>Affiliate</b> column lists the network affiliation for the stream to help identify streams for duopolies (or greater)."],
        },
      ],
    },
  ],
};
