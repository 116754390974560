// React Components
import React, { memo, useContext } from "react";

// MUI Components
import { Badge, Box, ListItem, ListItemButton, Tooltip, Typography } from "@mui/material";
import { Star, StarBorderOutlined } from "@mui/icons-material";

// Gray Components
import { Application } from "../../../../context/Application";
import { isAdmin } from "../../../../utilities/isAdmin";

const RowItem = ({ data, onSelect, onFavorite, favorites }) => {
  const global = useContext(Application);

  return (
    <React.Fragment>
      <ListItem
        disablePadding
        className={
          "station___navigation_row " +
          (global.activeStation && global.activeStation.station === data.station.toLowerCase() ? " active" : "") +
          (data.hidden ? " hidden" : "") +
          (isAdmin(global.appUser.account, global.appUI.hideAdminTools, false) ? " admin" : "")
        }
      >
        <ListItemButton className="station___navigation_row_button" onClick={(e) => onSelect(data.station.toLowerCase())}>
          <Box>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
              {isAdmin(global.appUser.account, global.appUI.hideAdminTools, false) && data.notes ? (
                <Tooltip title={`${data.notes} open note(s) on this station`} placement="right" arrow>
                  <Badge className="notes___count" badgeContent={data.notes} color="warning">
                    <Typography variant="span">{data.station.toUpperCase()}</Typography>
                  </Badge>
                </Tooltip>
              ) : (
                <Typography variant="span">{data.station.toUpperCase()}</Typography>
              )}
            </Box>
            <Typography>{data.dmaName}</Typography>
          </Box>
        </ListItemButton>
        {favorites && favorites.length > 0 && favorites.includes(data.station.toLowerCase()) ? (
          <Star className="station___navigation_star active" onClick={(e) => onFavorite(data.station.toLowerCase())} />
        ) : (
          <StarBorderOutlined className="station___navigation_star " onClick={(e) => onFavorite(data.station.toLowerCase())} />
        )}
      </ListItem>
    </React.Fragment>
  );
};

export default memo(RowItem);
