// React Components
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

// Material UI Components
import { Box, Button, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { Adb, ExpandLess, ExpandMore, TableChart, ViewTimeline as Timeline } from "@mui/icons-material";

// Gray Components
import { Application } from "../../context/Application";
import { getRouteName } from "../../utilities/utility";

// Gray Assets
import "./styles/StreamDateRange.scss";

const StreamDateRange = ({ route, setTimelineState }) => {
  const global = useContext(Application);
  const goto = useNavigate();

  /* Drop Down Elements */
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getRouteRanges = () => {
    if (global.appUI.section === "ad_analysis") {
      return [3, 6, 12, 24, 36, 48, 72, 120];
    } else if (global.appUI.section === "eventsTable") {
      return [3, 6, 12, 24, 36, 48, 72, 120, 168, 336];
    } else if (global.appUI.section === "eventsTimeline") {
      return [3, 6, 12, 24, 36, 48, 72, 120];
    } else {
      return [0];
    }
  };

  return (
    <Box className="filter___daterange">
      <Box className="filter___daterange_nav">
        <Box className="filter___daterange_table" sx={{ maxWidth: "100%" }}>
          <Box className="filter___daterange_row">
            <Typography variant="h1">Set {getRouteName(global.appUI.section)} to Trailing:</Typography>
            <Button
              id="video-source-button"
              variant="outlined"
              aria-controls={open ? "video-source-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              endIcon={open ? <ExpandLess /> : <ExpandMore />}
              size="small"
            >
              {global.appUI.refresh ? (
                global.appUI.refresh >= 48 ? (
                  <span>{global.appUI.refresh / 24} days</span>
                ) : (
                  <span>{global.appUI.refresh} hrs</span>
                )
              ) : (
                "Select Date Range"
              )}
            </Button>
            <Menu
              id="video-source-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {getRouteRanges().map((value) => {
                return (
                  <MenuItem
                    key={value}
                    onClick={() => {
                      global.setAppUI((prev) => ({ ...prev, refresh: value }));
                      handleClose();
                    }}
                    sx={{
                      minWidth: "5.4rem",
                      fontSize: "0.875rem",
                      fontWeight: global.appUI.refresh === value ? 700 : "inherit",
                      color: global.appUI.refresh === value ? "rgba(15, 72, 255, 0.75)" : "inherit",
                    }}
                  >
                    {value >= 48 ? <span>{value / 24} days</span> : <span>{value} hrs</span>}
                  </MenuItem>
                );
              })}
            </Menu>
            <Box sx={{ flexGrow: 2 }} />
            {(global.appUI.section === "ad_analysis" || global.appUI.section === "eventsTimeline") && (
              <Tooltip title="Back to Event Monitor'" arrow placement="bottom">
                <Button
                  size="small"
                  onClick={(e) => {
                    goto("/view/" + global.activeStation.station.toLowerCase() + "/" + global.activeStream.ssid);
                  }}
                  variant="contained"
                  startIcon={<TableChart />}
                  sx={{ maxWidth: "10rem", boxShadow: "none" }}
                >
                  Event Monitor
                </Button>
              </Tooltip>
            )}
            {global.appUI.section !== "ad_analysis" && ["MIDDLEMAN", "DBI", "TIMED", "FULLSCTE"].includes(global.activeStream.config) && (
              <Tooltip title="Ad Analysis view not available for 'Last 14 Days'" arrow placement="bottom">
                <Button
                  size="small"
                  onClick={(e) => {
                    goto("/ad_analysis/" + global.activeStation.station.toLowerCase() + "/" + global.activeStream.ssid);
                  }}
                  variant="contained"
                  // disabled={global.appUI.refresh > 120 ? true : false}
                  startIcon={<Adb />}
                  sx={{ maxWidth: "10rem", boxShadow: "none" }}
                >
                  Analyze Breaks
                </Button>
              </Tooltip>
            )}
            {global.appUI.section !== "eventsTimeline" && (
              <Tooltip title="Timeline view not available for 'Last 14 Days'" arrow placement="bottom">
                <Box>
                  <Button
                    size="small"
                    onClick={() => goto("/timeline/" + global.activeStation.station.toLowerCase() + "/" + global.activeStream.ssid)}
                    variant="contained"
                    // disabled={global.appUI.refresh >= 36 ? true : false}
                    startIcon={<Timeline />}
                    sx={{ maxWidth: "10rem", boxShadow: "none" }}
                  >
                    View Timeline
                  </Button>
                </Box>
              </Tooltip>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(StreamDateRange);
