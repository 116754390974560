import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

/* Miscellanous */
import { PieChart, Pie, Cell, Sector } from "recharts";
import moment from "moment-timezone";
import ReactGa4 from "react-ga4";

/* AWS Amplify DataStore & GraphQL */
import { API, DataStore, graphqlOperation } from "aws-amplify";
import { updateUsers } from "../graphql/mutations";
import { listUsers } from "../graphql/queries";
import { Streams } from "../models";

/* Material UI Components */
import { Alert, Avatar, Box, Chip, CircularProgress, Link, Paper, Slide, Snackbar, Switch, Tooltip, Typography } from "@mui/material";
import { AcUnit, Check, Warning, WarningOutlined } from "@mui/icons-material";
import { blue, green, grey } from "@mui/material/colors";

/* Gray Digital Components */
import { getLastAdBreaks } from "../utilities/getLastAdBreaks";
import { getOverview } from "../utilities/getOverview";
import { Application } from "../context/Application";
import { isAdmin } from "../utilities/isAdmin";
import { getTz } from "../utilities/utility";

import "../_styles/Overview.scss";

const Overview = () => {
  const global = useContext(Application);
  const goto = useNavigate();

  const colors = useMemo(() => [green[500], blue[500], grey[700]], []);

  const [exceptions, setExceptions] = useState([]);
  const [overview, setOverview] = useState(null);
  const [scteAlerts, setScteAlerts] = useState([]);
  const [updateStatus, setUpdateStatus] = useState(false);

  const [activeIdx, setActiveIdx] = useState(0);
  const [totals, setTotals] = useState({
    adbreaks: 0,
    streaming: 0,
    blackout: 0,
    total: 0,
  });
  const [benchmarks, setBenchmarks] = useState({
    min: { adbreaks: 0, streaming: 0, blackout: 0 },
    max: { adbreaks: 0, streaming: 0, blackout: 0 },
  });

  const handleHide = async () => {
    try {
      const email = global.appUser.email;
      const currentShowTelemundos = global.appUI.showTelemundos;

      // Fetch user data by email
      const response = await API.graphql(graphqlOperation(listUsers, { filter: { email: { eq: email } } }));
      const user = response?.data?.listUsers?.items[0];

      if (user) {
        const updatedUser = await API.graphql(
          graphqlOperation(updateUsers, {
            input: {
              id: user.id,
              showTelemundos: !currentShowTelemundos,
              _version: user._version,
            },
          })
        );
        console.log("   - Updated user", updatedUser?.data);
        global.setAppUI((prev) => ({ ...prev, showTelemundos: !currentShowTelemundos }));
      }
    } catch (err) {
      console.log("   - Failed to update user", err);
      global.setAppUI((prev) => ({ ...prev, error: "Failed to update user" }));
    }
  };

  useEffect(() => {
    global.setActiveStation(null);
    const abortController = new AbortController();

    // Set document title and send pageview event
    document.title = "Station Overview | SyncMon | Gray Digital";
    ReactGa4.send({ hitType: "pageview" });

    // Fetch user configuration
    const getUserConfig = async () => {
      try {
        const response = await API.graphql(graphqlOperation(listUsers, { filter: { email: { eq: global.appUser.email } } }));
        const user = response?.data?.listUsers?.items[0];
        if (user) {
          global.setAppUI((prev) => ({ ...prev, showTelemundos: user.showTelemundos }));
        }
      } catch (err) {
        console.log("   - Failed to fetch user config", err);
        global.setAppUI((prev) => ({ ...prev, error: "Failed to fetch user config" }));
      }
    };

    /* Fetch exceptions */
    const fetchExceptions = async () => {
      try {
        const results = await DataStore.query(Streams, (c) => c.config.eq("SNOWFLAKE"));
        const matches = results.map((item) => item.ssid);
        setExceptions(matches);
      } catch (error) {
        console.log("   - Failed to fetch exceptions", error);
        global.setAppUI((prev) => ({ ...prev, error: "Failed to fetch exceptions" }));
      }
    };

    getUserConfig();
    fetchExceptions();

    return () => abortController.abort();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchScteAlerts = () => {
    global.setActiveStation(null);
    const abortController = new AbortController();

    // Check if there are exceptions and if the user has admin rights
    if (exceptions.length > 0 && global.appUser && isAdmin(global.appUser.account, global.appUI.hideAdminTools, false)) {
      getLastAdBreaks(abortController)
        .then((res) => {
          const streams = res.apiResponse.streams || [];

          // Filter & Sort SCTE alerts
          const alerts = streams
            .filter((item) => !exceptions.includes(Number(item.ssid)) && item.state === "alert")
            .sort((a, b) => b.delta - a.delta); // Sort by delta in descending order

          setScteAlerts(alerts);
        })
        .catch((err) => {
          console.log("   - Failed to fetch SCTE alerts", err);
          global.setAppUI((prev) => ({ ...prev, error: "Failed to fetch SCTE alerts" }));
        });
    }

    return () => abortController.abort();
  };

  const fetchPercentages = () => {
    global.setActiveStation(null);
    const abortController = new AbortController();

    // Check if user has admin rights
    if (global.appUser && isAdmin(global.appUser.account, global.appUI.hideAdminTools, false)) {
      getOverview(abortController)
        .then((res) => {
          if (!res.apiResponse) {
            setOverview(null);
            global.setAppUI((prev) => ({ ...prev, error: res.errorMsg }));
            return;
          }

          const localTotals = { adbreaks: 0, streaming: 0, blackout: 0, total: 0 };
          const response = { ...res.apiResponse };

          /* Retrieve API Data */
          response.streams.forEach((item, key) => {
            response.streams[key]["exception"] = exceptions.includes(Number(item.ssid));

            response.streams[key]["data"] = response.streams[key]["data"] || [];
            response.streams[key]["alarms"] = response.streams[key]["alarms"] || [];

            Object.keys(item.percentages).forEach((label) => {
              if (label !== "total") {
                response.streams[key]["data"].push({
                  name: label,
                  value: item.percentages[label],
                });
              }
              localTotals[label] += item.percentages[label];
            });
          });

          const offsets = {
            adbreaks: [2, 1], // Different adbreaks thresholds for min/max
            streaming: [2, 1], // Different streaming thresholds for min/max
            blackout: [2, 3.5], // Different blackout thresholds for min/max
          };

          const calculateBenchmarks = (totals, offsets) => ({
            min: Object.fromEntries(
              Object.keys(offsets).map((key) => [key, Math.round(((totals[key] - totals[key] / offsets[key][0]) / totals.total) * 100)])
            ),
            max: Object.fromEntries(
              Object.keys(offsets).map((key) => [key, Math.round(((totals[key] + totals[key] / offsets[key][1]) / totals.total) * 100)])
            ),
            avg: Object.fromEntries(Object.keys(offsets).map((key) => [key, Math.round((totals[key] / totals.total) * 100)])),
          });

          const localBenchmarks = calculateBenchmarks(localTotals, offsets);

          response.streams.forEach((item) => {
            const { percentages, alarms } = item;
            const day = moment.utc(response.dates.start).day();
            const isWeekday = day !== 0 && day !== 6;

            if (percentages.adbreaks === 0) {
              alarms.push({ type: "adbreaks", name: "Ad Breaks", message: "Zero", delta: localBenchmarks.min.adbreaks - percentages.adbreaks });
            } else if (percentages.adbreaks < localBenchmarks.min.adbreaks && isWeekday) {
              alarms.push({ type: "adbreaks", name: "Ad Break", message: "Low", delta: localBenchmarks.min.adbreaks - percentages.adbreaks });
            } else if (percentages.adbreaks > localBenchmarks.max.adbreaks) {
              alarms.push({ type: "adbreaks", name: "Ad Break", message: "High", delta: percentages.adbreaks - localBenchmarks.max.adbreaks });
            }

            if (percentages.streaming === 0) {
              alarms.push({ type: "streaming", name: "Streaming", message: "Zero", delta: localBenchmarks.min.streaming - percentages.streaming });
            } else if (percentages.streaming < localBenchmarks.min.streaming && isWeekday) {
              alarms.push({ type: "streaming", name: "Streaming", message: "Low", delta: localBenchmarks.min.streaming - percentages.streaming });
            } else if (percentages.streaming > localBenchmarks.max.streaming) {
              alarms.push({ type: "streaming", name: "Streaming", message: "High", delta: percentages.streaming - localBenchmarks.max.streaming });
            }

            if (percentages.blackout === 0) {
              alarms.push({ type: "blackout", name: "Blackout", message: "Zero", delta: localBenchmarks.min.blackout - percentages.blackout });
            } else if (percentages.blackout < localBenchmarks.min.blackout) {
              alarms.push({ type: "blackout", name: "Blackout", message: "Low", delta: localBenchmarks.min.blackout - percentages.blackout });
            } else if (percentages.blackout > localBenchmarks.max.blackout && isWeekday) {
              alarms.push({ type: "blackout", name: "Blackout", message: "High", delta: percentages.blackout - localBenchmarks.max.blackout });
            }

            return true;
          });

          response.streams.forEach((item) => {
            const { percentages, exception, alarms } = item;

            if (percentages.adbreaks === 0 && percentages.streaming === 0 && percentages.blackout === 0) {
              item.priority = 2;
            } else if (alarms.length > 0 && exception === false) {
              item.priority = 1;
            } else if (alarms.length > 0 && exception === true) {
              item.priority = 3;
            } else {
              item.priority = 4;
            }
          });

          // Sort streams by priority, alarms, and station name
          const sortedStreams = response.streams.sort(
            (a, b) => a.priority - b.priority || b.alarms.length - a.alarms.length || a.station.localeCompare(b.station)
          );

          setBenchmarks(localBenchmarks);
          setTotals(localTotals);
          setOverview({ ...response, streams: sortedStreams });
          setUpdateStatus(true);
        })
        .catch((err) => {
          if (err.name !== "AbortError") {
            console.log("   - Failed to fetch Overview:", err);
          }
        });
    }
    // Cleanup function to abort fetch request if component unmounts or re-renders
    return () => abortController.abort();
  };

  useEffect(() => {
    if (exceptions.length > 0) {
      fetchScteAlerts();
      fetchPercentages();

      return () => {
        fetchScteAlerts();
        fetchPercentages();
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exceptions, global.appDates.start, global.appDates.end]);

  const getAlarms = (item) => {
    let response = false;
    if (item.percentages.adbreaks < benchmarks.min.adbreaks || item.percentages.adbreaks > benchmarks.max.adbreaks) response = "alarm ads";
    if (item.percentages.streaming < benchmarks.min.streaming || item.percentages.streaming > benchmarks.max.streaming) response = "alarm streaming";
    if (item.percentages.blackout < benchmarks.min.blackout || item.percentages.blackout > benchmarks.max.blackout) response = "alarm blackout";

    return response;
  };

  /* Chart Functions *******************************************/
  const onPieEnter = useCallback(
    // Set active pie slice for chart on hover
    (_, index) => {
      setActiveIdx(index);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setActiveIdx]
  );

  //eslint-disable-next-line react-hooks/exhaustive-deps
  const renderActiveShape = useCallback((props) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload } = props;

    return (
      <g>
        <text x={cx} y={cy} dy={-14} textAnchor="middle" fill={"#444"} style={{ fontSize: "0.625rem", fontWeight: 600 }}>
          {payload.name.toUpperCase()}
        </text>
        <text x={cx} y={cy} dy={16} textAnchor="middle" fill={fill} style={{ fontSize: "2.2rem", fontWeight: 600 }}>
          {payload.value}
        </text>
        <text x={cx} y={cy} dy={28} textAnchor="middle" fill={"#444"} style={{ fontSize: "0.5rem", fontWeight: 600 }}>
          PERCENT
        </text>
        <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={fill} />
        <Sector cx={cx} cy={cy} startAngle={startAngle} endAngle={endAngle} innerRadius={outerRadius + 2} outerRadius={outerRadius + 4} fill={fill} />
      </g>
    );
  });

  function SlideTransition(props) {
    return <Slide {...props} direction="right" />;
  }

  return (
    <React.Fragment>
      {overview && overview.streams && overview.streams.length > 0 ? (
        <Box className={"overview " + (global.appUI.showMobile ? "open" : "closed")}>
          <Snackbar open={updateStatus} autoHideDuration={10000} onClose={() => setUpdateStatus(false)} TransitionComponent={SlideTransition}>
            <Alert icon={<Check fontSize="inherit" />} severity="success">
              <b>UPDATED</b>: "Overview Dashboard" has been refreshed to reflect the newest event data for all stations
            </Alert>
          </Snackbar>

          <Box sx={{ padding: "0.5rem 1rem" }}>
            <Typography sx={{ fontSize: "0.75rem" }}>
              <Switch size="small" checked={global.appUI.showTelemundos} onChange={() => handleHide()} />
              Show Telemundos
            </Typography>
          </Box>
          <Box
            display={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              gap: "0.5rem",
              alignItems: "flex-start",
              margin: "0.5rem 1rem",
              padding: "0.5rem 1rem",
              background: "#fff",
              borderRadius: "0.25rem",
              boxShadow: "rgba(6, 26, 54, 0.16) 0 0.25rem 1rem",
            }}
          >
            <Typography
              sx={{
                fontSize: "0.75rem",
                textTransform: "uppercase",
                fontWeight: 600,
              }}
            >
              SCTE Ad Break Recency Alerts
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                gap: "0.5rem",
                flexShrink: 0,
              }}
            >
              {scteAlerts.length > 0 &&
                scteAlerts.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      {!global.appUI.showTelemundos && item.type === "telemundo" ? null : (
                        <Tooltip
                          key={index}
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: "#333",
                                color: "white",
                                maxWidth: "45rem",
                                padding: "1rem",
                              },
                            },
                            arrow: {
                              sx: {
                                color: "#333",
                              },
                            },
                          }}
                          title={
                            <Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  gap: "0.25rem",
                                  alignItems: "center",
                                }}
                              >
                                <Warning sx={{ color: "orange", fontSize: "1rem" }} />
                                <Typography variant="body1" sx={{ fontSize: "1rem", fontWeight: 600 }}>
                                  {item.exception ? "NON-STANDARD CONFIG" : "WARNING"}
                                </Typography>
                              </Box>
                              {item.delta < 86400000 ? (
                                <ul
                                  style={{
                                    marginBlockStart: 0,
                                    paddingBlockStart: "1rem",
                                    paddingInlineStart: "1rem",
                                  }}
                                >
                                  <li style={{ fontSize: "0.875rem" }}>
                                    Last Break: {moment.tz(item.lastUpdate, getTz(global.appDates.timezone)).format("MMM D, YYYY, h:mm:ss a z")}
                                  </li>
                                  <li style={{ fontSize: "0.875rem" }}>
                                    Approximately: {Math.round((item.delta / 60 / 60 / 1000) * 10) / 10} hrs ago
                                  </li>
                                </ul>
                              ) : (
                                <ul
                                  style={{
                                    marginBlockStart: 0,
                                    paddingBlockStart: "1rem",
                                    paddingInlineStart: "1rem",
                                  }}
                                >
                                  <li style={{ fontSize: "0.875rem" }}>Last Break: Greater than 24 hrs ago</li>
                                </ul>
                              )}
                            </Box>
                          }
                          placement="bottom"
                          arrow
                        >
                          <Chip
                            key={index}
                            label={item.station.toUpperCase()}
                            avatar={item.type === "telemundo" ? <Avatar className="espanol">ES</Avatar> : null}
                            color={
                              item.delta >= 86400000
                                ? "default"
                                : item.delta > 72000000
                                ? "primary"
                                : item.delta > 43200000
                                ? "error"
                                : item.delta > 21600000
                                ? "warning"
                                : item.delta > 10800000
                                ? "info"
                                : "secondary"
                            }
                            onClick={() => goto("/view/" + item.station + "/" + item.ssid)}
                          />
                        </Tooltip>
                      )}
                    </React.Fragment>
                  );
                })}
            </Box>
          </Box>
          <Box
            sx={{
              padding: "0.5rem 1rem 0.25rem ",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              background: "#fff",
              margin: "0.5rem 1rem",
              borderRadius: "0.25rem",
              boxShadow: "rgba(6, 26, 54, 0.16) 0 0.25rem 1rem",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "0.75rem",
                  textTransform: "uppercase",
                  paddingBottom: "0.25rem",
                }}
              >
                Gray Primary Stream Overview Dashboard for Period:
              </Typography>
              <Typography sx={{ fontSize: "0.75rem" }}>
                <span
                  style={{
                    display: "inline-block",
                    fontWeight: 600,
                    width: "3rem",
                  }}
                >
                  From:{" "}
                </span>
                {overview && overview.dates && moment.tz(overview.dates.start, getTz(global.appDates.timezone)).format("MMM Do YYYY, h:mm:ss a")}{" "}
              </Typography>

              <Typography sx={{ fontSize: "0.75rem" }}>
                <span
                  style={{
                    display: "inline-block",
                    fontWeight: 600,
                    width: "3rem",
                  }}
                >
                  To:{" "}
                </span>
                {overview && overview.dates && moment.tz(overview.dates.end, getTz(global.appDates.timezone)).format("MMM Do YYYY, h:mm:ss a z")}{" "}
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: "0.75rem" }}>
                <span
                  style={{
                    display: "inline-block",
                    width: "8rem",
                    fontWeight: 600,
                  }}
                >
                  Ad Breaks:{" "}
                </span>
                <span
                  style={{
                    display: "inline-block",
                    width: "8rem",
                    textAlign: "right",
                  }}
                >
                  {benchmarks.avg.adbreaks}%
                </span>
              </Typography>
              <Typography sx={{ fontSize: "0.75rem" }}>
                <span
                  style={{
                    display: "inline-block",
                    width: "8rem",
                    fontWeight: 600,
                  }}
                >
                  Local Streaming:
                </span>
                <span
                  style={{
                    display: "inline-block",
                    width: "8rem",
                    textAlign: "right",
                  }}
                >
                  {benchmarks.avg.streaming}%
                </span>
              </Typography>
              <Typography sx={{ fontSize: "0.75rem" }}>
                <span
                  style={{
                    display: "inline-block",
                    width: "8rem",
                    fontWeight: 600,
                  }}
                >
                  Blackout / LNL:
                </span>
                <span
                  style={{
                    display: "inline-block",
                    width: "8rem",
                    textAlign: "right",
                  }}
                >
                  {benchmarks.avg.blackout}%
                </span>
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              justifyContent: "flex-start",
              gap: "1rem",
              width: "100%",
              padding: "1rem",
              overflow: "scroll",
            }}
          >
            {overview &&
              benchmarks.max.streaming > 0 &&
              totals &&
              overview.streams &&
              overview.streams.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    {!global.appUI.showTelemundos && item.type === "telemundo" ? null : (
                      <Paper
                        elevation={3}
                        key={index}
                        sx={{
                          width: global.appUI.width > 1400 || !global.appUI.width ? "calc((100% - 7rem) / 8)" : "calc((100% - 4rem) / 4)",
                          padding: "0.5rem 0 ",
                          textAlign: "center",
                          boxSizing: "border-box",
                          cursor: "pointer",
                          boxShadow: "rgba(6, 26, 54, 0.16) 0 0.25rem 1rem",
                        }}
                        className={`overview-card 
                      ${item.alarms.length > 0 ? "alarm" : ""} 
                      ${item.exception ? "exception" : ""} 
                      priority-${item.priority}`}
                        onClick={() => goto("/view/" + item.station + "/" + item.ssid)}
                      >
                        {item.priority !== 2 ? (
                          <PieChart width={120} height={120} style={{ margin: "0 auto" }}>
                            <Pie
                              activeIndex={activeIdx}
                              activeShape={renderActiveShape}
                              data={item.data}
                              cx="50%"
                              cy="50%"
                              innerRadius={45}
                              outerRadius={55}
                              fill="#f00"
                              dataKey="value"
                              onMouseEnter={onPieEnter}
                            >
                              {item.data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                              ))}
                            </Pie>
                          </PieChart>
                        ) : (
                          <Avatar
                            sx={{
                              height: 120,
                              width: 120,
                              fontSize: "3rem",
                              backgroundColor: "#FFF",
                              margin: "0 auto",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <WarningOutlined sx={{ fontSize: "3rem", color: "orange" }} />
                              <Typography
                                variant="body1"
                                sx={{
                                  fontSize: "0.75rem",
                                  fontWeight: 600,
                                  color: "#444",
                                }}
                              >
                                No Events
                                <br />
                                Found
                              </Typography>
                            </Box>
                          </Avatar>
                        )}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            gap: "0.5rem",
                            alignItems: "center",
                            paddingTop: "0.5rem",
                          }}
                        >
                          {item.alarms.length > 0 && (
                            <Tooltip
                              title={
                                <Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "center",
                                      gap: "0.25rem",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Warning sx={{ color: "orange", fontSize: "1rem" }} />
                                    <Typography variant="body1" sx={{ fontSize: "1rem", fontWeight: 600 }}>
                                      {item.exception ? "NON-STANDARD CONFIG" : "WARNING"}
                                    </Typography>
                                  </Box>
                                  <ol
                                    style={{
                                      marginBlockStart: 0,
                                      paddingBlockStart: "1rem",
                                      paddingInlineStart: "1rem",
                                    }}
                                  >
                                    {item.alarms.map((alarm, alarmKey) => {
                                      return (
                                        <li key={`alarmKey-${alarmKey}`} style={{ fontSize: "0.875rem" }}>
                                          {alarm.message === "Zero" ? (
                                            <React.Fragment>
                                              <b>
                                                {alarm.message} {alarm.name} received
                                              </b>
                                            </React.Fragment>
                                          ) : (
                                            <React.Fragment>
                                              <b>
                                                {alarm.message} {alarm.name} %age
                                              </b>{" "}
                                              | Gray avg {benchmarks.avg[alarm.type]}%
                                            </React.Fragment>
                                          )}
                                        </li>
                                      );
                                    })}
                                  </ol>
                                </Box>
                              }
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    bgcolor: "#333",
                                    color: "white",
                                  },
                                },
                                arrow: {
                                  sx: {
                                    color: "#333",
                                  },
                                },
                              }}
                              placement="bottom"
                              arrow
                            >
                              <Avatar
                                sx={{
                                  width: "1rem",
                                  height: "1rem",
                                  fontSize: "0.75rem",
                                  backgroundColor: item.priority === 1 ? "crimson" : "darkorange" /* darkorange */ /*tomato*/,
                                  border: "2px solid #fff",
                                  boxSizing: "content-box",
                                }}
                              >
                                {item.alarms.length}
                              </Avatar>
                            </Tooltip>
                          )}
                          <Typography variant="body1">
                            <span
                              style={{
                                fontWeight: getAlarms(item) ? "600" : "inherit",
                              }}
                            >
                              {item.station.toUpperCase()}
                            </span>
                          </Typography>
                          {item.type === "telemundo" ? (
                            <Avatar
                              className="espanol crimson"
                              sx={{
                                height: "1.2rem",
                                width: "1.2rem",
                                fontSize: "0.45rem",
                                color: "black",
                              }}
                            >
                              ES
                            </Avatar>
                          ) : null}
                          {item.exception && <AcUnit sx={{ fontSize: "1rem" }} />}
                        </Box>
                      </Paper>
                    )}
                  </React.Fragment>
                );
              })}
          </Box>
        </Box>
      ) : (
        <Box
          className={"overview " + (global.appUI.showMobile ? "open" : "closed")}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
          <Typography variant="body1" sx={{ marginTop: "1rem", color: "#36c", fontSize: "1.25rem" }}>
            Fetching Data from the Zeam API
          </Typography>
          <Typography variant="body1" sx={{ textAlign: "center", marginTop: "2rem", color: "#444" }}>
            If the site does not load within a reasonable period,
            <br />
            <Link href="https://www.minitool.com/news/clear-cache-for-one-site.html" target="_blank" style={{ color: "#1976d2 !important" }}>
              please try clearing your cache
            </Link>
          </Typography>
        </Box>
      )}
    </React.Fragment>
  );
};

export default Overview;
