//TODO: Only apply filters to EventsTable
import React, { useCallback, useContext, useEffect, useState } from "react";

// Material UI Components
import { Avatar, Box, Tooltip, Typography } from "@mui/material";
import { blue, green, grey, orange } from "@mui/material/colors";
import { AttachMoneyOutlined, InfoOutlined, PlayCircleOutline, StopCircleOutlined } from "@mui/icons-material";

// Moment.js Componentes
import moment from "moment-timezone";

// ReChart Components
import { PieChart, Pie, Cell, Sector } from "recharts";

// Gray Digital Components
import { Application } from "../../context/Application";
import { sortTime } from "../../utilities/utility";
import "./styles/Duration.scss";

const StreamMeta = ({ logs }) => {
  const global = useContext(Application);
  const [activeIdx, setActiveIdx] = useState(0);
  const [data, setData] = useState(null);
  const colors = [blue[500], orange[700], green[500]];

  // Set Baseline Vars

  // TODO: If logs[0] is empty this throws an error
  /* Switch to state for delta from start to end datetime */
  const [diff_time, setDiff_time] = useState(); //start_time.diff(end_time, "seconds"));

  // Set active pie slice for chart on hover
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIdx(index);
    },
    [setActiveIdx]
  );

  const roundTimes = (val, unit) => {
    const delta =
      moment.utc().startOf("day").seconds(val).diff(moment.utc().startOf("day"), unit) +
      ":" +
      moment.utc({}).startOf("day").seconds(val).format("H:m:s");

    const parts = delta.split(":");
    return (
      <Box className="duration">
        {parts.map((val, key) => {
          let units = ["days", "hrs", "min", "sec"];
          return (
            <Box key={key} className="duration_columns">
              <Typography className="value" variant="body1">
                {val}
              </Typography>
              <Typography className="unit" variant="caption" sx={{ fontSize: "0.75rem" }}>
                {units[key]}
              </Typography>
            </Box>
          );
        })}
      </Box>
    );
  };

  // Get Percentages for event types
  useEffect(() => {
    if (logs && Array.isArray(logs) && logs.length > 0) {
      const timeframe = { start: 0, end: 0, delta: 0 };
      const percentages = [];

      // console.log("  - Calculating Percentages");

      /* Get the true timeframe for the stream by ignoring any active event where duration is zero*/
      const tempLogs = sortTime(logs);

      for (let i = 0; i < tempLogs.length; i++) {
        if (tempLogs[i].EstimatedDuration > 0 && tempLogs[i].IsBegin === true) {
          const duration = tempLogs[i].EstimatedDuration * 1000;

          const sta = Date.parse(tempLogs[i].Time);
          const end = sta + duration;
          const f_end = moment.utc(end).toISOString();

          timeframe.end = moment.utc(f_end);
          break;
        }
      }

      if (timeframe.end !== 0) {
        timeframe.start = moment.utc(tempLogs[tempLogs.length - 1].Time);
        timeframe.delta = timeframe.end.diff(timeframe.start, "seconds");
      }

      /* Set State */
      setDiff_time(timeframe.delta);
      // console.log("TIMEFRAME: ", timeframe);

      /* Separate the logs into the three event types (array) */
      const eventTypes = [];
      [
        { code: 2, name: "Streaming" },
        { code: 1, name: "Blackout / LNL" },
        { code: 0, name: "Ad Break" },
      ].map((val) => {
        const response = tempLogs.filter((e) => {
          if (e.EventType === val.code && e.IsBegin === true) {
            return true;
          } else return false;
        });
        eventTypes[val.code] = response;

        return true;
      });

      /* Look for any Ad Breaks that have the same start time as a Local or Blackout event */
      const eventStarts = [];
      eventTypes[0].map((val, key) => {
        eventTypes[1].some((e, k) => {
          if (val.Time === e.Time && val.IsBegin === true) {
            /* Echo to console when a Blackout event is matched */
            console.log("    - Blackout Matched", val, e);

            eventStarts.push([val, e]);
            eventTypes[1].splice(k, 1);
            return true;
          } else return false;
        });

        eventTypes[2].some((e, k) => {
          if (val.Time === e.Time && val.IsBegin === true) {
            /* Swap the estimated duration and fixed duration for calculated */
            eventTypes[0].fixedDuration = eventTypes[0].EstimatedDuration;
            eventTypes[0].EstimatedDuration = eventTypes[2].EstimatedDuration;
            eventTypes[0].duration = eventTypes[2].duration;
            eventTypes[0].seconds = eventTypes[2].seconds;

            eventStarts.push([val, e]);
            eventTypes[2].splice(k, 1);
            return true;
          }
          return false;
        });
        return true;
      });

      /* Checking for overlaps between Local and Blackout events */
      eventTypes[1].map((val, key) => {
        eventTypes[2].some((e, k) => {
          if (val.Time === e.Time && val.IsBegin === true) {
            /* Echo to console when a Blackout event is matched */
            // console.log("    - OVERLAPPING: ", val, e);
          }
          return false;
        });
        return false;
      });

      // console.log(eventStarts);
      // console.log("    - EVENT TYPES: ", eventTypes);

      /* Calculate the NEW Percentages for each event type */
      [
        { code: 2, name: "Streaming" },
        { code: 1, name: "Blackout / LNL" },
        { code: 0, name: "Ad Break" },
      ].map((val, key) => {
        let total = 0;
        eventTypes[val.code].map((e) => {
          // if (val.code === 2) {
          //   console.log(e.EstimatedDuration);
          // }
          total += e.EstimatedDuration;
          return true;
        });

        /* Use new calculations */
        percentages.push({
          code: val.code,
          name: val.name,
          value: Math.round((total / timeframe.delta) * 100),
          totalSeconds: total,
          totalDuration: roundTimes(total, "days"),
          count: eventTypes[val.code].length,
          avgSeconds: eventTypes[val.code].length > 0 ? Math.round(total / eventTypes[val.code].length) : 0,
          avgDuration: moment
            .utc({})
            .startOf("day")
            .seconds(Math.round(total / eventTypes[val.code].length))
            .format("HH:mm:ss"),
        });

        return true;
      });

      if (
        percentages[1].totalSeconds === 0 &&
        global?.activeStation.station !== "lnl" &&
        global.activeStream?.category &&
        global.activeStream?.category.toUpperCase() === "NEWS"
      ) {
        global.setAppUI((prev) => ({
          ...prev,
          error: 'Stream never entered blackout. Confirm that "Local News Live" is enabled for stitching within the time range selected',
        }));
      }

      // console.log("    - PERCENTAGES: ", percentages);
      // console.log("---");
      setData(percentages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logs, diff_time, global.activeStation.station]);

  const renderActiveShape = (props) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload } = props;

    return (
      <g>
        <text x={cx} y={cy} dy={-14} textAnchor="middle" fill={"#444"} style={{ fontSize: "0.625rem", fontWeight: 600 }}>
          {payload.name.toUpperCase()}
        </text>
        <text x={cx} y={cy} dy={16} textAnchor="middle" fill={fill} style={{ fontSize: "2.2rem", fontWeight: 600 }}>
          {payload.value}
        </text>
        <text x={cx} y={cy} dy={28} textAnchor="middle" fill={"#444"} style={{ fontSize: "0.5rem", fontWeight: 600 }}>
          PERCENT
        </text>
        <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={fill} />
        <Sector cx={cx} cy={cy} startAngle={startAngle} endAngle={endAngle} innerRadius={outerRadius + 2} outerRadius={outerRadius + 4} fill={fill} />
      </g>
    );
  };

  return (
    <React.Fragment>
      {data && data.length >= 3 && (
        <Box className="eventlogs___meta">
          <Box className="eventlogs___meta_left">
            <PieChart width={200} height={200}>
              <Pie
                activeIndex={activeIdx}
                activeShape={renderActiveShape}
                data={data}
                cx="50%"
                cy="50%"
                innerRadius={50}
                outerRadius={70}
                fill="#f00"
                dataKey="value"
                onMouseEnter={onPieEnter}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                ))}
              </Pie>
            </PieChart>
          </Box>
          <Box className="eventlogs___meta_right">
            <Box>
              <Box className="label">
                Total Duration
                <Tooltip
                  title={
                    <React.Fragment>
                      <Typography>The total duration of the stream from start to finish may not equal the timerange.</Typography>
                      <Typography>An event at the start of the time range may not have completed until well within the window.</Typography>
                      <Typography> Similarly, the most recent event may be ongoing so a duration cannot yet be calculated.</Typography>
                    </React.Fragment>
                  }
                  arrow
                  placement="top"
                >
                  <InfoOutlined sx={{ width: "1rem", height: "1rem", color: grey[400] }} />
                </Tooltip>
              </Box>
              <Box>{roundTimes(diff_time, "days")}</Box>
            </Box>
            <Box>
              <Box className="label">
                <Avatar
                  variant="rounded"
                  style={{
                    background: green[500],
                    width: "1rem",
                    height: "1rem",
                  }}
                >
                  <AttachMoneyOutlined style={{ color: "#fff", fontSize: "1rem" }} />
                </Avatar>
                Ad Breaks
                <Typography className="caption" variant="caption" sx={{ flexGrow: 2, textAlign: "right" }}>
                  Avg: {data[2].avgDuration}
                </Typography>
              </Box>
              <Box>{data[2].totalDuration}</Box>
            </Box>
            <Box>
              <Box className="label">
                <Avatar
                  variant="rounded"
                  style={{
                    background: orange[700],
                    width: "1rem",
                    height: "1rem",
                  }}
                >
                  <StopCircleOutlined style={{ color: "#fff", fontSize: "1rem" }} />
                </Avatar>
                Blackout
                <Typography className="caption" variant="caption" sx={{ flexGrow: 2, textAlign: "right" }}>
                  Avg: {data[1].avgDuration}
                </Typography>
              </Box>
              <Box>{data[1].totalDuration}</Box>
            </Box>
            <Box>
              <Box className="label">
                <Avatar
                  variant="rounded"
                  style={{
                    background: blue[500],
                    width: "1rem",
                    height: "1rem",
                  }}
                >
                  <PlayCircleOutline style={{ color: "#fff", fontSize: "1rem" }} />
                </Avatar>
                Streaming
                <Typography className="caption" variant="caption" sx={{ flexGrow: 2, textAlign: "right" }}>
                  Avg: {data[0].avgDuration}
                </Typography>
              </Box>
              <Box>{data[0].totalDuration}</Box>
            </Box>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};

export default StreamMeta;
