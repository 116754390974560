import React, { useContext } from "react";

// MUI Components
import { VisibilityOff } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { orange } from "@mui/material/colors";

// AWS Components
import { DataStore } from "aws-amplify";
import { Streams } from "../../models";

// Gray Componets
import setActivityLog from "../../utilities/setActivityLog";
import { Application } from "../../context/Application";

const AdminStreamVisibility = ({ streamData, forceUpdate }) => {
  const global = useContext(Application);

  /* Admin Tool - Set Stream as Primary for ActiveSB */
  const setVisibility = async (data) => {
    let hidden = true;
    const original = await DataStore.query(Streams, (c) =>
      c.and((c) => [c.ssid.eq(+data.ssid), c.station.eq(data.sname.toLowerCase().replace(/-\D\D/, ""))])
    );

    try {
      if (original.length > 0 && original[0].hasOwnProperty("ssid")) {
        hidden = !original[0].hidden;
        /* Update Record */
        await DataStore.save(
          Streams.copyOf(original[0], (updated) => {
            updated.hidden = !original[0].hidden;
            updated.author = global.appUser.email;
          })
        );
      } else {
        /* Create Record */
        await DataStore.save(
          new Streams({
            station: data.sname.toLowerCase().replace(/-\D\D/, ""),
            ssid: +data.ssid,
            activesb: data.activesb,
            hidden: true,
            author: global.appUser.email,
          })
        );
      }
      await setActivityLog({
        type: "VISIBILITY",
        note: `Stream visibility set to ${hidden ? "hide" : "unhide"} for #${+data.ssid}`,
        author: global.appUser.email,
        station: global.activeStation.station,
      });

      // Refresh Navigation
      forceUpdate();
    } catch (e) {
      console.log("   - ERROR", e);
    }
  };
  return (
    <Box className="admin___panel_button">
      <Box className="title">
        <VisibilityOff fontSize="1rem" />
        <Typography>Visibility</Typography>
      </Box>
      <Box className="description">
        <Typography>
          Set the visibility of <b>{streamData.friendly}</b> to {streamData.hidden ? "visible" : "hidden"} for{" "}
          <span sx={{ color: orange[500], fontWeight: "bold" }}>SyncMon</span> users.{" "}
        </Typography>

        <Typography>
          <b>NOTE:</b> Administrator can still view all streams regardless of visibility setting.
        </Typography>
      </Box>
      <Box className="status">
        <Button
          variant="outlined"
          onClick={() => {
            setVisibility(streamData);
          }}
          size="small"
        >
          {streamData.hide ? "Unhide" : "Hide"} Stream
        </Button>
      </Box>
    </Box>
  );
};

export default React.memo(AdminStreamVisibility);
