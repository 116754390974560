// React Components
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import moment from "moment-timezone";
import ReactGa4 from "react-ga4";

// AWS Amplify Components

// Material UI Components
import { AppBar, Divider, IconButton, ListItemIcon, Menu, MenuItem, Toolbar, Tooltip, Typography } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Dashboard, Logout, Menu as MenuIcon, MenuOpen, ToggleOffTwoTone, ToggleOnTwoTone } from "@mui/icons-material";
import { grey, red } from "@mui/material/colors";
import { Box } from "@mui/system";

// Gray Digital Components
import { isAdmin } from "../../utilities/isAdmin";
import { Application } from "../../context/Application";
import { updates } from "../../data/appUpdates";
import { getTz } from "../../utilities/utility";

import ButtonAccounts from "./Subcomponents/ButtonAccounts";
import KnowledgeBase from "../knowledgeBase/KnowledgeBase";
import ButtonUpdates from "./Subcomponents/ButtonUpdates";
import CommentDrawer from "../comment/CommentDrawer";
import ButtonHelp from "./Subcomponents/ButtonHelp";
import UpdatesRow from "./Subcomponents/UpdatesRow";
import Subhead from "./Subhead";

// Gray Assets
import "../../_styles/Header.scss";

// Construct MUI Theme Overrides
const darkTheme = createTheme({
  palette: {
    primary: { main: "#ffffff" },
    secondary: { main: "#cc0000" },
  },
});

const Header = () => {
  const global = useContext(Application);
  const goto = useNavigate();

  const { oktaAuth } = useOktaAuth();

  const handleLogout = () => oktaAuth.signOut();

  // Set States
  const [anchorEl, setAnchorEl] = useState(null);
  const updatesRef = Boolean(anchorEl);

  const [anchorAccount, setAnchorAccount] = useState(null);
  // const [smsOptIn, setSmsOptIn] = useState(false);
  const [hideAdminTools, setHideAdminTools] = useState(false);
  const accountRef = Boolean(anchorAccount);

  // Handle Interactions
  const handleClick = (event) => {
    ReactGa4.event({
      category: "ui",
      action: "ui_view",
      label: "appUpdates_" + (updatesRef ? "hide" : "show"),
    });
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    ReactGa4.event({
      category: "ui",
      action: "ui_view",
      label: "appUpdates_hide",
    });
  };

  const handleAccountClick = (event) => {
    ReactGa4.event({
      category: "ui",
      action: "ui_view",
      label: "appAccountInfo" + (accountRef ? "hide" : "show"),
    });
    setAnchorAccount(event.currentTarget);
  };

  const handleAccountClose = () => {
    setAnchorAccount(null);
    ReactGa4.event({
      category: "ui",
      action: "ui_view",
      label: "appAccountInfo_hide",
    });
  };

  const handleHideAdminTools = (status) => {
    setHideAdminTools(status);
    global.setAppUI({ ...global.appUI, hideAdminTools: status });
  };

  const handleNavigationClose = (state) => {
    global.setAppUI({ ...global.appUI, showMobile: state, pinNav: false });
  };

  useEffect(() => {
    if (global.appUI.hideAdminTools === true && isAdmin(global.appUser.account)) {
      setHideAdminTools(global.appUI.hideAdminTools);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [global.appUser.email]);

  const Salutation = () => {
    // console.log(global.favoriteList);
    const now = moment.tz(getTz());
    const hour = now.hour();

    const name = global.appUser.name.split(" ")[0];

    if (hour >= 5 && hour < 12) {
      return `Good Morning, ${name}!`;
    } else if (hour >= 12 && hour < 17) {
      return `Good Afternoon, ${name}!`;
    } else {
      return `Good Evening, ${name}!`;
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <React.Fragment>
        <KnowledgeBase />
        {global.activeStation && global.activeStream?.ssid && (
          <CommentDrawer station={global.activeStation.station} ssid={global.activeStream.ssid} />
        )}
        <AppBar id="header" position="fixed" elevation={0} className={global.appUI.showMobile ? "sidebar-open" : "sidebar-closed"}>
          <Toolbar variant="dense" sx={{ "@media": { paddingLeft: "0rem" } }}>
            <Tooltip title={`${global.appUI.showMobile ? "Collapse" : "Show"} sidebar`} arrow placment="bottom">
              <IconButton
                className="home___button"
                sx={{ marginLeft: "0" }}
                size="small"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => handleNavigationClose(!global.appUI.showMobile)}
              >
                {global.appUI.showMobile ? <MenuOpen /> : <MenuIcon />}
              </IconButton>
            </Tooltip>
            {global.appUI.section === "welcome" && (
              <Typography variant="h2" component="h2" sx={{ color: "#333", fontSize: "1.4rem", fontWeight: "bold" }}>
                <Salutation />
              </Typography>
            )}
            <Subhead />

            <Box className="header___right">
              <ButtonHelp />
              <ButtonUpdates status={updatesRef} action={handleClick} updates={updates} />
              {global && global.appUser.email && <ButtonAccounts status={accountRef} action={handleAccountClick} />}
            </Box>

            <Menu
              anchorEl={anchorEl}
              id="updates___menu"
              open={updatesRef}
              onClose={handleClose}
              onClick={handleClose}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <Typography variant="h6">Development Updates</Typography>
              <Divider />
              <Box className="updates___menu_list">
                {updates.map((val, key) => {
                  return <UpdatesRow key={key} data={val} />;
                })}
              </Box>
            </Menu>

            <Menu
              anchorEl={anchorAccount}
              id="account___menu"
              open={accountRef}
              onClose={handleAccountClose}
              // onClick={handleAccountClose}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <Typography variant="h6">Account Info</Typography>
              <Divider sx={{ minWidth: "35rem" }} />
              <Typography sx={{ fontSize: "1rem" }}>
                {global.appUser.name ? global.appUser.name : global.appUser.email}
                <Typography variant="span" sx={{ lineHeight: "0.875rem", color: grey[700], display: "block" }}>
                  {global.appUser.account && isAdmin(global.appUser.account, false, false) ? ` ${global.appUser.account.toLowerCase()}` : ""}
                </Typography>
              </Typography>
              <Divider />
              {global.appUser.account && isAdmin(global.appUser.account, global.appUI.hideAdminTools, false) && (
                <MenuItem onClick={() => goto("/overview")}>
                  <ListItemIcon>
                    <Dashboard className="logout_icon" style={{ fontSize: "1rem" }} />
                  </ListItemIcon>
                  Overview Dashboard
                </MenuItem>
              )}
              {global.appUser.account && isAdmin(global.appUser.account, false, false) && (
                <MenuItem onClick={() => handleHideAdminTools(!hideAdminTools)}>
                  <ListItemIcon>
                    {hideAdminTools ? (
                      <ToggleOnTwoTone className="logout_icon" style={{ fontSize: "1rem", color: red[500] }} />
                    ) : (
                      <ToggleOffTwoTone className="logout_icon" style={{ fontSize: "1rem", color: grey[600] }} />
                    )}
                  </ListItemIcon>
                  {hideAdminTools ? (
                    <Box sx={{ display: "flex", flexDirection: "column" }}>Demo Mode Enabled</Box>
                  ) : (
                    <Box sx={{ display: "flex", flexDirection: "column" }}>Demo Mode Disabled</Box>
                  )}
                </MenuItem>
              )}
              <Divider sx={{ padding: 0, marginTop: "0 !important", marginBottom: "0 !important" }} />
              {/*               
              {global.appUser.account && isSuperAdmin(global.appUser.account) && (
                <MenuItem onClick={() => setSmsOptIn(!smsOptIn)}>
                  <ListItemIcon>
                    {smsOptIn ? (
                      <ToggleOnTwoTone className="logout_icon" style={{ fontSize: "1rem", color: green[600] }} />
                    ) : (
                      <ToggleOffTwoTone className="logout_icon" style={{ fontSize: "1rem", color: red[500] }} />
                    )}
                  </ListItemIcon>
                  {smsOptIn ? (
                    <Box sx={{ display: "flex", flexDirection: "column" }}>Receiving Text Alerts</Box>
                  ) : (
                    <Box sx={{ display: "flex", flexDirection: "column" }}>Not Receiving Text Alerts</Box>
                  )}
                </MenuItem>
              )}
              {global.appUser.account && isSuperAdmin(global.appUser.account, global.appUI.hideAdminTools, false) && smsOptIn && (
                <Box sx={{ padding: "0 1rem 1rem 3rem" }}>
                  <Typography variant="caption" sx={{ color: grey[700], display: "block", marginBottom: "0.5rem" }}>
                    Issues with Gray livestreams will be sent via text messaging to your mobile phone. Opt-out at any time.
                  </Typography>
                  <TextField size="small" variant="filled" label="Mobile Phone" sx={{ width: "100%" }} />
                </Box>
              )}
              <Divider sx={{ padding: 0, marginTop: "0 !important", marginBottom: "0 !important" }} /> */}
              <MenuItem onClick={() => handleLogout()}>
                <ListItemIcon>
                  <Logout className="logout_icon" style={{ fontSize: "1rem" }} />
                </ListItemIcon>
                Log Out
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
      </React.Fragment>
    </ThemeProvider>
  );
};

export default React.memo(Header);
