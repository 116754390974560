import React, { useEffect, useMemo, useState } from "react";
import ReactGa4 from "react-ga4";

// React Router Dom Components
import { BrowserRouter } from "react-router-dom";

// Gray TV Components
import { Authentication } from "../context/Authentication";
import Auth from "../components/authentication/Auth";

/* Initial Web App View that established Routes and Authentication
 *
 * @returns {React.Component} - Returns the React Component for the HomeScreen
 */
const HomeScreen = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [authState, setAuthState] = useState(null);

  useEffect(() => {
    /* Initialize Google Analytics */
    ReactGa4.initialize("G-TT7XMQ6P29");

    /* Check if user is authenticated */
    const oktaTokenStorage = localStorage.getItem("okta-token-storage");
    if (oktaTokenStorage) {
      try {
        const okta = JSON.parse(oktaTokenStorage);
        const now = Math.floor(Date.now() / 1000);

        if (okta?.accessToken?.claims?.exp) {
          if (okta.accessToken.claims.exp > now) {
            console.log("  - Session Active: " + Math.round((okta.accessToken.claims.exp - now) / 60) + " minutes remaining");
            setIsAuthenticated(true);
          } else {
            console.log("  - Session Expired");
            setIsAuthenticated(false);
          }
        } else {
          console.log("  - Session Undefined");
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.log("  - Error parsing okta-token-storage:", error);
        setIsAuthenticated(false);
      }
    } else {
      console.log("  - Session Undefined");
      setIsAuthenticated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const authContextValue = useMemo(
    () => ({
      isAuthenticated,
      setIsAuthenticated,
      authState,
      setAuthState,
    }),
    [isAuthenticated, authState]
  );

  return (
    <Authentication.Provider value={authContextValue}>
      <BrowserRouter>
        <Auth />
      </BrowserRouter>
    </Authentication.Provider>
  );
};

export default HomeScreen;
