import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";

// MUI Components
import { HelpOutline, PlayCircleOutline } from "@mui/icons-material";
import { Avatar, Box, Tooltip, Typography } from "@mui/material";
import { grey, orange } from "@mui/material/colors";

// Gray TV Components
import { updateVODM3U8 } from "../../../../utilities/getStationVOD";
import { Application } from "../../../../context/Application";
import { getTz } from "../../../../utilities/utility";
import { Video } from "../../../../context/Video";

const VodList = ({ data }) => {
  const global = useContext(Application);
  const globalVideo = useContext(Video);
  const goto = useNavigate();

  // On Select Functions
  const onSelectVod = (vod) => {
    updateVODM3U8(global.activeStation, vod.id).then((response) => {
      globalVideo.setStream({
        url: response.apiResponse.streamUrl,
        title: response.apiResponse.title,
        distro: response.apiResponse.id,
        desc: response.apiResponse.description,
        duration: response.apiResponse.duration,
        adbreaks: response.apiResponse.adBreaks,
        type: "vod",
      });
      goto("/video/" + global.activeStation.station.toLowerCase() + "/vod/" + vod.id);
    });
  };

  return (
    <React.Fragment>
      <Box className="streams___container_label">
        PROGRAMMING ON DEMAND
        <Tooltip title="Previously aired on demand programming from the selected Gray station for review." arrow placement="bottom-start">
          <HelpOutline className="label___tooltip" />
        </Tooltip>
      </Box>
      <Box className="streams___list">
        {globalVideo.station.streams &&
          globalVideo.station.streams.length > 0 &&
          data.map((val, key) => {
            return (
              <Box
                key={"streams___distro_" + key}
                className={"streams___list_row " + (globalVideo.stream.distro === val.id ? "active" : "inactive")}
                onClick={() => onSelectVod(val)}
              >
                <Box className="streams___list_meta distro">
                  <Avatar
                    sx={{
                      background: globalVideo.stream.distro === val.id ? orange[500] : grey[700],
                      color: "#fff",
                      height: "0.875rem",
                      width: "0.875rem",
                    }}
                  >
                    <PlayCircleOutline />
                  </Avatar>
                  <Box className="meta___title">
                    <Typography className="streams___primary_label">
                      {moment.tz(val.airDate, getTz(global.appDates.timezone)).format("MMM D YYYY @ h:mm a z")}
                    </Typography>
                    <span>
                      <b>{val.title}</b> ({Math.round(val.duration / 1000 / 60)}m)
                    </span>
                  </Box>
                </Box>
              </Box>
            );
          })}
      </Box>
    </React.Fragment>
  );
};
export default VodList;
